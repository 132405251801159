import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    activateManagerEnterpriseServiceType,
    addManagerEnterpriseServiceProvider,
    deactivateManagerEnterpriseServiceType,
    deleteManagerEnterpriseServiceProviderById,
    getManagerEnterpriseServiceProviders,
    getManagerEnterpriseServiceTypeById
} from "../../../../../../services/dataService";
import {getUser, saveProviders} from "../../../../../../services/storageService";
import DoreTopBar from "../../../../../shared/DoreTopBar";
import DoreExpansionPanel from "../../../../../shared/DoreExpansionPanel";
import DoreList from "../../../../../shared/DoreList";
import {goToEnterpriseServiceProviderPage} from "../../../../../../services/pageService";
import {openConfirmDialog} from "../../../../../../services/confirmDialogService";
import {faBook, faTrash} from "@fortawesome/free-solid-svg-icons";
import NewServiceProvider from "../../serviceProvider/AddNewServiceProviderForm";
import {Icon, Portal} from "@material-ui/core";
import DoreSpeedDial from "../../../../../shared/DoreSpeedDial";
import DoreInputField from "../../../../../shared/DoreInputField";
import {translate2} from "../../../../../../services/translateService";
import DoreDatePicker from "../../../../../shared/DoreDatePicker";
import {dateSimpleFormat} from "../../../../../../utils/utils";
import DoreFab from "../../../../../shared/DoreFab";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";

const EnterpriseServiceTypeVersionProfile = ({match, loading}) => {
    const [isEditMode, setEditMode] = useState(false);
    const {enterpriseId} = getUser();
    let {params: {serviceTypeId, serviceTypeVersionId}} = match;
    serviceTypeId = serviceTypeId.split('_')[0];

    const [isActive, setIsActive] = useState(false);
    const [serviceTypeVersion, setServiceTypeVersion] = useState({});
    const [serviceTypeVersionInfo, setServiceTypeVersionInfo] = useState({});
    const [serviceProviders, setServiceProviders] = useState([]);
    const [refresh, setRefresh] = useState(false);

    let deletePressed = false;

    const refreshPage = () => {
        setRefresh(prevState => !prevState)
    }
    useEffect(() => {
        (async () => {
            const serviceTypeRes = await getManagerEnterpriseServiceTypeById(enterpriseId, serviceTypeId);

            if (serviceTypeRes) {
                let versionInfo = serviceTypeRes.versions.find(s => s.versionId == serviceTypeVersionId);
                setServiceTypeVersion({
                    // id: serviceTypeRes.id,
                    // name: serviceTypeRes.name,
                    status: versionInfo && versionInfo.status,
                    duration: versionInfo && versionInfo.duration,
                    effectiveDate: versionInfo && versionInfo.effectiveDate,
                    expirationDate: versionInfo && versionInfo.expirationDate
                });
            }
        })();
    }, [refresh]);


    useEffect(() => {
        // let expirationDate = serviceTypeVersion.expirationDate || 'غير محدد';
        // setServiceTypeVersionInfo({
        //     // name: {label: 'الاسم: ', value: serviceTypeVersion.name},
        //     // description: {label: 'الوصف: ', value: serviceTypeVersion.description},
        //     status: {label: 'الحالة: ', value: `${serviceTypeVersion.status} `},
        //     duration: {label: 'الوقت: ', value: `${serviceTypeVersion.duration} د`},
        //     effectiveDate: {label: 'تاريخ البداية: ', value: `${serviceTypeVersion.effectiveDate}`},
        //     expirationDate: {label: 'تاريخ النهاية: ', value: `${expirationDate}`},
        // });
        setServiceTypeVersionInfo({
            status: serviceTypeVersion.status,
            duration: serviceTypeVersion.duration,
            effectiveDate: serviceTypeVersion.effectiveDate,
            expirationDate: serviceTypeVersion.expirationDate,
        });
        serviceTypeVersion && setIsActive("ACTIVE" === serviceTypeVersion.status)
        setEditMode(!isActive)
    }, [serviceTypeVersion]);


    useEffect(() => {
        (async () => {
            const serviceProvidersArr = await getManagerEnterpriseServiceProviders(enterpriseId, serviceTypeId, serviceTypeVersionId);
            if (serviceProvidersArr) {
                setServiceProviders(serviceProvidersArr.map(sp => ({
                    id: sp.id,
                    title: sp.name,
                    avatar: true,
                    subTitle: sp.phoneNumber,
                })));
                saveProviders(serviceProvidersArr);
            }
        })();
    }, [refresh]);

    const onServiceProviderClick = async (sp) => {
        if (deletePressed) {
            await onDelete(sp);
        } else {
            goToEnterpriseServiceProviderPage(serviceTypeId, serviceTypeVersionId, sp.id)
        }
        ;
    }


    const [newSPDaysToShow, setNewSPDaysToShow] = useState("7")
    const [newSPId, setNewSPId] = useState("")
    const [newSPDuration, setNewSPDuration] = useState("20")

    const onAddNewServiceProvider = () => {
        let titleInfo = translate2("addNewServiceProvider")
        let subTitleInfo = ''
        openConfirmDialog(faPlus, titleInfo, subTitleInfo,
            <NewServiceProvider enterpriseId={enterpriseId}
                                inputDuration={newSPDuration}
                                inputDaysToShow={newSPDaysToShow}
                                onChangeDaysToShow={(days) => setNewSPDaysToShow(days)}
                                onChangeSP={(id) => setNewSPId(id)}
                                onChangeDuration={(duration) => setNewSPDuration(duration)}
                                serviceTypeId={serviceTypeId}
                                versionId={serviceTypeVersionId}/>,
            () => {
                addManagerEnterpriseServiceProvider(enterpriseId, serviceTypeId, serviceTypeVersionId, newSPId, newSPDuration)
                    .then(r => {
                        refreshPage()
                    });
            });
    }

    const onActivate = () => {
        activateManagerEnterpriseServiceType(enterpriseId, serviceTypeId, serviceTypeVersionId).then(r => {
            refreshPage()
        })
    }

    const onDeactivate = () => {
        deactivateManagerEnterpriseServiceType(enterpriseId, serviceTypeId, serviceTypeVersionId).then(r => {
            refreshPage()
        })
    }


    const onDelete = async (sp) => {
        let titleInfo = translate2("areUSureUWantToDeleteServiceProvider")
        let subTitleInfo = ''
        openConfirmDialog(faTrash, titleInfo, subTitleInfo,
            <div></div>,
            async () => {
                await deleteManagerEnterpriseServiceProviderById(enterpriseId, serviceTypeId, sp.id, serviceTypeVersionId).then(r => {
                    deletePressed = false;
                    refreshPage();
                });
            }, () => {
                deletePressed = false;
            });
    }

    // const actions = [
        // {
        //     icon: !isActive ? <Icon>blur_on</Icon> : <Icon>blur_off</Icon>,
        //     name: !isActive ? translate2("activateVersion") : translate2("deactivateVersion"),
        //     onClick: !isActive ? onActivate : onDeactivate
        // },
        // {icon: <Icon>add</Icon>, name: translate2("serviceProvider"), onClick: onAddNewServiceProvider},
    // ];

    return (
        <div>
            <DoreTopBar loading={loading}/>
            <div style={{marginRight: "10px", marginLeft: "10px"}}>
                <br/>
                <DoreInputField
                    loading={loading}
                    type="number"
                    label={translate2("durationInMin")}
                    icon="hourglass_empty"
                    value={serviceTypeVersionInfo.duration}
                    onChange={e => {
                        let v = parseInt(e.target.value);
                        if (v > 0) {
                            setServiceTypeVersionInfo(prevState => {
                                let newState = JSON.parse(JSON.stringify(prevState));
                                newState.duration = "" + v;
                                return newState;
                            })
                        }
                    }}
                />
                <DoreDatePicker
                    disableFutureDates={false}
                    loading={loading}
                    label={translate2("serviceStartDate")}
                    okLabel={translate2("ok")}
                    cancelLabel={translate2("cancel")}
                    icon="today"
                    value={serviceTypeVersion.effectiveDate}
                    onChange={date => {
                        let simpleDate = dateSimpleFormat(date);
                        setServiceTypeVersionInfo(prevState => {
                            let newState = JSON.parse(JSON.stringify(prevState));
                            newState.effectiveDate = simpleDate;
                            return newState;
                        })
                    }}
                />
                <DoreDatePicker
                    disableFutureDates={false}
                    loading={loading}
                    label={translate2("serviceEndDate")}
                    okLabel={translate2("ok")}
                    cancelLabel={translate2("cancel")}
                    icon="today"
                    value={serviceTypeVersion.expirationDate}
                    onChange={date => {
                        let simpleDate = dateSimpleFormat(date);
                        setServiceTypeVersionInfo(prevState => {
                            let newState = JSON.parse(JSON.stringify(prevState));
                            newState.expirationDate = simpleDate;
                            return newState;
                        })
                    }}
                />

                <DoreExpansionPanel title={translate2("serviceProviders")} icon="account_circle">
                    <div>
                        <DoreList
                            list={serviceProviders}
                            loading={loading}
                            onClick={onServiceProviderClick}
                            onDelete={() => {
                                deletePressed = true;
                            }}
                        />
                    </div>
                </DoreExpansionPanel>

            </div>


            <Portal container={document.body}>
                {
                    <DoreFab
                        color="primary"
                        onClick={onAddNewServiceProvider}
                        inout={true}
                        icon="add"
                        loading={true}
                        variant="extended"
                        label={translate2("addNewServiceProvider")}
                    />
                }
            </Portal>
        </div>
    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseServiceTypeVersionProfile);
