import React, {useState} from 'react';
import {translate, translate2} from '../../services/translateService';
import {closeDialog} from '../../services/dialogService';
import {connect} from 'react-redux';
import {goToHomePage, goToProfilePage} from '../../services/pageService';
import {alertError, alertSuccess} from '../../services/snackbarService';
import {signIn, signInWithFirebase} from '../../services/dataService';
import {createUser, saveAuth, saveUser} from '../../services/storageService';
import DoreButton from '../shared/DoreButton';
import DoreInputField from '../shared/DoreInputField';
import DoreIcon from '../shared/DoreIcon';
import DoreForm from '../shared/DoreForm';
import {setMenuBarItems} from '../../store/actions';
import {getMenuBarItems} from '../../utils/utils';
import jwt from 'jwt-decode';

const SignIn = ({dispatch, dialog, currentLanguage, loading, userPhoneNumber, accessCodeInit}) => {

    const {
        pageTranslation: {
            title,
            subTitle,
            accessCodeLabel,
            signInBtnText
        }
    } = translate('SignIn', currentLanguage);

    const [accessCode, setAccessCode] = useState(accessCodeInit);

    const handleSignIn = async () => {
        let firebaseAuthIdToken = "";
        await onSubmitOtp().then(idToken => {
            firebaseAuthIdToken = idToken
        });
        console.log("signBackend " + firebaseAuthIdToken)
        if(firebaseAuthIdToken) {
            const res = await signInWithFirebase(firebaseAuthIdToken);
            closeDialog();
            if (res && res.token) {
                const token = res.token;
                const jwtDecoded = jwt(token);
                saveUser(createUser(jwtDecoded.userType, jwtDecoded.enterpriseId, jwtDecoded.enterpriseStatus, userPhoneNumber, jwtDecoded.userLocalName));
                saveAuth(jwtDecoded.sub, res.token);
                dispatch(setMenuBarItems(getMenuBarItems(jwtDecoded.userType)));
                if (jwtDecoded.isNewUser) {
                    goToProfilePage();
                } else {
                    goToHomePage();
                }
                alertSuccess(translate2("successLoginMsg"));
            }
        }else{
            alertError(translate2("wrongAccessCode"))
        }
    };


    const onSubmitOtp = async () => {
        let otpInput = accessCode;
        let optConfirm = window.confirmationResult;
        let token = "";
        await optConfirm
            .confirm(otpInput)
            .then(function (result) {
                // User signed in successfully.
                console.log(JSON.stringify(result.user));
                token = result._tokenResponse.idToken;
            })
            .catch(function (error) {
                console.log("failed auth FireBase");
                console.log(error);
            });
        return token;
    };
    return (
        <DoreForm
            loading={loading}
            title={title}
            subTitle={subTitle}
            logo={<DoreIcon size="5rem" loading={loading}>lock_open</DoreIcon>}
        >
            <DoreInputField
                loading={loading}
                type="number"
                label={accessCodeLabel}
                icon="vpn_key"
                value={accessCode || ''}
                onChange={e => setAccessCode(e.target.value)}
            />
            <DoreButton
                icon="lock"
                label={signInBtnText}
                loading={loading}
                onClick={handleSignIn}
                disabled={!accessCode}
            />
        </DoreForm>
    );
};

export default connect(state => ({
    user: state.user,
    dialog: state.dialog,
    loading: state.loading,
    currentLanguage: state.currentLanguage,
}))(SignIn);
