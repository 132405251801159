import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    getEnterpriseEmployeeById,
    getManagerEnterpriseServicesTypes,
    updateEnterpriseEmployeeById
} from "../../../../../services/dataService";
import {getUser, mapAndSave, SERVICES_KEY} from "../../../../../services/storageService";
import DoreTopBar from "../../../../shared/DoreTopBar";
import {Portal} from "@material-ui/core";
import {translate2} from "../../../../../services/translateService";
import DoreFab from "../../../../shared/DoreFab";
import {EmployeeDetailsComponent} from "./AddOrUpdateNewEmployeeForm";
import DoreExpansionPanel from "../../../../shared/DoreExpansionPanel";
import WorkingHours from "../workingHours/WorkingHours";
import {onChangeEmployeesEvents, onSaveEmployeesWorkingHours, onUpdateEvent} from "../AddOrUpdateNewEnterpriseForm";
import FullWorkingHours from "../workingHours/FullWorkingHours";

const EnterpriseSingleEmployeeProfile = ({match, loading, currentLanguage}) => {


    const {enterpriseId} = getUser();
    const {params: {employeeId}} = match;

    // const [isEditMode, setIsisEditMode] = useState(true);
    const [employeeInfo, setEmployeeInfo] = useState(null);

    useEffect(() => {
        if(employeeInfo == null) {
            (async () => {
                let employeeRes = await getEnterpriseEmployeeById(enterpriseId, employeeId);
                if (employeeRes) {
                    setEmployeeInfo({
                        id: employeeRes.id,
                        name: employeeRes.displayName,
                        phoneNumber: employeeRes.phoneNumber,
                        role: employeeRes.role,
                    });
                }
            })();
        }
    }, []);

    const onSave = () => {
        let body = {
            "role": employeeInfo && employeeInfo.role,
            "displayName": employeeInfo && employeeInfo.name
        }
        updateEnterpriseEmployeeById(enterpriseId, employeeId, body).then(r => console.log("employee updated!"));
    }

    return (
        <div>
            <DoreTopBar loading={loading}/>
            <br/>
            <DoreExpansionPanel title={translate2("employeeDetails")} icon="person" initialExpanded={false}>

                {employeeInfo && <div style={{marginRight: "10px", marginLeft: "10px"}}>
                    <EmployeeDetailsComponent loading={loading}
                                              isEdit={true}
                                              inputName={employeeInfo.name}
                                              onChangeName={(name) => {
                                                  setEmployeeInfo(prevState => {
                                                      let newState = JSON.parse(JSON.stringify(prevState));
                                                      newState.name = name;
                                                      return newState;
                                                  })
                                              }}
                                              inputTN={employeeInfo.phoneNumber}
                                              onChangeTN={(tn) => {
                                                  setEmployeeInfo(prevState => {
                                                      let newState = JSON.parse(JSON.stringify(prevState));
                                                      newState.phoneNumber = tn;
                                                      return newState;
                                                  })
                                              }}
                                              inputRole={employeeInfo.role}
                                              onChangeRole={(role) => {
                                                  setEmployeeInfo(prevState => {
                                                      let newState = JSON.parse(JSON.stringify(prevState));
                                                      newState.role = role;
                                                      return newState;
                                                  })
                                              }}
                    />
                </div>}
            </DoreExpansionPanel>
            {/*<FullWorkingHours*/}
            {/*    employee={employeeInfo}*/}
            {/*    loading={loading}*/}
            {/*    isToShowTopBar={false}*/}
            {/*/>*/}

            <Portal container={document.body}>
                <DoreFab
                    onClick={() => {
                        onSave()
                    }}
                    inout={true}
                    icon="save"
                    loading={loading}
                    variant="extended"
                    label={translate2("save")}
                />
            </Portal>

        </div>

    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseSingleEmployeeProfile);
