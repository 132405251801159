import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import TextField from "@material-ui/core/TextField";
import DoreGroupCheckBox from "../../../shared/checkbox/DoreGroupCheckBox";
import {getManagerEnterpriseEmployees, getManagerEnterpriseServicesTypes} from "../../../../services/dataService";
import {getUser} from "../../../../services/storageService";
import {translate2} from "../../../../services/translateService";
import LabelledOutline from "../../../shared/utils/LabelledOutline";


export const SELECT_ALL_ID = "";

const AddNewVacation = ({
                            loading,
                            defaultSelectAllProviders,
                            defaultSelectAllServices,
                            onChangeProviders,
                            onChangeServices,
                            onChangeDescription,
                            onChangeFromDate,
                            onChangeToDate
                        }) => {

        const [selectAllProviders, setSelectAllProviders] = useState(defaultSelectAllProviders);
        const [selectAllServices, setSelectAllServices] = useState(defaultSelectAllServices);
        const [selectedProviders, setSelectedProviders] = useState(new Set([SELECT_ALL_ID]));
        const [selectedServices, setSelectedServices] = useState(new Set([SELECT_ALL_ID]));

        const [services, setServices] = useState([selectAllServices]);
        const [providers, setProviders] = useState([selectAllProviders]);
        const {enterpriseId} = getUser();
        const [fromDateTime, setFromDateTime] = useState("");
        const [toDateTime, setToDateTime] = useState("");
        const [description, setDescription] = useState("");

        useEffect(() => {
            selectedServices && onChangeServices && onChangeServices(selectedServices)
        }, [selectedServices])

        useEffect(() => {
            selectedProviders && onChangeProviders && onChangeProviders(selectedProviders)
        }, [selectedProviders])

        useEffect(() => {
            setSelectedProviders(new Set([SELECT_ALL_ID]));
            setSelectedServices(new Set([SELECT_ALL_ID]));
        }, []);

        useEffect(() => {
            if (!selectedServices.has(SELECT_ALL_ID)) {
                setSelectAllServices(prevState => {
                    prevState.checked = false;
                    return prevState
                });
                setState('services', setServices);
            } else {
                setServices([selectAllServices])
                setSelectAllServices(prevState => {
                    prevState.checked = true;
                    return prevState
                });
            }
        }, [selectedServices]);

        useEffect(() => {
            if (!selectedProviders.has(SELECT_ALL_ID)) {
                setSelectAllProviders(prevState => {
                    prevState.checked = false;
                    return prevState
                });
                setState('providers', setProviders);
            } else {
                setProviders([selectAllProviders])
                setSelectAllProviders(prevState => {
                    prevState.checked = true;
                    return prevState
                });
            }
        }, [selectedProviders]);

        const setState = async (state, setState) => {
            // Mapping for async calls
            const mapping = {
                services: async () => await getManagerEnterpriseServicesTypes(enterpriseId),
                providers: async () => await getManagerEnterpriseEmployees(enterpriseId),
            };

            // Apply async call
            const res = await mapping[state]();
            if (res) {
                const current = res;
                const prefixArr = [state == "providers" ? selectAllProviders : selectAllServices];
                setState(prefixArr.concat(current));
            }
        };


        const updateSelected = (state, selectedSetter, index, id) => {
            selectedSetter((prevState => {
                let newState = prevState;
                if (id === "") {
                    if (prevState.has(id)) {
                        return new Set()
                    } else {
                        return new Set([""])
                    }
                } else {
                    prevState.has(id) ? newState.delete(id) : newState.add(id)
                }
                ;
                return new Set(newState);
            }))

        }


        return (
            <div style={{marginRight: "10px", marginLeft: "10px"}}>
                <LabelledOutline id="from" label={`${translate2("from")}`} width={'210px'}>
                    <TextField
                        fullWidth={true}
                        id="datetime-local"
                        // label={`${translate2("from")}:`}
                        value={fromDateTime}
                        onChange={(e) => {
                            let value = e.target.value;
                            setFromDateTime(value);
                            onChangeFromDate && onChangeFromDate(value)
                        }}
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </LabelledOutline>
                <LabelledOutline id="to" label={`${translate2("to")}`} width={'210px'}>
                    <TextField
                        fullWidth={true}
                        id="datetime-local"
                        // label={`${translate2("to")}:`}
                        value={toDateTime}
                        onChange={(e) => {
                            let value = e.target.value;
                            setToDateTime(value);
                            onChangeToDate && onChangeToDate(value)
                        }}
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </LabelledOutline>
                <DoreGroupCheckBox
                    icon="business_center"
                    dataArr={services}
                    formLabel={translate2("services")}
                    formHelperText=""
                    onClick={(index, id) => {
                        updateSelected("services", setSelectedServices, index, id)
                    }}
                />
                <br/>
                <DoreGroupCheckBox
                    icon="account_circle"
                    dataArr={providers}
                    formLabel={translate2("serviceProvider")}
                    formHelperText=""
                    onClick={(index, id) => {
                        updateSelected("providers", setSelectedProviders, index, id)
                    }}
                />

                <br/>
                <br/>

                <LabelledOutline id="description" label={`${translate2("description")}`} width={'100%'} >
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={description}
                    onChange={(e) => {
                        let value = e.target.value;
                        setDescription(value)
                        onChangeDescription && onChangeDescription(value)
                    }}
                    // label={translate2("description")}
                    type="text"
                    fullWidth
                />
                </LabelledOutline>
            </div>
        );
    }
;


export default connect(state => ({
    loading: state.loading,
}))(AddNewVacation);
