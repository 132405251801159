import {grey, indigo, pink, red} from '@material-ui/core/colors';

// Colors
export const MAIN_COLOR_A = indigo[500];
export const MAIN_COLOR_B = indigo[400];
export const MAIN_COLOR_C = indigo[200];
export const SECOND_COLOR_A = grey[500];
export const SECOND_COLOR_B = grey[400];
export const THIRD_COLOR = '#ffffff';

export const ERROR_COLOR_A = pink.A400;
export const ERROR_COLOR_B = red.A200;
export const ERROR_COLOR_C = red.A100;

export const MENU_COLOR = indigo;

// Sizes
export const INPUT_WIDTH = '14.5rem';
export const INPUT_HEIGHT = '3rem';
export const INPUT_PADDING_TOP = '1.4rem';