import React, {useEffect, useState} from 'react';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Icon} from '@material-ui/core';
import {INPUT_HEIGHT, INPUT_PADDING_TOP, MAIN_COLOR_A, SECOND_COLOR_A, THIRD_COLOR} from '../../styles';
import {DInputFieldSkeleton} from './DoreInputField';
import {makeStyles} from '@material-ui/core/styles';

/**
 * ======================================================
 * =================[Components styles]==================
 * ======================================================
 */
const useStyles = makeStyles({
  root: {
    marginBottom: '1rem',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      color: SECOND_COLOR_A,
      height: INPUT_HEIGHT,
      boxSizing: 'border-box',
      paddingTop: INPUT_PADDING_TOP,
    }
  },
});

/**
 * =======================================================
 * =================[Date Picker Toolbar]=================
 * =======================================================
 */
const DoreDatePickerToolbar = ({ date }) => {
  const [dd, setDD] = useState('');
  const [mm, setMM] = useState('');
  const [yyyy, setYYYY] = useState('');
  useEffect(() => {
    // Set state
    setDD(date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());
    setMM((date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1);
    setYYYY(date.getFullYear());
    // Work around to change the pickersMonthSelectionContainer, ltr dir, months label's
    const pickersMonthSelectionContainer = document.querySelector('.MuiPickersMonthSelection-container');
    if (pickersMonthSelectionContainer) {
      pickersMonthSelectionContainer.dir = 'ltr';
      Array.from(pickersMonthSelectionContainer.childNodes).forEach((el, index) => {
        const monthNumber = (index + 1) < 10 ? `${0}${index + 1}` : `${index + 1}`;
        el.innerText = monthNumber;
      });
    }
    // Another work around to update the day section on the calendar
    const dayPickerView = document.querySelector('.MuiPickersBasePicker-pickerView');
    if (dayPickerView) {
      dayPickerView.dir = 'ltr';
      const header = document.querySelector('.MuiPickersCalendarHeader-switchHeader');
      if (header) {
        header.dir = 'rtl';
      }
    }
  }, [date]);
  return (
    <div style={{
      backgroundColor: MAIN_COLOR_A,
      color: THIRD_COLOR,
      textAlign: 'center',
      paddingTop: '1rem',
      marginBottom: '1rem'
    }}>
      <h1 dir="ltr">{dd} / {mm} / {yyyy}</h1>
    </div>
  )
};

/**
 * =======================================================
 * =================[Doretou Date Picker]=================
 * =======================================================
 */
const DDatePicker = (
  {
    inputVariant = 'outlined',
    disableFutureDates = true,
    openTo = 'year',
    format = 'dd/MM/yyyy',
    views = ['year', 'month', 'date'],
    label,
    value,
    onChange,
    icon,
    cancelLabel,
    okLabel,
    clearLabel,
    clearable = false,
    showTodayButton = false,
    todayLabel,
    invalidLabel,
    invalidDateMessage,
    disableToolbar = false,
    autoOk = false,
    allowKeyboardControl = false,
    ToolbarComponent = params => <DoreDatePickerToolbar {...params} />,
    renderDay,
    iconColor = SECOND_COLOR_A
  }
) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        fullWidth
        className={classes.root}
        inputVariant={inputVariant}
        disableFuture={disableFutureDates}
        openTo={openTo}
        format={format}
        views={views}
        label={label}
        value={value}
        onChange={onChange}
        cancelLabel={cancelLabel}
        clearable={clearable}
        clearLabel={clearLabel}
        okLabel={okLabel}
        showTodayButton={showTodayButton}
        todayLabel={todayLabel}
        invalidLabel={invalidLabel}
        invalidDateMessage={invalidDateMessage}
        disableToolbar={disableToolbar}
        autoOk={autoOk}
        allowKeyboardControl={allowKeyboardControl}
        ToolbarComponent={ToolbarComponent}
        renderDay={renderDay}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon style={{ color: iconColor }}>{icon}</Icon>
            </InputAdornment>
          )
        }}
      />
    </MuiPickersUtilsProvider>
  )
};

const DoreDatePicker = (props) => (
  <div>
    {props.loading.skeleton && <DInputFieldSkeleton />}
    {!props.loading.skeleton && <DDatePicker {...props} />}
  </div>
);

export default DoreDatePicker;
