import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import DoreInputField from "../../../../shared/DoreInputField";
import DoreDatePicker from "../../../../shared/DoreDatePicker";
import {dateSimpleFormat} from "../../../../../utils/utils";
import {getUser} from "../../../../../services/storageService";
import {ENTERPRISE_STATUSES} from "../../../../../utils/consts";
import {translate2} from "../../../../../services/translateService";

const NewServiceType = ({
                            loading,
                            inputName, onChangeName,
                            inputDesc, onChangeDescription,
                            inputEffDate, onChangeEffectiveDate,
                            inputExpDate, onChangeExpirationDate,
                            inputDuration, onChangeDuration,
                            inputDaysToShow, onChangeDaysToShow
                        }
) => {

    const {enterpriseStatus} = getUser();
    const isNewEnterprise = enterpriseStatus === ENTERPRISE_STATUSES.NEW;
    const [name, setName] = useState("");
    const [duration, setDuration] = useState(inputDuration);
    const [daysToShow, setDaysToShow] = useState(inputDaysToShow);
    const [description, setDescription] = useState("");
    const [effectiveDate, setEffectiveDate] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    useEffect(() => {
        setServiceName(inputName)
    }, [inputName]);
    useEffect(() => {
        console.log("inputDuration", inputDuration)
        setServiceDuration(inputDuration)
    }, [inputDuration]);
    useEffect(() => {
        setServiceDaysToShow(inputDaysToShow)
    }, [inputDaysToShow]);
    useEffect(() => {
        setServiceDesc(inputDesc)
    }, [inputDesc]);
    useEffect(() => {
        console.log("inputEffDate", inputEffDate)
        setServiceEffDate(inputEffDate)
    }, [inputEffDate]);
    useEffect(() => {
        setServiceExpDate(inputExpDate)
    }, [inputExpDate]);


    function setServiceExpDate(simpleDate) {
        setExpirationDate(simpleDate || null);
        onChangeExpirationDate && onChangeExpirationDate(simpleDate);
    }

    function setServiceEffDate(simpleDate) {
        setEffectiveDate(simpleDate || new Date());
        onChangeEffectiveDate && onChangeEffectiveDate(simpleDate)
    }

    function setServiceDuration(v) {
        setDuration(v || '')
        onChangeDuration && onChangeDuration(v)
    }

    function setServiceDaysToShow(v) {
        setDaysToShow(v || '')
        onChangeDaysToShow && onChangeDaysToShow(v)
    }

    function setServiceDesc(v) {
        setDescription(v || '')
        onChangeDescription && onChangeDescription(v)
    }

    function setServiceName(v) {
        setName(v || '')
        onChangeName && onChangeName(v)
    }

    return (
        <div>
            <DoreInputField
                loading={loading}
                label={translate2("name")}
                icon="business_center"
                value={name}
                onChange={e => {
                    let v = e.target.value;
                    setServiceName(v);
                }}
            />
            <DoreInputField
                loading={loading}
                label={translate2("description")}
                icon="description"
                value={description}
                onChange={e => {
                    let v = e.target.value;
                    setServiceDesc(v);
                }}
            />
            <DoreInputField
                loading={loading}
                type="number"
                label={translate2("numOfDaysToShow")}
                icon="date_range"
                value={daysToShow}
                onChange={e => {
                    let v = parseInt(e.target.value);
                    if (v > 0) {
                        setServiceDaysToShow("" + v);
                    }
                }}
            />
            <DoreInputField
                loading={loading}
                type="number"
                label={translate2("durationInMin")}
                icon="hourglass_empty"
                value={duration}
                onChange={e => {
                    let v = parseInt(e.target.value);
                    if (v > 0) {
                        setServiceDuration("" + v);
                    }
                }}
            />
            <DoreDatePicker
                disableFutureDates={false}
                loading={loading}
                label={translate2("serviceStartDate")}
                okLabel={translate2("ok")}
                cancelLabel={translate2("cancel")}
                icon="today"
                value={effectiveDate}
                onChange={date => {
                    let simpleDate = dateSimpleFormat(date);
                    setServiceEffDate(simpleDate);
                }}
            />
            <DoreDatePicker
                disableFutureDates={false}
                loading={loading}
                label={translate2("serviceEndDate")}
                okLabel={translate2("ok")}
                cancelLabel={translate2("cancel")}
                icon="today"
                value={expirationDate}
                onChange={date => {
                    let simpleDate = dateSimpleFormat(date);
                    setServiceExpDate(simpleDate);
                }}
            />
        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewServiceType);
