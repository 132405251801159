import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {green} from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import {translate2} from "../../../services/translateService";
import DoreInputField from "../DoreInputField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";


const GreenSwitch = withStyles({
    switchBase: {
        color: green[0],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const EditCalendarSettings = ({
                                  loading,
                                  inputSlotDuration,
                                  onChangeSlotDuration,
                                  inputAllowSlotsOverlap,
                                  onChangeAllowSlotsOverlap
                              }) => {


    const [allowSlotsOverlap, setAllowSlotsOverlap] = useState(false);
    const [slotDuration, setSlotDuration] = useState(10);

    useEffect(() => {
        if (inputSlotDuration) {
            setSlotDuration(inputSlotDuration)
        }
    }, [inputSlotDuration])

    useEffect(() => {
        if (inputAllowSlotsOverlap) {
            setAllowSlotsOverlap(inputAllowSlotsOverlap)
        }
    }, [inputAllowSlotsOverlap])


    return (

        <div style={{marginRight: '12px'}}>
            <DoreInputField
                loading={loading}
                type="number"
                label={translate2("calendarResolution")}
                icon="aspect_ratio"
                value={slotDuration}
                onChange={e => {
                    let durationStr = e.target.value;
                    if (durationStr && parseInt(durationStr) > 0) {
                        setSlotDuration(durationStr)
                        onChangeSlotDuration(durationStr)
                    } else {
                        setSlotDuration(1)
                        onChangeSlotDuration(1)
                    }
                }}
            />
            <FormControlLabel
                control={
                    <GreenSwitch
                        checked={allowSlotsOverlap}
                        onChange={() => {
                            let oldStat = allowSlotsOverlap;
                            setAllowSlotsOverlap(!oldStat)
                            onChangeAllowSlotsOverlap(!oldStat)
                        }}
                        name="allowSlotsOverlap"
                        color="secondary"
                    />
                }
                label={translate2("allowSlotsOverlap")}
            />
            <br/>
            <br/>
            <Button
                onClick={()=>{
                    setAllowSlotsOverlap(false)
                    onChangeAllowSlotsOverlap(false)
                    setSlotDuration(10)
                    onChangeSlotDuration(10)
                }}
                size={"small"}
                variant="contained"
                // color="gray"
                startIcon={<Icon>restore</Icon>}
            >
                {translate2("restoreSettings")}
            </Button>

        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EditCalendarSettings);
