import {clone} from '../utils/utils';
import {HEBREW} from '../utils/consts';
import {createDialog} from '../services/dialogService';
import {createSnackbar} from '../services/snackbarService';
import {createConfirmDialog} from '../services/confirmDialogService';
import {
    SET_BREAD_CRUMB,
    SET_CONFIRM_DIALOG,
    SET_CURRENT_LANGUAGE,
    SET_DIALOG,
    SET_IS_MENU_BAR_OPEN,
    SET_LOADING,
    SET_MENU_BAR_ITEMS,
    SET_SNACKBAR
} from './actionsTypes';

const initialState = {
  loading: { spinner: false, skeleton: false },
  breadCrumb: [],
  currentLanguage: HEBREW,
  dialog: createDialog(),
  confirmDialog: createConfirmDialog(),
  snackbar: createSnackbar(),
  isMenuBarOpen: false,
  menuBarItems: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return clone(state, action, 'loading');
    case SET_BREAD_CRUMB:
      return clone(state, action, 'breadCrumb');
    case SET_CURRENT_LANGUAGE:
      return clone(state, action, 'currentLanguage');
    case SET_SNACKBAR:
      return clone(state, action, 'snackbar');
    case SET_DIALOG:
      return clone(state, action, 'dialog');
    case SET_CONFIRM_DIALOG:
      return clone(state, action, 'confirmDialog');
    case SET_IS_MENU_BAR_OPEN:
      return clone(state, action, 'isMenuBarOpen');
    case SET_MENU_BAR_ITEMS:
      return clone(state, action, 'menuBarItems');
    default:
      break;
  }
  return state;
};

export default rootReducer;
