import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import EnterpriseProfileHome from "./EnterpriseProfileHome";
import {onAddOrUpdateEmployeeClick} from "./employee/EnterpriseEmployeesProfile";
import {createEnterprise, getManagerEnterprises} from "../../../../services/dataService";
import {openConfirmDialog} from "../../../../services/confirmDialogService";
import {getUser, saveUser} from "../../../../services/storageService";
import NewEnterprise from "./AddOrUpdateNewEnterpriseForm";
import {ENTERPRISE_CATEGORIES, FIXED_TZ} from "../../../../utils/consts";
import DoreTopBar from "../../../shared/DoreTopBar";

/**
 * Enterprise Consumers Component
 *
 * @author Mohammad
 */
const EnterpriseProfile = ({loading}) => {
    //const user = getUser();
    const [refresh, setRefresh] = useState(false);
    const [enterprise, setEnterprise] = useState(null);
    const [isNewEnterprise, setIsNewEnterprise] = useState(getUser().enterpriseStatus === "" || getUser().enterpriseStatus === "NEW");
    const [newEnterpriseIcon, setNewEnterpriseIcon] = useState(false)

    useEffect(() => {
        setIsNewEnterprise(getUser().enterpriseStatus === "" || getUser().enterpriseStatus === "NEW");
        (async () => {
            const enterprises = await getManagerEnterprises();
            if (enterprises && enterprises[0]) {
                setEnterprise(enterprises[0]);
                setNewEnterpriseIcon(false)
            } else {
                setEnterprise({})
            }
        })();
    }, [refresh]);


    const [newEnterpriseName, setNewEnterpriseName] = useState("");
    const [newEnterpriseDesc, setNewEnterpriseDesc] = useState("");
    const [newEnterpriseCity, setNewEnterpriseCity] = useState("");
    const [newEnterpriseEmail, setNewEnterpriseEmail] = useState("");
    const [newEnterprisePrivacyType, setNewEnterprisePrivacyType] = useState("");
    const [newEnterprisePhoneNumber, setNewEnterprisePhoneNumber] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);


    const refreshPage = () => {
        setRefresh(prevState => !prevState)
    }

    const openCreateEnterpriseForm = async () => {
        setNewEnterpriseIcon(false)
        let titleInfo = ''
        let subTitleInfo = ''
        openConfirmDialog(null, titleInfo, subTitleInfo,
            <NewEnterprise/>,
            () => {
                let body = {
                    "name": newEnterpriseName,
                    "description": newEnterpriseDesc,
                    "categoryType": ENTERPRISE_CATEGORIES.BARBER,
                    "address": {
                        "city": newEnterpriseCity
                    },
                    "phoneNumber": newEnterprisePhoneNumber,
                    "email": newEnterpriseEmail,
                    "timeZone": FIXED_TZ.JRS,
                    "privacy": newEnterprisePrivacyType
                }
                createEnterprise(body).then(r => {
                    refreshPage();
                    let user = getUser();
                    user.enterpriseId = r.enterpriseId;
                    user.enterpriseStatus = 'NEW'
                    saveUser(user)
                    onAddOrUpdateEmployeeClick(r.enterpriseId)
                })
            }, () => {
                setNewEnterpriseIcon(true)
            },
            false,
            false,
            true
        );
    }

    return (isNewEnterprise)
        ? (
            <div className="profile-scope">
                {/*{isMobile &&  <TopBar loading={loading}/>}*/}
                {enterprise && <NewEnterprise enterprise={enterprise} onActivate={() => refreshPage()}/>}
            </div>
        )
        : <div>
            <DoreTopBar loading={loading}/>
            {enterprise &&
            <EnterpriseProfileHome
                enterprise={enterprise}
                loading={loading}
                refreshPage={refreshPage}
                setEnterprise={setEnterprise}
            />}

        </div>
    // : (<div>
    //         <TopBar loading={loading}/>
    //         {enterprise && <DoreTabs
    //             initSelectedTab={0}
    //             onChangeTab={(idx) => setSelectedTab(idx)}
    //             loading={loading}
    //             tabs={[
    //                 {
    //                     label: translate2("venue"),
    //                     icon: 'home',
    //                     children:
    //                         <EnterpriseProfileHome
    //                             enterprise={enterprise}
    //                             loading={loading}
    //                             refreshPage={refreshPage}
    //                             setEnterprise={setEnterprise}
    //                         />
    //                 },
    //                 {
    //                     label: translate2("employees"),
    //                     icon: 'account_circle',
    //                     children:
    //                         <EnterpriseProfileEmployees
    //                             loading={loading}
    //                             isToShowTopBar={false}
    //                         />
    //                 },
    //                 {
    //                     label: translate2("services"),
    //                     icon: 'business_center',
    //                     children: <EnterpriseServicesProfile loading={loading} isToShowTopBar={false}/>
    //                 },
    //                 {
    //                     label: translate2("workingHours"),
    //                     icon: 'date_range',
    //                     children:
    //                         <FullWorkingHours
    //                             loading={loading}
    //                             isToShowTopBar={false}
    //                         />
    //                 },
    //             ]}
    //         />}
    //
    //         <DoreFab
    //             color="primary"
    //             onClick={openCreateEnterpriseForm}
    //             inout={newEnterpriseIcon}
    //             icon="add"
    //             loading={loading}
    //             variant="extended"
    //             label={translate2("createVenuePage")}
    //         />
    //
    //     </div>
    // );
};

export default connect(state => ({
    loading: state.loading,
}))(EnterpriseProfile);
