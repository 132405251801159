import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DoreExpansionPanel from "../../../shared/DoreExpansionPanel";
import {translate2} from "../../../../services/translateService";
import {activateManagerEnterprise, deactivateManagerEnterprise} from "../../../../services/dataService";
import {
    createOrUpdateNewEnterprise,
    enterpriseInfoComponent,
    EnterpriseSettingsComponent
} from "./EditEnterpriseInfoForm";
import {ENTERPRISE_STATUS_TYPES} from "../../../../utils/consts";
import {openConfirmDialog} from "../../../../services/confirmDialogService";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import DoreFab from "../../../shared/DoreFab";
import {Portal} from "@material-ui/core";
// import Typography from 'material-ui/Typography';
// import { typography } from 'material-ui/styles';


const EnterpriseProfileHome = ({enterprise, refreshPage, loading, setEnterprise}) => {

    // const [enterprise, setEnterprise] = useState(null);
    const [isActive, setIsActive] = useState("ACTIVE" === enterprise.status);

    useEffect(() => {
        setEnterprise(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            newState.status = isActive ? ENTERPRISE_STATUS_TYPES.ACTIVE.id : ENTERPRISE_STATUS_TYPES.INACTIVE.id;
            return newState;
        })
    }, [isActive]);


    const onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }
    const onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    const onActivate = () => {
        activateManagerEnterprise(enterprise.id).then(r => {
            refreshPage && refreshPage()
        })
    }

    const onDeactivate = () => {
        deactivateManagerEnterprise(enterprise.id).then(r => {
            refreshPage && refreshPage()
        })
    }


    const onChangeEnterpriseStatus = () => {

        let titleInfo =
            isActive
                ? `${translate2("areYouSureYouWantToDeactivateEnterprise")}?`
                : `${translate2("areYouSureYouWantToActivateEnterprise")}?`
        openConfirmDialog(faPowerOff, titleInfo, null, null, () => {
            setIsActive(prevState => !prevState)
        });

    };


    return (
        <div>
            {enterprise &&
            <DoreExpansionPanel title={translate2("enterpriseDetails")} icon="store" initialExpanded={true}>
                {enterpriseInfoComponent(enterprise, setEnterprise, loading, false)}
            </DoreExpansionPanel>
            }
            {enterprise &&
            <DoreExpansionPanel
                title={translate2("enterpriseSettings")}
                icon="settings"
                initialExpanded={false}
            >
                <EnterpriseSettingsComponent
                    isActive={isActive}
                    onChangeEnterpriseStatus={onChangeEnterpriseStatus}
                    inputPrivacyType={enterprise.privacy}
                    onChangePrivacyType={(privacyType) => {
                        setEnterprise(prevState => {
                            let newState = JSON.parse(JSON.stringify(prevState));
                            newState.privacy = privacyType;
                            return newState;
                        })
                    }}
                />
            </DoreExpansionPanel>
            }
            <Portal container={document.body}>
                <DoreFab
                    onClick={() => {
                        createOrUpdateNewEnterprise(enterprise)
                    }}
                    inout={true}
                    icon="save"
                    loading={loading}
                    variant="extended"
                    label={translate2("save")}
                />
            </Portal>

            {/*<DoreExpansionPanel title={translate2("gpsLocation")} icon="room">*/}
            {/*    <Map*/}
            {/*        item*/}
            {/*        xs = { 12 }*/}
            {/*        style = {{*/}
            {/*            width: '50vw',*/}
            {/*            height: '75vh',*/}
            {/*            'marginLeft': 'auto',*/}
            {/*            'marginRight': 'auto'*/}
            {/*        }}*/}
            {/*        google = {{} }*/}
            {/*        onClick = { onMapClick }*/}
            {/*        zoom = { 14 }*/}
            {/*        initialCenter = {{ lat: 39.648209, lng: -75.711185 }}*/}
            {/*    >*/}
            {/*        <Marker*/}
            {/*            onClick = { onMarkerClick }*/}
            {/*            title = { 'Changing Colors Garage' }*/}
            {/*            position = {{ lat: 39.648209, lng: -75.711185 }}*/}
            {/*            name = { 'Changing Colors Garage' }*/}
            {/*        />*/}
            {/*        <InfoWindow*/}
            {/*            // marker = { this.state.activeMarker }*/}
            {/*            // visible = { this.state.showingInfoWindow }*/}
            {/*        >*/}
            {/*            <Paper>*/}
            {/*                <Typography*/}
            {/*                    variant = 'headline'*/}
            {/*                    component = 'h4'*/}
            {/*                >*/}
            {/*                    Changing Colors Garage*/}
            {/*                </Typography>*/}
            {/*                <Typography*/}
            {/*                    component = 'p'*/}
            {/*                >*/}
            {/*                    98G Albe Dr Newark, DE 19702 <br />*/}
            {/*                    302-293-8627*/}
            {/*                </Typography>*/}
            {/*            </Paper>*/}
            {/*        </InfoWindow>*/}
            {/*    </Map>*/}
            {/*</DoreExpansionPanel>*/}

            {/*<DoreFab*/}
            {/*    color={isActive ? "secondary" : "primary"}*/}
            {/*    onClick={isActive ? onDeactivate : onActivate}*/}
            {/*    inout={enterprise}*/}
            {/*    icon={isActive ? "blur_off" : "blur_on"}*/}
            {/*    loading={loading}*/}
            {/*    variant="extended"*/}
            {/*    label={translate2(isActive ? "deactivatePage" : "activatePage")}*/}
            {/*/>*/}
        </div>
    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseProfileHome);


