import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    createNewServiceTypeVersion,
    deleteManagerEnterpriseServiceTypeVersion,
    getManagerEnterpriseServiceTypeById,
    updateManagerEnterpriseServiceTypeById
} from "../../../../../services/dataService";
import {getUser} from "../../../../../services/storageService";
import DoreTopBar from "../../../../shared/DoreTopBar";
import DoreExpansionPanel from "../../../../shared/DoreExpansionPanel";
import DoreList from "../../../../shared/DoreList";
import {goToEnterpriseServiceTypeVersionPage} from "../../../../../services/pageService";
import {alertSuccess} from "../../../../../services/snackbarService";
import {openConfirmDialog} from "../../../../../services/confirmDialogService";
import NewVersion from "./version/AddNewServiceTypeVersionForm";
import {translate2} from "../../../../../services/translateService";
import DoreInputField from "../../../../shared/DoreInputField";
import DoreSpeedDial from "../../../../shared/DoreSpeedDial";
import {Portal} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

const EnterpriseServiceTypeProfile = ({match, loading}) => {

    const {enterpriseId} = getUser();
    let {params: {serviceTypeId}} = match;
    serviceTypeId = serviceTypeId.split('_')[0];
    const [serviceType, setServiceType] = useState({});
    const [serviceTypeInfo, setServiceTypeInfo] = useState(null);
    const [serviceTypeVersions, setServiceTypeVersions] = useState([]);
    const [refresh, setRefresh] = useState(1);
    let deletePressed = false;

    useEffect(() => {
        (async () => {
            const serviceTypeRes = await getManagerEnterpriseServiceTypeById(enterpriseId, serviceTypeId);
            if (serviceTypeRes) {
                setServiceType({
                    id: serviceTypeRes.id,
                    name: serviceTypeRes.name,
                    versions: serviceTypeRes.versions,
                    description: serviceTypeRes.description,
                    daysToShow: serviceTypeRes.daysToShow
                });
            }
        })();
    }, [refresh]);

    const getVersionTitle = (version) => {

        let expirationDate = version.expirationDate ? version.expirationDate : ' دون تحديد';
        return `${version.effectiveDate} - ${expirationDate}`;
    }

    useEffect(() => {
        setServiceTypeInfo({
            name: serviceType.name,
            description: serviceType.description,
            daysToShow: serviceType.daysToShow,
        });
        serviceType && serviceType.versions && serviceType.versions.sort((a, b) => (a.versionId > b.versionId) ? 1 : -1);
        serviceType && serviceType.versions && setServiceTypeVersions(serviceType.versions.map(version => ({
            id: version.versionId,
            title: getVersionTitle(version),
            expirationDate: version.expirationDate,
            effectiveDate: version.effectiveDate,
            status: version.status,
            avatar: true,
            //subTitle: `${version.duration}الوقت: د`,
            subTitle: "ACTIVE" == version.status ? translate2("active") : translate2("inactive"),
        })));
    }, [serviceType]);

    const onServiceTypeVersionClick = (serviceTypeVersion) => {
        if (deletePressed) {
            deleteManagerEnterpriseServiceTypeVersion(enterpriseId, serviceTypeId, serviceTypeVersion.id).then(() => {
                deletePressed = false;
                refreshPage();
            });
        } else {
            goToEnterpriseServiceTypeVersionPage(serviceTypeId, serviceTypeVersion.id);
        }
    }


    const createNewVersion = (versionId, prevVersionExpirationDate) => {
        let versionIdR = versionId;
        let effectiveDateR = null;
        let expirationDateR = null;
        let prevVersionExpirationDateR = prevVersionExpirationDate;

        let titleInfo = `${translate2("newVersion")}:`
        let subTitleInfo = ''
        openConfirmDialog(null, titleInfo, subTitleInfo,
            <NewVersion loading={loading}
                        versionIdR={versionIdR}
                        onChangeSourceVersion={(v) => {
                            versionIdR = v
                            prevVersionExpirationDateR = v != -1 ? serviceTypeVersions[v].expirationDate : null;
                        }}
                        prevVersionExpirationDateR={prevVersionExpirationDateR}
                        onChangePrevVersionExpirationDate={(date) => prevVersionExpirationDateR = date}
                        effectiveDateR={effectiveDateR} onChangeEffectiveDate={(date) => effectiveDateR = date}
                        expirationDateR={expirationDateR} onChangeExpirationDate={(date) => expirationDateR = date}
                        serviceTypeVersions={serviceTypeVersions}
            />,
            () => {
                createNewVersionCall(effectiveDateR, expirationDateR, versionIdR, prevVersionExpirationDateR).then(r => console.log(r))
            });
    };

    const createNewVersionCall = async (effectiveDate, expirationDate, versionId, prevVersionExpirationDate) => {
        createNewServiceTypeVersion(enterpriseId, serviceTypeId, effectiveDate, expirationDate, versionId, prevVersionExpirationDate).then(() => {
                alertSuccess(translate2("successMsg"));
                refreshPage();
            }
        );
    }

    const refreshPage = () => {
        setRefresh((prevState => prevState * (-1)))
    }


    const onCreateNewVersionClick = () => {
        let lastVersionIdIndex = serviceTypeVersions.length - 1;
        let versionId = serviceTypeVersions[lastVersionIdIndex].id.toString();
        let prevVersionExpirationDate = serviceTypeVersions[lastVersionIdIndex].expirationDate
        createNewVersion(versionId, prevVersionExpirationDate)
    }

    const onSave = () => {
        if (serviceTypeInfo) {
            let body = {
                "name": serviceTypeInfo.name,
                "description": serviceTypeInfo.description,
                "daysToShow": serviceTypeInfo.daysToShow
            }
            updateManagerEnterpriseServiceTypeById(enterpriseId, serviceTypeId, body).then((r) => {
                console.log("updated", r)
            });
        }
    }

    const actions = [
        {icon: <Icon>save</Icon>, name: translate2("save"), onClick: onSave},
        {icon: <Icon>add</Icon>, name: translate2("addNewServiceVersion"), onClick: onCreateNewVersionClick},
    ];

    return (
        <div>
            <DoreTopBar loading={loading}/>
            {serviceTypeInfo &&
            // <DoreExpansionPanel title={translate2("serviceDetails")} icon="account_circle">
            <div style={{marginRight: "10px", marginLeft: "10px"}}>
                <br/>
                <DoreInputField
                    loading={loading}
                    label={translate2("name")}
                    icon="business_center"
                    value={serviceTypeInfo.name}
                    onChange={e => {
                        let v = e.target.value;
                        setServiceTypeInfo(prevState => {
                            let newState = JSON.parse(JSON.stringify(prevState));
                            newState.name = v;
                            return newState;
                        });
                    }}
                />
                <DoreInputField
                    loading={loading}
                    label={translate2("description")}
                    icon="description"
                    value={serviceTypeInfo.description}
                    onChange={e => {
                        let v = e.target.value;
                        setServiceTypeInfo(prevState => {
                            let newState = JSON.parse(JSON.stringify(prevState));
                            newState.description = v;
                            return newState;
                        });
                    }}
                />
                <DoreInputField
                    loading={loading}
                    type="number"
                    label={translate2("numOfDaysToShow")}
                    icon="date_range"
                    value={serviceTypeInfo.daysToShow}
                    onChange={e => {
                        let v = parseInt(e.target.value);
                        if (v > 0) {
                            setServiceTypeInfo(prevState => {
                                let newState = JSON.parse(JSON.stringify(prevState));
                                newState.daysToShow = v;
                                return newState;
                            });
                        }
                    }}
                />

                <DoreExpansionPanel title={translate2("serviceVersions")} icon="account_circle">
                    <div>
                        <DoreList
                            list={serviceTypeVersions}
                            loading={loading}
                            onClick={onServiceTypeVersionClick}
                            onDelete={() => {
                                deletePressed = true;
                            }}
                        />

                    </div>
                </DoreExpansionPanel>
            </div>}
            <Portal container={document.body}>

                <DoreSpeedDial editIcon={true} actions={actions} openAction={() => console.log('openAction')}
                               closeAction={() => console.log('closeAction')}/>
            </Portal>

        </div>
    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseServiceTypeProfile);
