import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Pagination from "@material-ui/lab/Pagination";


const DorePagination = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        },
    }));
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Pagination count={props.numOfPages} onChange={props.handlePageChange} variant="outlined" shape="rounded"/>
        </div>
    );
}

export default DorePagination;