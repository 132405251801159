import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const DoreCheckBox = (props) => {


    let data = props.data;
    return (
        <>
            <FormControlLabel
                control={<Checkbox checked={data.checked} onChange={() => props.onClick(props.index,data.id)} name={data.name || data.displayName}/>}
                label={data.name || data.displayName }
            />
        </>
    );
}

export default DoreCheckBox;