import React from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';

import history from './history';
import SignUp from './components/pages/SignUp';
import Profile from './components/pages/Profile';
import Bookings from './components/pages/Bookings';
import Services from './components/pages/Services';
import Providers from './components/pages/Providers';
import AvailableDates from './components/pages/AvailableDates';
import TimeSlots from './components/pages/TimeSlots';
import EnterpriseHome from './components/pages/EnterpriseHome';
import EnterpriseBooks from './components/pages/manager/bookings/EnterpriseBooks';
import EnterpriseVacations from './components/pages/manager/vacation/EnterpriseVacations';
import EnterpriseSubscribers from './components/pages/manager/subscriber/EnterpriseSubscribers';
import EnterpriseProfile from './components/pages/manager/profile/EnterpriseProfile';
import EnterpriseSubscriberProfile from './components/pages/manager/subscriber/EnterpriseSubscriberProfile';

import {
    AVAILABLE_DATES_PATH,
    BOOKINGS_PATH,
    ENTERPRISE_BOOKS_PATH,
    ENTERPRISE_EMPLOYEE_PROFILE_PATH,
    ENTERPRISE_EMPLOYEES_PATH,
    ENTERPRISE_HOME_PATH,
    ENTERPRISE_PROFILE_PATH,
    ENTERPRISE_SERVICE_PROVIDER_PROFILE_PATH,
    ENTERPRISE_SERVICE_TYPE_PROFILE_PATH,
    ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE_PATH,
    ENTERPRISE_SERVICES_PATH,
    ENTERPRISE_SUBSCRIBER_PROFILE_PATH,
    ENTERPRISE_SUBSCRIBERS_PATH,
    ENTERPRISE_VACATIONS_PATH,
    ENTERPRISE_WH_PATH,
    ENTERPRISES_PATH,
    PROFILE_PATH,
    PROVIDERS_PATH,
    SERVICES_PATH,
    SIGN_UP_PATH,
    TIME_SLOTS_PATH
} from './services/pageService';
import EnterpriseServiceTypeProfile from "./components/pages/manager/profile/serviceType/EnterpriseServiceTypeProfile";
import EnterpriseServiceProviderProfile
    from "./components/pages/manager/profile/serviceProvider/EnterpriseServiceProviderProfile";
import EnterpriseServiceTypeVersionsProfile
    from "./components/pages/manager/profile/serviceType/version/EnterpriseServiceTypeVersionsProfile";
import EnterpriseSingleEmployeeProfile
    from "./components/pages/manager/profile/employee/EnterpriseSingleEmployeeProfile";
import EnterpriseServicesProfile from "./components/pages/manager/profile/serviceType/EnterpriseServicesProfile";
import EnterpriseEmployeesProfile from "./components/pages/manager/profile/employee/EnterpriseEmployeesProfile";
import ConsumerMainPage from "./components/pages/ConsumerMainPage";
import EnterpriseWorkingHours from "./components/pages/manager/profile/workingHours/EnterpriseWorkingHours";

const router = () => (
  <Router history={history}>
    <Switch>
      <Route exact path={SIGN_UP_PATH} component={SignUp} />
      <Route exact path={PROFILE_PATH} component={Profile} />
      <Route exact path={ENTERPRISES_PATH} component={ConsumerMainPage} />
      <Route exact path={ENTERPRISE_HOME_PATH} component={EnterpriseHome} />
      <Route exact path={SERVICES_PATH} component={Services} />
      <Route exact path={PROVIDERS_PATH} component={Providers} />
      <Route exact path={AVAILABLE_DATES_PATH} component={AvailableDates} />
      <Route exact path={TIME_SLOTS_PATH} component={TimeSlots} />
      <Route exact path={BOOKINGS_PATH} component={Bookings} />
      <Route exact path={ENTERPRISE_BOOKS_PATH} component={EnterpriseBooks} />
      <Route exact path={ENTERPRISE_VACATIONS_PATH} component={EnterpriseVacations} />
      <Route exact path={ENTERPRISE_WH_PATH} component={EnterpriseWorkingHours} />
      <Route exact path={ENTERPRISE_SUBSCRIBERS_PATH} component={EnterpriseSubscribers} />
      <Route exact path={ENTERPRISE_SUBSCRIBER_PROFILE_PATH} component={EnterpriseSubscriberProfile} />
      <Route exact path={ENTERPRISE_PROFILE_PATH} component={EnterpriseProfile} />
      <Route exact path={ENTERPRISE_SERVICES_PATH} component={EnterpriseServicesProfile} />
      <Route exact path={ENTERPRISE_EMPLOYEES_PATH} component={EnterpriseEmployeesProfile} />
      <Route exact path={ENTERPRISE_EMPLOYEE_PROFILE_PATH} component={EnterpriseSingleEmployeeProfile}/>
      <Route exact path={ENTERPRISE_SERVICE_TYPE_PROFILE_PATH} component={EnterpriseServiceTypeProfile} />
      <Route exact path={ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE_PATH} component={EnterpriseServiceTypeVersionsProfile}/>
      <Route exact path={ENTERPRISE_SERVICE_PROVIDER_PROFILE_PATH} component={EnterpriseServiceProviderProfile} />
      <Redirect from="*" to={SIGN_UP_PATH} />
    </Switch>
  </Router>
);

export default router;
