import React, {useState} from 'react';
import TopBar from '../shared/DoreTopBar';
import logoSrc from "../../imgs/doretou-barber.jpg";
import {translate} from '../../services/translateService';
import {SECOND_COLOR_A, THIRD_COLOR} from '../../styles';
import {connect} from 'react-redux';
import {openDialog} from '../../services/dialogService';
import SignIn from './SignIn';
import DoreButton from '../shared/DoreButton';
import DoreForm from '../shared/DoreForm';
import DoreAvatar from '../shared/DoreAvatar';
import DoreInputField from '../shared/DoreInputField';
import {faSms} from '@fortawesome/free-solid-svg-icons';
import {clearStorage} from '../../services/storageService';

import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { getFirestore} from 'firebase/firestore';
import {firebase} from "../../utils/DoreFireBase";



const SignUp = ({dialog, loading, currentLanguage}) => {
    getFirestore(firebase)
    const {
        pageTranslation: {
            title,
            subTitle,
            phoneNumberLabel,
            getAccessCodeBtnText
        }
    } = translate('SignUp', currentLanguage);

    const [userPhoneNumber, setUserPhoneNumber] = useState('');


    const setUpRecaptcha = () => {

        const auth = getAuth();
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
            }
        }, auth);
    }

    const onSignInSubmit = () => {
       setUpRecaptcha();
        const auth = getAuth();
        const phoneNumber = "+972"+userPhoneNumber;
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                openDialog(<SignIn userPhoneNumber={userPhoneNumber} accessCodeInit={''}/>);
                // ...
            }).catch((error) => {
                console.log("error ::::"+error)
            // Error; SMS not sent
            // ...
        });
    }


    const handleSignUp = async () => {
        clearStorage();
        onSignInSubmit();
        // const res = await signUp(userPhoneNumber);
        // if (res) {
        //     //TODO: access code should not passed to SignIn component, it's just for development
        //     openDialog(<SignIn userPhoneNumber={userPhoneNumber} accessCodeInit={''}/>);
        // }
    };

    return (
        <div className="sign-up-scope">
            <div id="recaptcha-container"></div>
            <TopBar backgroundColor={THIRD_COLOR} color={SECOND_COLOR_A} menuBarBtn={false} breadcrumb={false}/>
            <DoreForm
                loading={loading}
                title={title}
                subTitle={subTitle}
                logo={<DoreAvatar src={logoSrc} loading={loading}/>}
            >
                <DoreInputField
                    loading={loading}
                    type="number"
                    label={phoneNumberLabel}
                    icon="local_phone"
                    value={userPhoneNumber || ''}
                    onChange={e => setUserPhoneNumber(e.target.value)}
                >
                </DoreInputField>
                <DoreButton
                    icon={faSms}
                    label={getAccessCodeBtnText}
                    loading={loading}
                    onClick={handleSignUp}
                    disabled={!userPhoneNumber}
                />
            </DoreForm>
        </div>
    );
};

export default connect(state => ({
    dialog: state.dialog,
    loading: state.loading,
    currentLanguage: state.currentLanguage,
}))(SignUp);
