/**
 * Redux actions file
 * Any REDUX action need to be defined here
 *
 * @author Mohammad Agbaryya
 */

import {
    SET_BREAD_CRUMB,
    SET_CONFIRM_DIALOG,
    SET_CURRENT_LANGUAGE,
    SET_DIALOG,
    SET_IS_MENU_BAR_OPEN,
    SET_LOADING,
    SET_MENU_BAR_ITEMS,
    SET_SNACKBAR
} from './actionsTypes';

export const setLoading = payload => ({ type: SET_LOADING, payload });
export const setBreadCrumb = payload => ({ type: SET_BREAD_CRUMB, payload });
export const setCurrentLanguage = payload => ({ type: SET_CURRENT_LANGUAGE, payload });
export const setDialog = payload => ({ type: SET_DIALOG, payload });
export const setConfirmDialog = payload => ({ type: SET_CONFIRM_DIALOG, payload });
export const setSnackbar = payload => ({ type: SET_SNACKBAR, payload });
export const setIsMenuBarOpen = payload => ({ type: SET_IS_MENU_BAR_OPEN, payload });
export const setMenuBarItems = payload => ({ type: SET_MENU_BAR_ITEMS, payload });
