import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {translate2} from "../../../services/translateService";
import DoreInputField from "../DoreInputField";
import {changeTimeFormat} from "../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import {Delete} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles((theme) => ({
    timePicker_container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    timePicker_textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const EditCalendarSlot = ({
                              loading,
                              inputEvent,
                              onChangeStartTime,
                              onChangeEndTime,
                              onDeleteEvent
                          }) => {

    const classes = useStyles();

    const [stTime, setStTime] = useState(null);
    const [event, setEvent] = useState(null);
    const [endTime, setEndTime] = useState(null);

    useEffect(() => {
        if (inputEvent) {
            setStTime(changeTimeFormat(inputEvent.start))
            setEndTime(changeTimeFormat(inputEvent.end))
            setEvent(inputEvent)
        }
    }, [inputEvent])

    return (
        stTime && endTime &&
        <div>
            <form className={classes.timePicker_container} noValidate>
                <DoreInputField
                    loading={loading}
                    value={stTime}
                    onChange={e => {
                        let newStartTime = e.target.value;
                        setStTime(newStartTime);
                        onChangeStartTime && onChangeStartTime(newStartTime)
                    }}
                    id="time"
                    label={translate2("startHour")}
                    type="time"
                    className={classes.timePicker_textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
            </form>
            <br/>
            <form className={classes.timePicker_container} noValidate>
                <DoreInputField
                    loading={loading}
                    value={endTime}
                    onChange={e => {
                        let newEndTime = e.target.value;
                        setEndTime(newEndTime);
                        onChangeEndTime && onChangeEndTime(newEndTime)
                    }}
                    id="time"
                    label={translate2("endHour")}
                    type="time"
                    //defaultValue={endTime}
                    className={classes.timePicker_textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
            </form>
            <br/>
            <IconButton
                size="small"
                color="secondary"
                aria-label="delete"
                onClick={() => {
                    onDeleteEvent && onDeleteEvent()
                }}
            >
                <Delete/>
                <label color={'gray'}>{translate2("deleteSlot")}</label>
            </IconButton>

        </div>

    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EditCalendarSlot);
