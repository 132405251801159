import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DoreTopBar from '../../../shared/DoreTopBar';
import {addSubscriber, getPagedEnterpriseSubscribers, searchSubscribers} from '../../../../services/dataService';
import DoreList from '../../../shared/DoreList';
import {goToEnterpriseProfilePage, goToEnterpriseSubscriberProfilePage} from '../../../../services/pageService';
import {getUser, mapAndSave, SUBSCRIBERS_KEY} from '../../../../services/storageService';
import DorePagination from "../../../shared/paging/DorePagination";
import {DEFAULT_PAGE_SIZE, ENTERPRISE_STATUSES} from "../../../../utils/consts";
import DoreFab from "../../../shared/DoreFab";
import DoreInputField from "../../../shared/DoreInputField";
import {openConfirmDialog} from "../../../../services/confirmDialogService";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {translate2} from "../../../../services/translateService";

/**
 * Enterprise EnterpriseSubscribers Component
 * TODO: all arrays (at least where possible) replace it with object {}
 *       Then pass the list just the UI obj with id and get the org server obj from the stored state obj by key O(1)
 *
 * @author Mohammad
 */
const EnterpriseSubscribers = ({ breadCrumb, loading }) => {

  // enterpriseId
  const { enterpriseId,enterpriseStatus } = getUser();

  // State
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [selectedPage,setSelectedPage] = useState(0);
  const [numOfPages,setNumOfPages] = useState(0)
  const [addIcon, setAddIcon] = useState(true);

  const onClick = (uiObj) => {
    const { subscriber } = uiObj;
    console.log(subscriber)
    goToEnterpriseSubscriberProfilePage(subscriber.userInfo.id || subscriber.userInfo.phoneNumber );
  };

  useEffect(()=>{
      if (!enterpriseId || enterpriseStatus === ENTERPRISE_STATUSES.NEW)
      {
          goToEnterpriseProfilePage()
      }
  },[])
    
  useEffect(() => {
      enterpriseId && enterpriseStatus != ENTERPRISE_STATUSES.NEW &&  (async () => {
      const res = await getPagedEnterpriseSubscribers(enterpriseId,selectedPage,DEFAULT_PAGE_SIZE);
      setNumOfPages(res.totalPageNumber ? res.totalPageNumber : 0);
      let subscribers = res.pageBody ? res.pageBody : res;
      handleSubscribersResponse(subscribers);
    })();

    // clean-up
    return function cleanup() {
    };
  }, [selectedPage]);

  const handleSubscribersResponse = (subscribers) =>
  {
    if (subscribers) {
      setSubscribers(subscribers.map(subscriber => ({
          subscriber,
        id: subscriber.userInfo.id,
        title: subscriber.userInfo.name || subscriber.userInfo.phoneNumber,
        avatar: true,
        subTitle: subscriber.userInfo.id ? subscriber.userInfo.address && subscriber.userInfo.address.city : "زبون غير مؤكد",
      })));
      // Save to storage
      mapAndSave(SUBSCRIBERS_KEY, subscribers);
    }
  }

  const handleSearch = async (searchText) => {
        const res = searchText ? await searchSubscribers(enterpriseId,searchText) :  await getPagedEnterpriseSubscribers(enterpriseId,selectedPage,DEFAULT_PAGE_SIZE);
        let subscribers = res.pageBody ? res.pageBody : res;
        setNumOfPages(res.totalPageNumber ? res.totalPageNumber : 0);
        handleSubscribersResponse(subscribers);
    //refreshPage()
  }

  const handlePageChange = (event,value) => {
    setSelectedPage(value-1);
  }


  let newSubscriberPhoneNumber = '';
  const NewSubscriber = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    return (
        <DoreInputField
            bottomSpace={false}
            loading={{}}
            label={translate2("phoneNumber")}
            icon="local_phone"
            value={phoneNumber}
            onChange={e => {
              const tn = e.target.value;
              setPhoneNumber(tn);
              newSubscriberPhoneNumber = tn;
            }}
        />
    );
  };

  const addSubscriberAction = () => {
    let titleInfo = translate2("newCustomer")
    let subTitleInfo =  ''
    openConfirmDialog(faUserPlus, titleInfo,subTitleInfo,
        <NewSubscriber  />,
        () => {
          callAddSubscriber().then(console.log("added Subscriber"))
        });
  };

  const callAddSubscriber = async () => {
      const subscriberInfo = {
          "userInfo": {
              "phoneNumber": newSubscriberPhoneNumber
          },
          "customersNames": [],
          "periodUnit": "DAY",
          "minPeriodBetweenBookings": 7,
          "numOfAllowedTurns": 1,
          "status": "ACTIVE",
          "subscriberAdditionalInfo": {}
      };
      let res;
      if (res = await addSubscriber(enterpriseId, [subscriberInfo])) {
          goToEnterpriseSubscriberProfilePage(newSubscriberPhoneNumber );
      }
  }

  const onAddSubscriberIconClick = async () => {
      addSubscriberAction()
  };

  return (
    <div>
      <DoreTopBar loading={loading} onSearch={(searchText)=>{handleSearch(searchText)}}/>
      {numOfPages>1  && <DorePagination numOfPages={numOfPages} handlePageChange={handlePageChange}/>}
      <DoreList list={subscribers} loading={loading} onClick={onClick} />
        {enterpriseId && <DoreFab
          color="primary"
          onClick={onAddSubscriberIconClick}
          inout={addIcon}
          icon="add"
          loading={loading}
          variant="extended"
          label={translate2("add")}
      />}
    </div>
  );
};

export default connect(state => ({
  breadCrumb: state.breadCrumb,
  loading: state.loading,
}))(EnterpriseSubscribers);
