import React, {useState} from "react";
import {connect} from "react-redux";
import DoreInputField from "../../../shared/DoreInputField";
import {translate2} from "../../../../services/translateService";

const AdditionalSubscriber = ({loading, onChangeName}) => {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    return (
        <DoreInputField
            bottomSpace={false}
            loading={{}}
            label={translate2("name")}
            icon="person"
            value={name}
            onChange={e => {
                const newName = e.target.value;
                setName(newName);
                onChangeName && onChangeName(newName);
            }}
        />
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(AdditionalSubscriber);
