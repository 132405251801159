import React from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Styles
 */
const useStyles = makeStyles({
  root: ({ center }) => ({
    marginLeft: center && 'auto',
    marginRight: center && 'auto',
  }),
});

export const DORE_SKELETON_VARIANT = Object.freeze({
  TEXT: 'text',
  RECT: 'rect',
  CIRCLE: 'circle'
});

export const DORE_SKELETON_ANIMATION = Object.freeze({
  PULSE: 'pulse',
  WAVE: 'wave',
  FALSE: 'false'
});

/**
 * Dore Skeleton Component
 */
const DoreSkeleton = ({
                        animation = DORE_SKELETON_ANIMATION.WAVE,
                        component = 'span',
                        width = '100%',
                        height = 25,
                        variant = DORE_SKELETON_VARIANT.TEXT,
                        center = true,
                      }) => {

  const classes = useStyles({ center });

  return (
    <Skeleton
      className={classes.root}
      animation={animation}
      component={component}
      width={width}
      height={height}
      variant={variant}
    />
  );
};

export default connect(state => ({
  loading: state.loading,
}))(DoreSkeleton);
