import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    addEnterpriseEmployees,
    deleteEnterpriseEmployeeById,
    getManagerEnterpriseEmployees,
    updateEnterpriseEmployeeById
} from "../../../../../services/dataService";
import DoreList from "../../../../shared/DoreList";
import {goToEnterpriseEmployeeProfilePage} from "../../../../../services/pageService";
import {getUser} from "../../../../../services/storageService";
import DoreFab from "../../../../shared/DoreFab";
import {Portal} from "@material-ui/core";
import {closeConfirmDialog, openConfirmDialog} from "../../../../../services/confirmDialogService";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import NewEmployee from "./AddOrUpdateNewEmployeeForm";
import DoreTopBar from "../../../../shared/DoreTopBar";
import {ENTERPRISE_STATUSES} from "../../../../../utils/consts";
import {translate2} from "../../../../../services/translateService";


export const EmployeeInfoComponent = ({
                                          enterpriseId,
                                          employeeId,
                                          employeeDisplayName,
                                          employeeTN,
                                          employeeRoleType,
                                          onConfirm
                                      }) => {
    return <NewEmployee enterpriseId={enterpriseId}
                        isEdit={employeeId != null && typeof employeeId != 'undefined'}
                        inputName={employeeDisplayName} onChangeName={(name) => employeeDisplayName = name}
                        inputTN={employeeTN} onChangeTN={(tn) => employeeTN = tn}
                        inputRole={employeeRoleType} onChangeRole={(role) => employeeRoleType = role}
                        onConfirm={async () => {
                            let body = [{
                                "phoneNumber": employeeTN,
                                "role": employeeRoleType,
                                "displayName": employeeDisplayName
                            }]
                            employeeId
                                ? updateEnterpriseEmployeeById(enterpriseId, employeeId, body[0]).then(() => {
                                    onConfirm && onConfirm();
                                })
                                : addEnterpriseEmployees(enterpriseId, body).then(() => {
                                    onConfirm && onConfirm();
                                })
                        }}
                        onCancel={() => {
                            onConfirm && onConfirm();
                        }}
    />
};

const EnterpriseEmployeesProfile = ({loading, isToShowTopBar = true, onChangeEmployees}) => {

    const {enterpriseId, enterpriseStatus} = getUser();
    const [employees, setEmployees] = useState([]);
    const [addIcon, setAddIcon] = useState(true);


    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        (async () => {
            if (enterpriseId) {
                const employeesArr = await getManagerEnterpriseEmployees(enterpriseId);
                if (employeesArr) {
                    setEmployees(employeesArr.map((emp) => ({
                        tn: emp.phoneNumber,
                        id: emp.id,
                        title: emp.displayName,
                        avatar: true,
                        subTitle: translate2('employeeType.' + emp.role),
                        role: emp.role
                    })));
                }
            }
        })();
    }, [refresh]);

    useEffect(() => {
        onChangeEmployees && onChangeEmployees(employees);
    }, [employees])

    let deletePressed = false;
    const onEmployeeClick = (employee) => {
        if (deletePressed) {
            openConfirmDialog(
                faTrash,
                translate2("areYouSureYouWantToDeleteEmployee") + ' "' + employee.title + '" ?',
                null,
                <div></div>,
                () => {
                    deleteEnterpriseEmployeeById(enterpriseId, employee.id).then(() => {
                            deletePressed = false;
                            refreshPage();
                        }
                    )
                },
                () => {
                    deletePressed = false;
                })
        } else
          //  if (enterpriseStatus === ENTERPRISE_STATUSES.NEW)
            {
            setAddIcon(false)
            onAddOrUpdateEmployeeClick(enterpriseId, refreshPage, onCancel, employee)
        }
        // else {
        //     goToEnterpriseEmployeeProfilePage(employee.id)
        // }
    }

    const refreshPage = () => {
        setRefresh(prevState => !prevState)
        setAddIcon(true)
    }

    const onCancel = () => {
        setAddIcon(true)
    }

    return (
        <div>
            {isToShowTopBar && <DoreTopBar loading={loading}/>}
            <DoreList
                list={employees}
                loading={loading}
                onClick={onEmployeeClick}
                onDelete={() => {
                    deletePressed = true;
                }}
            />
            {/*<FullWorkingHours*/}
            {/*    employee={employees[0]}*/}
            {/*    loading={loading}*/}
            {/*    isToShowTopBar={false}*/}
            {/*/>*/}
            <Portal container={document.body}>
                {
                    <DoreFab
                        color="primary"
                        onClick={() => {
                            setAddIcon(false)
                            onAddOrUpdateEmployeeClick(enterpriseId, refreshPage, onCancel, null)
                        }}
                        inout={addIcon}
                        icon="add"
                        loading={true}
                        variant="extended"
                        label={translate2("addNewEmployee")}
                    />
                }
            </Portal>

        </div>
    )
};

export const onAddOrUpdateEmployeeClick = (enterpriseId, onConfirm, onCancel, employee) => {
    let newEmployeeTN = employee && employee.tn || '';
    let newEmployeeRoleType = employee && employee.role || "ADMIN";
    let newEmployeeDisplayName = employee && employee.title || "";
    let employeeId = employee && employee.id;

    let titleInfo = employeeId ? translate2("editEmployeeInfo") : translate2("addNewEmployee")

    openConfirmDialog(null,
        titleInfo,
        null,
        <EmployeeInfoComponent enterpriseId={enterpriseId}
                               employeeId={employeeId}
                               employeeDisplayName={newEmployeeDisplayName}
                               employeeTN={newEmployeeTN}
                               employeeRoleType={newEmployeeRoleType}
                               onConfirm={() => {
                                   onConfirm();
                                   closeConfirmDialog()
                               }}/>,
        null,
        onCancel,
        true, true);
}


export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseEmployeesProfile);
