import React from 'react';
import {render} from 'react-dom';
import './index.scss';
import App from './App';
import {Provider} from 'react-redux';
import store from './store/store';

render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// TODO: ===>
// Warning: Can't perform a React state update on an unmounted component.
// This is a no-op, but it indicates a memory leak in your application.
// To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
// in AllEnterprises (created by Context.Consumer)
// TODO: ===>
