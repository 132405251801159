import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {green, red} from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {translate2} from "../../../../../services/translateService";
import Link from "@material-ui/core/Link";
import {openConfirmDialog} from "../../../../../services/confirmDialogService";
import {faTable} from "@fortawesome/free-solid-svg-icons";

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
        fontSize: "x-large"
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TermsOfUse = ({
                        loading,
                        setTermsAccepted,
                    }) => {


    const [state, setState] = React.useState({
        terms: false,
        privacy: false,
    });
    const [open, setOpen] = React.useState(false);


    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    useEffect(() => {
        setTermsAccepted(state.terms && state.privacy)
    }, [state])

    const openTermsOfUse = () => {
        let titleInfo = translate2("termsOfUse") + ":"
        openConfirmDialog(faTable, titleInfo, null
            ,
            <div>
                {[...new Array(50)]
                    .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                    )
                    .join('\n')}
            </div>
            ,
            null,
            null, false, true
        );
    };

    const openPrivacyTerms = () => {
        let titleInfo = translate2("privacyTerms") + ":"
        openConfirmDialog(faTable, titleInfo, null
            ,
            <div>
                {[...new Array(50)]
                    .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                    )
                    .join('\n')}
            </div>
            ,
            null,
            null, false, true
        );
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    const handleClickOpen = (agreementContentLocation) => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <p style={{marginRight: '12px'}}>
                {translate2("termsPrefix")}
            </p>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <FormGroup column={"true"}>
                    <FormControlLabel
                        control={
                            <GreenCheckbox checked={state.terms}
                                           onChange={handleChange}
                                           name="terms"
                            />}

                        label={
                            <div>
                                <label> {translate2("iApprove")} </label>
                                <Link href="#" onClick={openTermsOfUse}>
                                    {translate2("termsOfUse")}
                                </Link>
                            </div>
                        }
                    />
                    <FormControlLabel
                        control={
                            <GreenCheckbox checked={state.privacy}
                                           onChange={handleChange}
                                           name="privacy"
                            />}
                        label={
                            <div>
                                <label> {translate2("iApprove")} </label>
                                <Link href="#" onClick={openPrivacyTerms}>
                                    {translate2("privacyTerms")}
                                </Link>
                            </div>}
                    />

                    <FormControlLabel
                        control={
                            <GreenCheckbox checked={true}
                                           onChange={handleChange}
                                           name="sale"
                                           disabled={true}

                            />}
                        label={
                            <div>
                                <label> {translate2("saleInfo")} </label>
                            </div>}
                    />
                </FormGroup>
            </div>


        </div>

    )


};


export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(TermsOfUse);



