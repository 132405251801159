import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(2),
    cursor: 'pointer',
  },
  media: {
    height: 190,
  },
}));

function DoreMediaCard({ loading = false, logo, title, subTitle, image, activeUser = true, onClick, children }) {
  const classes = useStyles();
  const [raised, setRaised] = useState(false);
  return (
    <Card
      className={classes.card}
      raised={raised}
      onClick={() => onClick && onClick()}
      onMouseDown={() => setRaised(false)}
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
    >
      <CardHeader
        avatar={
          loading ? (
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          ) : (
            <Avatar
              alt="Enterprise logo"
              src={logo}
            />
          )
        }
        // action={
        //   loading ? null : (
        //     <IconButton aria-label="settings">
        //       <Icon>more_vert_icon</Icon>
        //     </IconButton>
        //   )
        // }
        title={
          loading ? (
            <Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />
          ) : (
            title
          )
        }
        subheader={loading ? <Skeleton animation="wave" height={10} width="80%" /> : subTitle}
      />
      {loading ? (
        <Skeleton animation="wave" variant="rect" className={classes.media} />
      ) : (
        <CardMedia
          className={classes.media}
          image={image}
          children={children}
          title={title}
        />
      )}

      <CardContent>
        {loading ? (
          <React.Fragment>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </React.Fragment>
        ) : (
          <Typography variant="body2" color="textSecondary" component="p">
            {/*{activeUser ? 'دخول' : 'اشترك الان'}*/}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default DoreMediaCard;
