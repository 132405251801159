import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import DoreInputField from "../../../shared/DoreInputField";
import DoreChip from "../../../shared/DoreChip";
import {getUser, saveUser} from "../../../../services/storageService";
import {translate2} from "../../../../services/translateService";
import Alert from "@material-ui/lab/Alert";
import {
    ENTERPRISE_CATEGORIES,
    ENTERPRISE_PRIVACY_TYPES,
    ENTERPRISE_STATUS_TYPES,
    FIXED_TZ
} from "../../../../utils/consts";
import {createEnterprise, updateManagerEnterpriseInfo} from "../../../../services/dataService";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {makeStyles} from "@material-ui/core/styles";
import {INPUT_HEIGHT, INPUT_PADDING_TOP, SECOND_COLOR_A} from "../../../../styles";
import LabelledOutline from "../../../shared/utils/LabelledOutline";

const useStyles = makeStyles({
    root: ({isBtn, bottomSpace}) => ({
        marginBottom: bottomSpace && '1rem',
        '& .MuiInputBase-input': {
            textAlign: 'center',
            color: SECOND_COLOR_A,
            height: INPUT_HEIGHT,
            boxSizing: 'border-box',
            paddingTop: INPUT_PADDING_TOP,
            cursor: isBtn && 'pointer',
        },
    }),
    inputFieldSkeleton: {
        paddingTop: INPUT_PADDING_TOP,
        height: INPUT_HEIGHT,
        boxSizing: 'border-box',
        borderRadius: '0.35rem',
    }
});

export const createOrUpdateNewEnterprise = (enterprise, onSuccess) => {


    let body = {
        "name": enterprise.name,
        "description": enterprise.description,
        "categoryType": ENTERPRISE_CATEGORIES.BARBER,
        "address": enterprise.address,
        "phoneNumber": enterprise.phoneNumber,
        "email": enterprise.email,
        "timeZone": FIXED_TZ.JRS,
        "privacy": enterprise.privacy
    }
    enterprise && enterprise.id
        ? updateManagerEnterpriseInfo(enterprise.id, body).then(r => {
            onSuccess && onSuccess(r)
        })
        : createEnterprise(body).then(r => {
            let user = getUser();
            user.enterpriseId = r.enterpriseId;
            user.enterpriseStatus = ENTERPRISE_STATUS_TYPES.NEW.id
            saveUser(user)
            onSuccess && onSuccess(r)
        })

}


export const EnterprisePrivacyTypesComponent = ({inputPrivacyType, onChangePrivacyType}) => {

    const updatePrivacyType = (privacyType) => {
        setSelectedPrivacyType(privacyType);
        setPrivacyTypes(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            Object.keys(newState).forEach((key) => {
                newState[key].checked = false;
            });

            newState[privacyType].checked = true;
            return newState;
        })
    }

    function setEnterprisePrivacy(keyName) {
        updatePrivacyType(keyName || ENTERPRISE_PRIVACY_TYPES.SUBSCRIPTION.id)
        onChangePrivacyType && onChangePrivacyType(keyName || ENTERPRISE_PRIVACY_TYPES.SUBSCRIPTION.id)
    }

    useEffect(() => {
        setEnterprisePrivacy(inputPrivacyType);
    }, [inputPrivacyType]);

    const [selectedPrivacyType, setSelectedPrivacyType] = useState(inputPrivacyType);
    const [privacyTypes, setPrivacyTypes] = useState({
        "PUBLIC": {name: translate2("public"), description: translate2("publicDescription"), checked: false},
        "SUBSCRIPTION": {
            name: translate2("subscription"),
            description: translate2("subscriptionDescription"),
            checked: true
        },
        "PRIVATE": {name: translate2("private"), description: translate2("privateDescription"), checked: false},
    });

    return <LabelledOutline id="privacy" label={`${translate2("privacy")}:`}>
        <div
            style={{color: "gray"}}>
            {
                Object.keys(privacyTypes).map((keyName, index) => (
                    <DoreChip
                        key={keyName}
                        label={privacyTypes[keyName].name}
                        checked={privacyTypes[keyName].checked}
                        onClick={() => {
                            setEnterprisePrivacy(keyName);
                        }}
                    />
                ))
            }
            {privacyTypes[selectedPrivacyType] && <Alert style={{fontSize: "small"}}
                                                         severity={"info"}>{privacyTypes[selectedPrivacyType].description}</Alert>}
        </div>
    </LabelledOutline>
};

export const EnterpriseSettingsComponent = ({isActive, onChangeEnterpriseStatus, inputPrivacyType, onChangePrivacyType}) => {
    return <div>
        <EnterprisePrivacyTypesComponent inputPrivacyType={inputPrivacyType} onChangePrivacyType={onChangePrivacyType}/>
        <br/>
        <LabelledOutline id="status" label={`${translate2("status")}:`}>
            <div>
                <Typography component="div" style={{marginRight: "10px"}}>
                    <div style={{marginRight: "10px"}}>
                        <Grid component="label" container alignItems="center" spacing={0}>
                            <Grid item
                                  style={{color: !isActive ? 'black' : 'gray'}}> {translate2("enterpriseStatus.INACTIVE")}</Grid>
                            <Grid item>
                                <Switch checked={isActive}
                                        onChange={onChangeEnterpriseStatus && onChangeEnterpriseStatus}/>
                            </Grid>
                            <Grid item
                                  style={{color: isActive ? 'red' : "lightgray"}}>{translate2("enterpriseStatus.ACTIVE")}</Grid>
                        </Grid>
                    </div>
                </Typography>
            </div>
        </LabelledOutline>
    </div>

};

export const enterpriseInfoComponent = (newEnterprise, setNewEnterprise, loading, isToShowPrivacyTypes = true) => {
    return newEnterprise && <NewEnterpriseInfo loading={loading}
                                               isToShowPrivacyTypes={isToShowPrivacyTypes}
                                               style={{
                                                   position: 'absolute', left: '50%', top: '50%',
                                                   transform: 'translate(-50%, -50%)'
                                               }}
                                               inputName={newEnterprise.name}
                                               onChangeName={(name) => {
                                                   setNewEnterprise(prevState => {
                                                       let newState = JSON.parse(JSON.stringify(prevState));
                                                       newState.name = name;
                                                       return newState;
                                                   })
                                               }}
                                               inputDescription={newEnterprise.description}
                                               onChangeDescription={(dsc) => {
                                                   setNewEnterprise(prevState => {
                                                       let newState = JSON.parse(JSON.stringify(prevState));
                                                       newState.description = dsc;
                                                       return newState;
                                                   })
                                               }}
                                               inputPhoneNumber={newEnterprise.phoneNumber}
                                               onChangePhoneNumber={(tn) => {
                                                   setNewEnterprise(prevState => {
                                                       let newState = JSON.parse(JSON.stringify(prevState));
                                                       newState.phoneNumber = tn;
                                                       return newState;
                                                   })
                                               }}
                                               inputCity={newEnterprise.address && newEnterprise.address.city}
                                               onChangeCity={(city) => {
                                                   setNewEnterprise(prevState => {
                                                       let newState = JSON.parse(JSON.stringify(prevState));
                                                       newState.address = {
                                                           city: city
                                                       }
                                                       return newState;
                                                   })
                                               }}
                                               inputEmail={newEnterprise.email}
                                               onChangeEmail={(email) => {
                                                   setNewEnterprise(prevState => {
                                                       let newState = JSON.parse(JSON.stringify(prevState));
                                                       newState.email = email;
                                                       return newState;
                                                   })
                                               }}
                                               inputPrivacyType={newEnterprise.privacy}
                                               onChangePrivacyType={(privacyType) => {
                                                   setNewEnterprise(prevState => {
                                                       let newState = JSON.parse(JSON.stringify(prevState));
                                                       newState.privacy = privacyType;
                                                       return newState;
                                                   })
                                               }}
    />;
}

const NewEnterpriseInfo = ({
                               loading,
                               inputName, onChangeName,
                               inputDescription, onChangeDescription,
                               inputPhoneNumber, onChangePhoneNumber,
                               inputCity, onChangeCity,
                               inputEmail, onChangeEmail,
                               inputPrivacyType, onChangePrivacyType,
                               isToShowPrivacyTypes
                           }) => {
    const {phoneNumber} = getUser();
    const [name, setName] = useState(inputName);
    const [description, setDescription] = useState(inputDescription);
    const [city, setCity] = useState(inputCity);
    const [email, setEmail] = useState(inputEmail || '');
    const [enterprisePhoneNumber, setEnterprisePhoneNumber] = useState(inputPhoneNumber)


    useEffect(() => {
        setEnterpriseName(inputName);
    }, [inputName]);
    useEffect(() => {
        setEnterpriseDesc(inputDescription);
    }, [inputDescription]);
    useEffect(() => {
        setEnterpriseEmail(inputEmail);
    }, [inputEmail]);
    useEffect(() => {
        setEnterpriseCity(inputCity);
    }, [inputCity]);
    useEffect(() => {
        setEnterprisePhone(inputPhoneNumber);
    }, [inputPhoneNumber]);


    function setEnterpriseName(v) {
        setName(v)
        onChangeName && onChangeName(v)
    }

    function setEnterpriseDesc(v) {
        setDescription(v)
        onChangeDescription && onChangeDescription(v)
    }

    function setEnterprisePhone(v) {
        setEnterprisePhoneNumber(v || phoneNumber)
        onChangePhoneNumber && onChangePhoneNumber(v || phoneNumber)
    }

    function setEnterpriseCity(v) {
        setCity(v)
        onChangeCity && onChangeCity(v)
    }

    function setEnterpriseEmail(v) {
        setEmail(v)
        onChangeEmail && onChangeEmail(v)
    }


    return (
        <div style={{marginRight: "10px"}}>
            <DoreInputField
                loading={loading}
                label={translate2("venueName")}
                icon="business"
                value={name}
                onChange={e => {
                    let v = e.target.value;
                    setEnterpriseName(v);
                }}
            />
            <DoreInputField
                loading={loading}
                label={translate2("venueDescription")}
                icon="info"
                value={description}
                onChange={e => {
                    let v = e.target.value;
                    setEnterpriseDesc(v);
                }}
            />
            <DoreInputField
                loading={loading}
                type="number"
                label={translate2("venuePhoneNumber")}
                icon="local_phone"
                value={enterprisePhoneNumber}
                onChange={e => {
                    let v = e.target.value;
                    setEnterprisePhone(v);
                }}
            />
            <DoreInputField
                loading={loading}
                label={translate2("city")}
                icon="location_city"
                value={city}
                onChange={e => {
                    let v = e.target.value;
                    setEnterpriseCity(v);
                }}
            />
            <DoreInputField
                loading={loading}
                type="email"
                label={translate2("email")}
                icon="email"
                value={email}
                onChange={e => {
                    let v = e.target.value;
                    setEnterpriseEmail(v);
                }}
            />
            {isToShowPrivacyTypes &&
            <EnterprisePrivacyTypesComponent inputPrivacyType={inputPrivacyType}
                                             onChangePrivacyType={onChangePrivacyType}/>}

        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewEnterpriseInfo);
