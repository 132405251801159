/**
 * Redux actions types
 * Any REDUX action type need to be defined here
 *
 * @author Mohammad Agbaryya
 */

export const SET_LOADING = 'SET_LOADING';
export const SET_BREAD_CRUMB = 'SET_BREAD_CRUMB';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
export const SET_DIALOG = 'SET_DIALOG';
export const SET_CONFIRM_DIALOG = 'SET_CONFIRM_DIALOG';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_IS_MENU_BAR_OPEN = 'SET_IS_MENU_BAR_OPEN';
export const SET_MENU_BAR_ITEMS = 'SET_MENU_BAR_ITEMS';
