import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

/**
 * Component styles
 */
const useStyles = makeStyles({
    root: {}
});

/**
 * DoreDialog
 */
const DoreDialog = ({dialog}) => {
    const classes = useStyles();
    return (
        <Dialog
            className={classes.root}
            onClose={dialog.onClose}
            open={dialog.open}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            fullScreen
        >
            {(dialog.closeBtn || dialog.filterBtn) &&
            <div style={{
                minHeight: '3rem',
                backgroundColor: dialog.headerColor,
            }}>

                {dialog.closeBtn &&
                <IconButton
                    onClick={dialog.onClose}
                    style={{
                        position: 'absolute', top: 0, right: 0,
                    }}
                >
                    <Icon style={{color: 'white'}}>close</Icon>
                </IconButton>
                }

                {dialog.filterBtn && < IconButton
                    onClick={dialog.onFilter}
                    style={{
                        position: 'absolute', top: 0, left: 0,
                    }}
                >
                    <FontAwesomeIcon className={classes.actionsIcon} style={{fontSize: '0.8rem', color: 'white'}}
                                     icon={faFilter}/>
                </IconButton>}
            </div>


            }


            {dialog.title && <DialogTitle>{dialog.title}</DialogTitle>}
            {dialog.children}
            {dialog.footer && <div>
                <span style={{float: 'left', marginRight: '1rem', fontWeight: 'bold'}}>موافق</span>
                <span onClick={dialog.onClose} style={{float: 'left'}}>الغاء</span>
            </div>}
        </Dialog>
    );
};

export default connect(state => ({
    dialog: state.dialog
}))(DoreDialog);
