import React, {useEffect, useState} from "react";
import {getManagerEnterpriseServicesTypes} from "../../../../../services/dataService";
import {getUser, mapAndSave, SERVICES_KEY} from "../../../../../services/storageService";
import {connect} from "react-redux";
import {SOME_COLORS} from "../../../../../utils/consts";
import {getUniqueColor} from "../../../../../utils/utils";
import WorkingHours from "./WorkingHours";
import {onChangeEmployeesEvents, onSaveEmployeesWorkingHours, onUpdateEvent} from "../AddOrUpdateNewEnterpriseForm";

const FullWorkingHours = ({
                              loading,
                              isToShowTopBar = false,
                              employee = null
                          }) => {

    const {enterpriseId} = getUser();
    const [employeesEvents, setEmployeesEvents] = useState({});
    const [services, setServices] = React.useState([]);

    useEffect(() => {
        (async () => {
            const servicesArr = await getManagerEnterpriseServicesTypes(enterpriseId);
            if (servicesArr) {
                setServices(servicesArr.map((service, idx) => {
                    service['backgroundColor'] = idx < SOME_COLORS.length ? SOME_COLORS[idx] : getUniqueColor();
                    return service
                }));
                mapAndSave(SERVICES_KEY, servicesArr)
            }
        })();
    }, []);


    return <div>

        {services && <WorkingHours
            inputEmployee={employee}
            inputServices={services}
            isOneWeekCalendar={false}
            loading={loading}
            isToShowTopBar={isToShowTopBar}
            onUpdateEvent={(empId, calendar) => {
                onUpdateEvent(empId, calendar, setEmployeesEvents)
            }}
            onChangeEmployeesEvents={(oldSelectedEmpId, newSelectedEmpId, calendar) => {
                calendar.removeAllEvents();
               // onChangeEmployeesEvents(oldSelectedEmpId, newSelectedEmpId, calendar, setEmployeesEvents)
            }}
            onSave={() => {
                onSaveEmployeesWorkingHours(employeesEvents, services, enterpriseId)
            }}
        />}
    </div>
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(FullWorkingHours);