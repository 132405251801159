import {getService} from "../../../../../services/storageService";
import {DAYS_TO_NUMBERS} from "../../../../../utils/consts";
import {addHHMMToDate, nextDayDate, toYYYYMMDDTHHmmssFormat} from "../../../../../utils/utils";
import {createEventId} from "../serviceProvider/event-utils";

export const mapServiceProviderWHToCalendarEvents = (workingHours, calendar, startDate, serviceTypeId, serviceType) => {
    if (workingHours && calendar) {
        Object.keys(workingHours).map((day) => {
                let title = getService(serviceTypeId) ? getService(serviceTypeId).name : 'WH';
                let mappedDaySlots = null;
                let slots = workingHours[day];
                let dayNumber = DAYS_TO_NUMBERS[day];
                // let date = addDays(startDate, dayNumber);
                let date = nextDayDate(startDate, dayNumber);
                if (slots) {
                    slots.map((slot) => {
                        let startDateTime = toYYYYMMDDTHHmmssFormat(addHHMMToDate(date, slot.startTime));
                        let endDateTime = toYYYYMMDDTHHmmssFormat(addHHMMToDate(date, slot.endTime));
                        let mappedSlot = {
                            id: createEventId(),
                            title: title,
                            start: startDateTime,
                            end: endDateTime,
                            allDay: false,
                            serviceTypeId: serviceTypeId,
                            backgroundColor: serviceType && serviceType.backgroundColor
                        };
                        calendar.addEvent(mappedSlot);
                    })
                }
                return mappedDaySlots;
            }
        )
    }
}