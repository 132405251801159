import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Icon} from '@material-ui/core';
import {MAIN_COLOR_C} from '../../styles';

/**
 * TabPanel component helper
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

/**
 * a11yProps component helper
 */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

/**
 * Styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    padding: '6px 0'
  },
}));

/**
 * Dore generic tabs component - any other component need tabs should use this component
 *
 * @author Mohammad Agbaryya
 */
const DoreTabs = ({ tabs = [], indicatorColor = 'primary', textColor = 'primary', loading, initSelectedTab = 0 , onTabChange}) => {

  // Theme and styles
  const theme = useTheme();
  const classes = useStyles();

  // State
  const [value, setValue] = React.useState(initSelectedTab);

  // Actions
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange && onTabChange(newValue)
  };
  const handleChangeIndex = index => {
    setValue(index);
    onTabChange && onTabChange(index)

  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor={indicatorColor}
          textColor={textColor}
          variant="fullWidth"
          aria-label="doretou tabs core component"
        >
          {tabs && tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              disabled={tab.disabled}
              icon={<Icon style={{ color: MAIN_COLOR_C }}>{tab.icon}</Icon>}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x' : 'x-reverse'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabs && tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index} dir={theme.direction}>
            {tab.children}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};

export default DoreTabs;
