import React, {useEffect, useState} from 'react';
import TopBar from '../shared/DoreTopBar';
import {connect} from 'react-redux';
import {getUserInfo, updateUserInfo} from '../../services/dataService';
import {translate, translate2} from '../../services/translateService';
import {goToHomePage} from '../../services/pageService';
import {alertSuccess} from '../../services/snackbarService';
import DoreInputField from '../shared/DoreInputField';
import DoreDatePicker from '../shared/DoreDatePicker';
import DoreExpansionPanel from '../shared/DoreExpansionPanel';
import DoreFab from '../shared/DoreFab';

const Profile = ({ loading, currentLanguage }) => {

  const {
    pageTranslation: {
      phoneNumberLabel,
      cityLabel,
    }
  } = translate('Profile', currentLanguage);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [birthDate, setBirthDate] = useState(null);

  useEffect(() => {
    // Get Info From Server
    (async () => {
      const res = await getUserInfo();
      if (res && res.userInfo) {
        const { name, birthDate, phoneNumber, address } = res.userInfo;
        setName(name);
        setBirthDate(birthDate);
        setPhoneNumber(phoneNumber);
        setCity(address && address.city);
      }
    })();
  }, [currentLanguage]);


  const save = async () => {
    await updateUserInfo(name, birthDate, city);
    alertSuccess('تم الحفظ بنجاح');
    // TODO: need to clear timer, also in all other components need to clear component when deprecated
    // TODO: Also add to all components hooks to handle component life cycle, to cleanup every thing fot performance
    setTimeout(() => {
      goToHomePage();
      alertSuccess('moved to the main page');
    }, 1500);
  };

  return (
    <div className="profile-scope">
      <TopBar loading={loading} />
      <DoreExpansionPanel
        title={translate2("personalDetails")}
        initialExpanded={true}
        icon="perm_contact_calendar"
      >
        <div>
          <DoreInputField
            loading={loading}
            label={translate2("name")}
            icon="person"
            value={name || ''}
            onChange={e => setName(e.target.value)}
          />
          <DoreInputField
            loading={loading}
            disabled
            type="number"
            label={phoneNumberLabel}
            icon="local_phone"
            value={phoneNumber || ''}
          />
          <DoreInputField
            loading={loading}
            label={cityLabel}
            icon="location_city"
            value={city || ''}
            onChange={e => setCity(e.target.value)}
          />
          <DoreDatePicker
            loading={loading}
            label={translate2("birthDate")}
            okLabel={translate2("ok")}
            cancelLabel={translate2("cancel")}
            icon="today"
            value={birthDate}
            onChange={date => setBirthDate(date)}
          />
        </div>
      </DoreExpansionPanel>
      <DoreFab
        onClick={save}
        inout={true}
        icon="save"
        loading={loading}
        disabled={!name || !city || !birthDate}
      />
    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
  currentLanguage: state.currentLanguage,
}))(Profile);
