import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DoreCheckBox from "./DoreCheckBox";
import {SECOND_COLOR_A} from "../../../styles";
import LabelledOutline from "../utils/LabelledOutline";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
}));


const DoreGroupCheckBox = (props) => {
    const classes = useStyles();
    let dataArr = props.dataArr;
    let icon = props.icon;
    let iconColor = SECOND_COLOR_A;

    return (
        <>
            <FormControl component="fieldset" className={classes.formControl}>
                {/*<FormLabel component="legend">*/}
                {/*    { // TODO: TEMP CODE: Need to be handled from DoreIcon.js component, need to use it as generic icon!!*/}
                {/*        // Also need to create core shred Typography generic component for text used*/}
                {/*        typeof icon === 'string' ?*/}
                {/*            <Icon style={{color: iconColor}}>{icon}</Icon> :*/}
                {/*            <FontAwesomeIcon style={{color: iconColor}} icon={icon}/>*/}
                {/*    }*/}
                {/*    {props.formLabel}*/}
                {/*</FormLabel>*/}
                <LabelledOutline id="serviceProvider" label={props.formLabel} icon={props.icon}>
                    <FormGroup>
                        <div>
                            {dataArr && dataArr.map((data, index) => (
                                <div key={index}>
                                    <DoreCheckBox data={data} onClick={props.onClick} index={index}/>
                                    <br/>
                                </div>
                            ))}
                        </div>
                    </FormGroup>
                </LabelledOutline>
                {/*<FormHelperText>{props.formHelperText}</FormHelperText>*/}
            </FormControl>
        </>
    );
}


export default DoreGroupCheckBox;