import React from 'react';
import {connect} from 'react-redux';
import DoreIcon from './DoreIcon';
import DoreForm from './DoreForm';

/**
 *
 */
const DoreEmptyPage = ({loading, title = 'الصفحة فارغة!!', subTitle = '', vhToSub = '120px'}) => {

    // The view
    return (
        <DoreForm
            loading={loading}
            title={title}
            subTitle={subTitle}
            spacing={0}
            vhToSub={vhToSub}
            logo={<DoreIcon loading={loading}>desktop_access_disabled</DoreIcon>}/>
    );
};

export default connect(state => ({
    loading: state.loading,
}))(DoreEmptyPage);
