import React from 'react';
import {default as SnackBar} from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import {connect} from 'react-redux';
import {setSnackbar} from '../../store/actions';

const DoreSnackbar = ({ dispatch, snackbar }) => {
  return (
    <div className="snakbar-scope">
      <SnackBar
        open={snackbar.open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => dispatch(setSnackbar({ ...snackbar, open: false }))}
      >
        <Alert severity={snackbar.severity}>
          {snackbar.msg}
        </Alert>
      </SnackBar>
    </div>
  );
}

export default connect(state => ({
  snackbar: state.snackbar
}))(DoreSnackbar);
