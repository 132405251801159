import {USER_TYPE} from '../utils/consts';

// TODO: Need to compose/wrapper the server calls, to be sure all the get,update,etc.. calls exit from one point so can handling stable caching...

// *** Storage  to use ***
const storage = sessionStorage;

// *** Storage Keys ***
export const USER_KEY = 'user';
export const AUTH_KEY = 'auth';
export const SUBSCRIBERS_KEY = 'subscribers';
export const ENTERPRISES_KEY = 'enterprises';
export const SERVICES_KEY = 'services';
export const PROVIDERS_KEY = 'providers';


// *** Objects Creators ***
export const createUser = (type = USER_TYPE.CONSUMER,
                           enterpriseId = '',
                           enterpriseStatus = '',
                           phoneNumber = '',
                           userLocalName = 'There!') => ({
    type,
    enterpriseId,
    enterpriseStatus,
    phoneNumber,
    userLocalName
});

// *** Storage generic actions ***
const $save = (key, item) => storage.setItem(key, JSON.stringify(item));
const $get = (key) => JSON.parse(storage.getItem(key));

// *** Clear Storage ***
// TODO: fot now delete it when signOut, because it a session storage, at the future maybe wee need to save it more time!
export const clearStorage = () => storage.clear();

// *** User Actions ***
export const saveUser = (user) => $save(USER_KEY, user);
export const getUser = () => $get(USER_KEY);

// *** Auth Actions ***
export const saveAuth = (userId, auth) => $save(AUTH_KEY, {userId, auth});
export const getAuth = () => $get(AUTH_KEY);
export const removeAuth = () => storage.removeItem(AUTH_KEY);
export const isAuth = () => getAuth() && getAuth().userId && getAuth().auth;

// *** Consumers Actions ***
export const saveConsumers = (consumers) => mapAndSave(SUBSCRIBERS_KEY, consumers);
export const getConsumers = () => $get(SUBSCRIBERS_KEY);
export const getConsumer = (consumerId) => getConsumers() && getConsumers()[consumerId];

// *** Enterprises Actions ***
export const saveEnterprises = (enterprises) => mapAndSave(ENTERPRISES_KEY, enterprises);
export const getEnterprises = () => $get(ENTERPRISES_KEY);
export const getEnterprise = (enterpriseId) => getEnterprises() && getEnterprises()[enterpriseId];
export const isEnterprises = () => getEnterprises() && true;

// *** Services Actions ***
export const saveServices = (services) => mapAndSave(SERVICES_KEY, services);
export const getServices = () => $get(SERVICES_KEY);
export const getService = (serviceId) => getServices() && getServices()[serviceId];

// *** Providers Actions ***
export const saveProviders = (providers) => mapAndSave(PROVIDERS_KEY, providers);
export const getProviders = () => $get(PROVIDERS_KEY);
export const getProvider = (providerId) => getProviders() && getProviders()[providerId];

// helper's
export const mapAndSave = (key, list) => {
    const map = {};
    list.map(item => {
        let id = key === SUBSCRIBERS_KEY ? item.userInfo.id : item.id;
        if(id) map[id] = item;
    });
    $save(key, map);
    return {...map};
};
