import React, {useEffect, useState} from 'react';
import {cancelBookings, getBookings, getEnterprises} from '../../services/dataService';
import {connect} from 'react-redux';
import {changeDateFormat, changeTimeFormat, checkByIndex, getSelected, switchIcon, uncheckAll} from '../../utils/utils';
import DoreList from '../shared/DoreList';
import DoreFab from '../shared/DoreFab';
import {unCheckedIcon} from '../../utils/consts';
import {ERROR_COLOR_C} from '../../styles';
import DoreTopBar from '../shared/DoreTopBar';
import {alertSuccess} from '../../services/snackbarService';
import DoreEmptyPage from '../shared/DoreEmptyPage';
import FilterMenu from "../shared/FilterMenu";
import DoreExpansionPanel from "../shared/DoreExpansionPanel";
import DoreChip from "../shared/DoreChip";
import {translate2} from "../../services/translateService";

// TODO: maybe change the delete implantation with checkbox and {} and shared delete button as the save slot solution. also make the code in utils, etc...
const Bookings = ({loading, breadCrumb}) => {

    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [enterprises, setEnterprises] = useState(null);
    const [isAllEnterpriseSelected, setAllEnterpriseSelected] = useState(true);

    const [bookings, setBookings] = useState([]);
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [deleteIcon, setDeleteIcon] = useState(false);

    useEffect(() => {
        (async () => {
            const e = await getEnterprises("SUBSCRIPTION");
            if (e && e[0]) {
                // e[0].checked = true;
                setEnterprises(e);
            }
        })();
    }, []);

    useEffect(() => {
        if (selectedBookings.length > 0) setDeleteIcon(true);
        else setDeleteIcon(false);
    }, [selectedBookings]);

    const onClick = async (book, index) => {
        switchIcon(bookings, setBookings, selectedBookings, setSelectedBookings, book.icon, book.id, index);
    };

    const onDelete = async () => {
        const booksToCancel = selectedBookings.map(item => item.data);
        if (await cancelBookings(booksToCancel)) {
            setSelectedBookings([]);
            const newBooks = bookings.filter(({id}) => !selectedBookings.some(({data}) => data === id));
            setBookings(newBooks);
            alertSuccess('Delete Succeeded');
        }
    };

    useEffect(() => {
        if (enterprises) {
            (async () => {
                const enterpriseId = isAllEnterpriseSelected ? null : getSelected(enterprises).id;
                const bookings = await getBookings(enterpriseId);
                if (bookings) {
                    if (bookings.length === 0) {
                        setBookings([])
                    } else {
                        setBookings(bookings.map(book => ({
                            id: book.id,
                            title: `${changeTimeFormat(book.startTime)} ${changeDateFormat(book.startTime)}`,
                            subTitle: `${book.serviceName} - ${book.enterpriseName}`,
                            icon: unCheckedIcon,
                        })))
                    }
                } else {
                    setBookings([])
                }
            })();
        }
    }, [enterprises]);


    const updateEnterprise = async (index) => {
        // selectByKey(enterprises, setEnterprises,index);
        setAllEnterpriseSelected(false)
        checkByIndex(index, enterprises, setEnterprises);
    };


    return (
        <div>
            <DoreTopBar
                onFilter={() => setOpenFilterMenu(true)}
                counter={selectedBookings.length > 0 && selectedBookings.length}
                counterIcon="delete"
                loading={loading}
            />

            <FilterMenu
                open={openFilterMenu}
                onClose={() => setOpenFilterMenu(false)}
            >

                <DoreExpansionPanel title={translate2("venue")} icon="business_center" initialExpanded={true}
                                    forceCancelLoading={true}>
                    <div style={{
                        paddingBottom: '1rem',
                    }}>
                        {
                            <DoreChip
                                key={"allEnterprises"}
                                label={translate2("allVenues")}
                                checked={isAllEnterpriseSelected}
                                onClick={() => {
                                    setAllEnterpriseSelected(true);
                                    uncheckAll(enterprises, setEnterprises);
                                }}
                            />
                        }
                        {enterprises && enterprises.length > 0 && enterprises.map((enterprise, index) => (
                            <DoreChip
                                key={enterprise.name}
                                label={enterprise.name}
                                checked={enterprise.checked}
                                onClick={() => updateEnterprise(index)}
                            />
                        ))}

                    </div>
                </DoreExpansionPanel>

            </FilterMenu>

            <DoreFab
                color="secondary"
                onClick={onDelete}
                inout={deleteIcon}
                icon="delete"
                loading={loading}
                variant="extended"
                label={translate2("delete")}
            />
            <DoreList list={bookings} onClick={onClick} loading={loading} selectedColor={ERROR_COLOR_C}/>
            {bookings.length === 0 && <DoreEmptyPage/>}
        </div>
    );
};

export default connect(state => ({
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(Bookings);
