import arabicTranslation from '../translations/ar';

const translator =  arabicTranslation;
export const translate = (param, currentLanguage) => {
    const $translate = translator;//TODO choose language mode

    return {
        sharedTranslation: $translate.shared,
        routerTranslation: $translate.router,
        pageTranslation: param && $translate.pages[param],
        errorTranslation: param && $translate.errors[param],
        errorConstantTranslation: param && $translate.errorsConstants[param],
    }
};

export const translate2 = (param, currentLanguage) => {
   // const translate = currentLanguage === HEBREW ? hebrewTranslation : arabicTranslation;
    return translator[param];
};
