import React, {useEffect, useState} from 'react';
import DoreList from '../shared/DoreList';
import {getEnterpriseServiceProviders} from '../../services/dataService';
import {goToAvailableDatesPage} from '../../services/pageService';
import DoreTopBar from '../shared/DoreTopBar';
import {$bc_store} from '../../services/breadcrumbService';
import {connect} from 'react-redux';
import {mapAndSave, PROVIDERS_KEY} from '../../services/storageService';
import {translate2} from "../../services/translateService";

/**
 * Providers Component
 *
 * @author Mohammad
 */
const Providers = ({ match, breadCrumb, loading, asDialog }) => {

  // Params
  let { params: { enterpriseId, serviceId } } = match;
  enterpriseId = enterpriseId.split('_')[0];
  serviceId = serviceId.split('_')[0];
  // States
  const [providers, setProviders] = useState([]);

  // Helpers
  const onClick = (provider) => {
    goToAvailableDatesPage(enterpriseId, serviceId, provider.id);
  };
  useEffect(() => {
    (async () => {
      const providers = await getEnterpriseServiceProviders(enterpriseId, serviceId);
      providers && providers.length>0 && setProviders(providers.map(provider => {
        $bc_store[provider.id] = provider.name;
        return {
          id: provider.id,
          title: provider.name,
          subTitle: ` ${translate2("duration")}: ${provider.duration || 20} ${translate2("min")}`,
          avatar: true,
        }
      }));
      // Save to storage
      providers && providers.length>0 && mapAndSave(PROVIDERS_KEY, providers);
      if(providers && providers.length == 1){
        goToAvailableDatesPage(enterpriseId, serviceId, providers[0].id);
      }
    })();
  }, []);

  return (
    <div>
      {!asDialog && <DoreTopBar backBarBtn={true} loading={loading} />}
      <DoreList
        list={providers}
        onClick={(provider) => asDialog ? asDialog.onClick(provider) : onClick(provider)}
        loading={loading}
      />
    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
  breadCrumb: state.breadCrumb,
}))(Providers);

