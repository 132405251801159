import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import DoreInputField from "../../../../shared/DoreInputField";
import DoreChip from "../../../../shared/DoreChip";
import {employeeRoleTypes} from "../../../../../utils/consts";
import {translate2} from "../../../../../services/translateService";
import DoreConfirmButtons from "../../../../shared/utils/DoreConfirmButtons";
import Alert from "@material-ui/lab/Alert";
import {isValidPhoneNumber} from "../../../../../utils/utils";
import LabelledOutline from "../../../../shared/utils/LabelledOutline";


export const EmployeeDetailsComponent = ({
                                             loading,
                                             isEdit = false,
                                             inputTN, onChangeTN,
                                             inputName, onChangeName,
                                             inputRole, onChangeRole,
                                             onValidChange
                                         }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [selectedRole, setSelectedRole] = useState(inputRole || translate2(employeeRoleTypes.ADMIN.name));
    const [rolesTypes, setRolesTypes] = useState(employeeRoleTypes);
    const [valid, setValid] = useState(isValidPhoneNumber(inputTN || phoneNumber));

    const updateRole = (role) => {
        setSelectedRole(role);
        setRolesTypes(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            Object.keys(newState).forEach((key) => {
                newState[key].checked = false;
            });
            newState[role].checked = true;
            return newState;
        })
    }

    function updateEmployeeTN(tn) {
        setPhoneNumber(tn);
        onChangeTN && onChangeTN(tn)
    }

    function updateEmployeeDisplayName(name) {
        setDisplayName(name)
        onChangeName && onChangeName(name)
    }

    function updateEmployeeRoleType(keyName) {
        updateRole(keyName)
        onChangeRole && onChangeRole(keyName)
    }

    useEffect(() => {
        updateEmployeeTN(inputTN)
    }, [inputTN]);
    useEffect(() => {
        updateEmployeeDisplayName(inputName)
    }, [inputName]);
    useEffect(() => {
        inputRole && updateEmployeeRoleType(inputRole)
    }, [inputRole]);

    return <div>
        {
            !valid && phoneNumber && phoneNumber.length > 0 &&
            <div><Alert severity="warning"> {translate2("incorrectInputPhoneNumber")} </Alert> <br/></div>
        }
        <DoreInputField
            bottomSpace={true}
            loading={loading}
            disabled={isEdit}
            type="number"
            label={translate2("phoneNumber")}
            icon="local_phone"
            value={phoneNumber}
            onChange={e => {
                let tn = e.target.value;
                const isValidTN = isValidPhoneNumber(tn);
                setValid(isValidTN);
                onValidChange && onValidChange(isValidTN);
                updateEmployeeTN(tn);
            }}
        />
        <DoreInputField
            bottomSpace={false}
            loading={loading}
            label={translate2("name")}
            icon="person"
            value={displayName}
            onChange={e => {
                let name = e.target.value;
                updateEmployeeDisplayName(name);
            }}
        />
        <br/>
        <div>
            <LabelledOutline id="rank" label={`${translate2("rank")}`}>
                {
                    Object.keys(rolesTypes).map((keyName, index) => (
                        <DoreChip
                            key={keyName}
                            label={translate2(rolesTypes[keyName].name)}
                            checked={rolesTypes[keyName].checked}
                            onClick={() => {
                                updateEmployeeRoleType(keyName);
                            }}
                        />
                    ))
                }
            </LabelledOutline>
        </div>

    </div>
};

const NewEmployee = ({
                         loading,
                         isEdit = false,
                         inputName, onChangeName,
                         inputTN, onChangeTN,
                         inputRole, onChangeRole,
                         onConfirm, onCancel
                     }) => {

    const [valid, setValid] = useState(isValidPhoneNumber(inputTN));

    return (
        <div>
            <EmployeeDetailsComponent loading={loading}
                                      isEdit={isEdit}
                                      inputName={inputName} onChangeName={onChangeName}
                                      inputTN={inputTN} onChangeTN={onChangeTN}
                                      inputRole={inputRole} onChangeRole={onChangeRole}
                                      onValidChange={setValid}
            />
            <DoreConfirmButtons
                showCancel={true}
                showConfirm={true}
                showDelete={false}
                onCancel={onCancel}
                onConfirm={onConfirm}
                disableConfirm={!valid}
            />

        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewEmployee);
