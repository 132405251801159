import React, {useEffect, useState} from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {connect} from "react-redux";

import {Icon} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import {createOrUpdateNewEnterprise, enterpriseInfoComponent} from "./EditEnterpriseInfoForm";
import {isMobile} from 'react-device-detect';
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import EnterpriseEmployeesProfile from "./employee/EnterpriseEmployeesProfile";
import Typography from "@material-ui/core/Typography";
import EnterpriseServicesProfile from "./serviceType/EnterpriseServicesProfile";
import {activateManagerEnterprise, editMultiServiceProviderWorkingHours} from "../../../../services/dataService";
import {getUser, saveUser} from "../../../../services/storageService";
import {translate2} from "../../../../services/translateService";
import WorkingHours from "./workingHours/WorkingHours";
import {ENTERPRISE_STATUS_TYPES, NUMBERS_TO_DAYS} from "../../../../utils/consts";
import {changeTimeFormat, isValidPhoneNumber} from "../../../../utils/utils";
import TermsOfUse from "./agreement/TermsOfUse";


export const onUpdateEvent = (empId, calendar, setEmployeesEvents) => {
    if (empId && calendar) {
        setEmployeesEvents(prevState => {
            prevState[empId] = calendar.getEvents();
            return prevState;
        });
    }
}

export const onChangeEmployeesEvents = (oldSelectedEmpId, newSelectedEmpId, calendar, setEmployeesEvents) => {
    if (oldSelectedEmpId && newSelectedEmpId && calendar) {
        setEmployeesEvents(prevState => {
            let events = prevState && prevState[newSelectedEmpId];
            prevState[oldSelectedEmpId] = calendar.getEvents();
            calendar.removeAllEvents();
            if (events) {
                events.forEach(event => {
                    calendar.addEvent(event)
                })
            }
            return prevState;
        })
    }
}

export const onSaveEmployeesWorkingHours = (employeesEvents, services,enterpriseId) => {
    let whBody = [];
    Object.keys(employeesEvents).forEach((employeeId) => {
        let fcEvents = employeesEvents[employeeId]
        let newWorkingHours = {};
        services.forEach(service => {
            newWorkingHours[service.id] = {
                serviceProviderId: employeeId,
                serviceTypeId: service.id,
                serviceTypeVersionId: 0,
                map: {}
            }
        })
        fcEvents.map((event) => {
                let serviceTypeId = event.extendedProps.serviceTypeId;
                let day = NUMBERS_TO_DAYS[event.start.getDay()];
                let slot = {
                    startTime: changeTimeFormat(event.start),
                    endTime: changeTimeFormat(event.end)
                }
                if (!newWorkingHours[serviceTypeId]) {
                    newWorkingHours[serviceTypeId] = {
                        serviceProviderId: employeeId,
                        serviceTypeId: serviceTypeId,
                        serviceTypeVersionId: 0,
                        map: {}
                    }
                }
                if (!newWorkingHours[serviceTypeId]["map"][day]) {
                    newWorkingHours[serviceTypeId]["map"][day] = [slot]
                } else {
                    newWorkingHours[serviceTypeId]["map"][day].push(slot);
                }
            }
        );
        Object.keys(newWorkingHours).forEach(serviceTypeID => {
            whBody.push(newWorkingHours[serviceTypeID]);
        })

    })

    editMultiServiceProviderWorkingHours(enterpriseId, whBody)
        .then(r => {
            console.log("done updating WHs")
        });
}

const NewEnterprise = ({loading, enterprise, onActivate}) => {
    const [newEnterprise, setNewEnterprise] = useState(enterprise);
    const [employeesEvents, setEmployeesEvents] = useState({});
    const ADD_ENTERPRISE_INFO_STEP = 0;
    const ADD_EMPLOYEES_STEP = 1;
    const ADD_SERVICES_STEP = 2;
    const ADD_WORKING_HOURS_STEP = 3;
    const TERMS_STEP = 4;

    const icons = {};
    icons[ADD_ENTERPRISE_INFO_STEP + 1] = <Icon>home</Icon>;
    icons[ADD_EMPLOYEES_STEP + 1] = <Icon>person</Icon>;
    icons[ADD_SERVICES_STEP + 1] = <Icon>businessCenter</Icon>;
    //   icons[ADD_SERVICE_PROVIDERS_STEP + 1] = <Icon>assignment_ind</Icon>;
    icons[ADD_WORKING_HOURS_STEP + 1] = <Icon>schedule</Icon>;
    icons[TERMS_STEP + 1] = <Icon>assignment</Icon>;
    const maxSteps = 5;

    const getSteps = () => {
        return [translate2("pageDetails"),
            translate2("employees"),
            translate2("services"),
            //  translate2("serviceProviders"),
            translate2("workingHours"),
            translate2("terms")];
    }
    const steps = getSteps();
    const {enterpriseId} = getUser();

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        button: {
            marginLeft: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }));

    const ColorLibConnector = withStyles({
        alternativeLabel: {
            top: 22,
        },
        active: {
            '& $line': {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        completed: {
            '& $line': {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        line: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
        },
    })(StepConnector);

    const useColorLibStepIconStyles = makeStyles({
        root: {
            backgroundColor: '#ccc',
            zIndex: 1,
            color: '#fff',
            width: 50,
            height: 50,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
        completed: {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        },
    });

    function ColorLibStepIcon(props) {
        const classes = useColorLibStepIconStyles();
        const {active, completed} = props;
        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [isEmployeesAdded, setIsEmployeesAdded] = React.useState(false);
    const [isServicesAdded, setIsServicesAdded] = React.useState(false);
    const [services, setServices] = React.useState([]);
    const [isTermsAccepted, setTermsAccepted] = React.useState(false);
    const [nextEnabled, setNextEnabled] = React.useState(false);


    const theme = useTheme();
    const handleNext = () => {
        switch (activeStep) {
            case ADD_ENTERPRISE_INFO_STEP:
                createOrUpdateNewEnterprise(newEnterprise, (response) => {
                    setActiveStep(ADD_EMPLOYEES_STEP);
                });
                break;
            case ADD_WORKING_HOURS_STEP:
                onSaveEmployeesWorkingHours(employeesEvents, services,enterpriseId)
                setActiveStep(TERMS_STEP)
                break;
            case TERMS_STEP:
                activateManagerEnterprise(getUser().enterpriseId).then(r => {
                    let user = {};
                    user.enterpriseId = getUser().enterpriseId;
                    user.enterpriseStatus = ENTERPRISE_STATUS_TYPES.ACTIVE.id
                    saveUser(user)
                    onActivate && onActivate();
                });
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    useEffect(() => {
        if (activeStep === ADD_SERVICES_STEP) {
            setNextEnabled(isServicesAdded)
        }
    }, [activeStep, isServicesAdded])


    useEffect(() => {
        if (activeStep === ADD_EMPLOYEES_STEP) {
            setNextEnabled(isEmployeesAdded)
        }
    }, [activeStep, isEmployeesAdded])


    useEffect(() => {
        if (activeStep === ADD_ENTERPRISE_INFO_STEP) {
            let isAllPopulated = isAllEnterpriseInfoPopulated();

            setNextEnabled(isAllPopulated)
        }
    }, [activeStep, newEnterprise])

    useEffect(() => {
        if (activeStep === ADD_WORKING_HOURS_STEP) {
            setEmployeesEvents({})
        }
    }, [activeStep])

    useEffect(() => {
        if (activeStep === ADD_WORKING_HOURS_STEP) {
            setNextEnabled(true);
        }
    }, [employeesEvents])


    useEffect(() => {
        if (activeStep === TERMS_STEP) {
            setNextEnabled(isTermsAccepted)
        }
    }, [activeStep, isTermsAccepted])

    function isAllEnterpriseInfoPopulated() {
        const isAllPopulated = newEnterprise && newEnterprise.name && newEnterprise.description &&
            newEnterprise.address && newEnterprise.address.city && newEnterprise.email &&
            newEnterprise.privacy && newEnterprise.phoneNumber;
        const res = isAllPopulated && isValidPhoneNumber(newEnterprise.phoneNumber);
        return res;
    }


    return (
        <div>

            {!isMobile && <div>
                <br/>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                    </div>
                ) : (
                    <div>
                        <div>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                {translate2("prev")}
                            </Button>
                            <Button
                                disabled={!nextEnabled}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {activeStep === steps.length - 1 ? translate2("activate") : translate2("next")}
                            </Button>
                        </div>
                    </div>
                )}
            </div>}
            {!isMobile && <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector/>}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorLibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>}
            {isMobile && <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    !nextEnabled ||
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {translate2("next")}
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                        {translate2("prev")}
                    </Button>
                }
            />}
            <br/>
            {activeStep === ADD_ENTERPRISE_INFO_STEP && enterpriseInfoComponent(newEnterprise, setNewEnterprise, loading)}
            {activeStep === ADD_EMPLOYEES_STEP &&
            <EnterpriseEmployeesProfile
                isToShowTopBar={false}
                onChangeEmployees={(employees) => {
                    setIsEmployeesAdded(employees && employees.length > 0)
                }}/>
            }
            {activeStep === ADD_SERVICES_STEP &&
            <EnterpriseServicesProfile
                isFirstCreate={true}
                isToShowTopBar={false}
                onChangeServices={(services) => {
                    setServices(services)
                    setIsServicesAdded(services && services.length > 0)
                }}/>
            }
            {activeStep === ADD_WORKING_HOURS_STEP &&
            <WorkingHours
                isToShowTopBar={false}
                onUpdateEvent={(empId, calendar) => {
                    onUpdateEvent(empId, calendar, setEmployeesEvents)
                }}
                onChangeEmployeesEvents={(oldSelectedEmpId, newSelectedEmpId, calendar) => {
                    onChangeEmployeesEvents(oldSelectedEmpId, newSelectedEmpId, calendar, setEmployeesEvents)
                }}
                onSave={() => {
                    onSaveEmployeesWorkingHours(employeesEvents, services,enterpriseId)
                }}
            />
            }
            {activeStep === TERMS_STEP &&
            <TermsOfUse setTermsAccepted={setTermsAccepted}/>
            }
        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewEnterprise);
