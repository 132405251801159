import {
    ARABIC,
    checkedIcon,
    HEBREW,
    MANAGER_MENU,
    THEME_BASE_OPTIONS,
    unCheckedIcon,
    USER_MENU,
    USER_TYPE
} from './consts';
import store from '../store/store';
import {setCurrentLanguage, setLoading} from '../store/actions';
import {create} from 'jss';
import {jssPreset} from '@material-ui/styles';
import rtl from 'jss-rtl';
import {createMuiTheme} from '@material-ui/core';
import 'datejs';
import {getUser} from '../services/storageService';

export const clone = (state, {payload}, key) => ({...state, [key]: payload});

// TODO: When use select to change language, need to change this code
export const changeLanguage = (currentLanguage, dispatch) => {
    switch (currentLanguage) {
        case ARABIC: {
            dispatch(setCurrentLanguage(HEBREW));
            document.body.classList = ['hebrew-font'];
            Date.i18n.setLanguage("he-IL");
            break;
        }
        case HEBREW:
        default: {
            dispatch(setCurrentLanguage(ARABIC));
            document.body.classList = ['arabic-font'];
            Date.i18n.setLanguage("ar-TN");
            break;
        }
    }
};

// export const timeStringToSeconds = (hm) => {
//   const a = hm.split(':');
//   return (+a[0]) * 60 * 60 + (+a[1]) * 60;
// };
//
// export const sortArrayByProp = (arr, propName) => { //TODO: maybe delete, also change the name because it just for times!
//   arr.sort((a, b) => {
//     const v1 = timeStringToSeconds(a[propName]);
//     const v2 = timeStringToSeconds(b[propName]);
//     return v1 - v2;
//   });
// };

// export const getFormattedDate = (date) => {
//   return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes();
// };

export const JSS_RTL = create({plugins: [...jssPreset().plugins, rtl()]});
export const THEME = createMuiTheme(THEME_BASE_OPTIONS);

export const tConvert = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
        time = time.slice(1);
        if (+time[0] >= 3 && +time[0] <= 11) time[5] = ' صباحا';
        else if (+time[0] >= 12 && +time[0] <= 14) time[5] = ' ظهرا';
        else if (+time[0] >= 15 && +time[0] <= 17) time[5] = ' عصرا';
        else if (+time[0] >= 18 && +time[0] <= 20) time[5] = ' مساء';
        else if (+time[0] >= 21 && +time[0] <= 23) time[5] = ' ليلا';
        else time[5] = ' منتصف الليل';
        // time[5] = +time[0] < 12 ? ' صباحا' : ' مساء';
        time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
};

export const changeDateFormat = (date) => {
    return new Date(date).toString('dd/MM/yyyy');
};

export const changeDateTimeFormat = (dateTime) => {
    return new Date(dateTime).toString('dd/MM/yyyy HH:mm');
};

export const changeTimeFormat = (date) => {
    return new Date(date).toString('HH:mm');
};

export const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);

    // return new Date().getFullYear() - new Date(birthday).getFullYear();
};

export const switchIcon = (array, setArray, selectedArray, setSelectedArray, icon, data, index) => {
    const newArray = [...array];
    const newSelectedArray = [...selectedArray];
    if (icon === unCheckedIcon) {
        newArray[index].icon = checkedIcon;
        newArray[index].selected = true;
        newSelectedArray.push({data, index});
        setSelectedArray(newSelectedArray);
    } else {
        newArray[index].icon = unCheckedIcon;
        newArray[index].selected = false;
        const selectedIndex = newSelectedArray.findIndex(d => d === data);
        newSelectedArray.splice(selectedIndex, 1);
        setSelectedArray(newSelectedArray);
    }
    setArray(newArray);
};

export const startSpinnerLoading = () => store.dispatch(setLoading({spinner: true, skeleton: false}));
export const startSkeletonLoading = () => store.dispatch(setLoading({spinner: false, skeleton: true}));
export const stopLoading = () => store.dispatch(setLoading({spinner: false, skeleton: false}));

export const getMenuBarItems = (userType) => {
    const type = userType || (getUser() && getUser().type);
    return type === USER_TYPE.MANAGER ? MANAGER_MENU : USER_MENU;
};

// Check By Index
export const selectByKey = (state, setter, key) => {
    const copy = {...state};
    Object.keys(copy).map(k => copy[k].selected = false);
    if (copy[key]) {
        copy[key].selected = true;
        setter(copy);
    }
};

export const checkByKey = (key, state, setter) => {
    const copy = {...state};
    Object.keys(copy).map(k => copy[k].checked = false);
    if (copy[key]) {
        copy[key].checked = true;
        setter(copy);
        return key;
    }
};

export const checkByIndex = (index, state, setter) => { // TODO: remove and use utils one selectByIndex - should change checked to selected
    const copy = [...state];
    copy.map(c => c.checked = false);
    if (copy[index]) {
        copy[index].checked = true;
        setter && setter(copy);
    }
};


export const changeCheckByIndex = (index, state, setter) => { // TODO: remove and use utils one selectByIndex - should change checked to selected
    const copy = [...state];
    if (copy[index]) {
        copy[index].checked = !copy[index].checked;
        setter && setter(copy);
    }
};

export const uncheckAll = (state, setter) => { // TODO: remove and use utils one selectByIndex - should change checked to selected
    const copy = [...state];
    copy.map(c => c.checked = false);
    setter(copy);
};

export const getSelected = (arr) => arr && arr.length > 0 && arr.find(s => s.checked === true);

export const getDuration = (minutes) => {
    if (minutes) {
        let m = minutes % 60;
        let h = (minutes - m) / 60;
        let res = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
        return res;
    }
    return '00:30'
}


export const dateSimpleFormat = (date) => {
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate();

    return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join('-');
};

export const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

//HH:MM
export const addHHMMToDate = (date, hhmm) => {
    let hhmmArr = hhmm.split(":");
    let hours = parseInt(hhmmArr[0]);
    let mins = parseInt(hhmmArr[1]);
    let newDate = new Date(date);
    newDate.setHours(date.getHours() + hours, date.getMinutes() + mins);
    return newDate;
}

export const resetHHMMToDate = (date, hhmm) => {
    let hhmmArr = hhmm.split(":");
    let hours = parseInt(hhmmArr[0]);
    let mins = parseInt(hhmmArr[1]);
    let newDate = new Date(date);
    newDate.setHours(hours, mins);
    return newDate;
}

export const toYYYYMMDDTHHmmssFormat = (dateTime) => {
    return new Date(dateTime).toString('yyyy-MM-ddTHH:mm:ss');
};


export const toYYYYMMDDFormat = (dateTime) => {
    return new Date(dateTime).toString('yyyy-MM-dd');
};

export const getUniqueColor = () => {
    return '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6);
}

export const isValidPhoneNumber = (tn) => {
    return /05([0-9]){8}$/.test(tn);
}

export const nextDayDate = (date , dayNumber) => {
    let res = new Date(date.getTime());;
    res.setDate(date.getDate() + (dayNumber+(7-date.getDay())) % 7);
    return res;
}