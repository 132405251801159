import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DoreTopBar from '../../../shared/DoreTopBar';
import {makeStyles} from '@material-ui/core/styles';
import FilterMenu from '../../../shared/FilterMenu';
import DoreList from '../../../shared/DoreList';
import DoreExpansionPanel from '../../../shared/DoreExpansionPanel';
import DoreChip from '../../../shared/DoreChip';
import {changeDateFormat, checkByIndex, switchIcon} from '../../../../utils/utils';
import {
    getAvailableDates,
    getEnterpriseBooks,
    getEnterpriseServiceProviders,
    getEnterpriseServices,
    managerBookTurn,
    managerCancelBooking
} from '../../../../services/dataService';
import {getUser} from '../../../../services/storageService';
import DoreFab from "../../../shared/DoreFab";
import DoreEmptyPage from "../../../shared/DoreEmptyPage";
import {ENTERPRISE_STATUSES, unCheckedIcon} from "../../../../utils/consts";
import {alertSuccess} from "../../../../services/snackbarService";
import DoreInputField from "../../../shared/DoreInputField";
import {openConfirmDialog} from "../../../../services/confirmDialogService";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {goToEnterpriseProfilePage} from "../../../../services/pageService";

const useStyles = makeStyles(theme => ({
  list: {
    "& .MuiListItem-root": {
      display: 'block',
      paddingBottom: 0,
    }
  },
}));

// TODO: instead of showing the filters with the same page, create a filter menu like the menubar


/**
 * Manager Books Component
 *
 * @author Mohammad
 */
const EnterpriseBooks = ({ loading }) => {

  // CSS classes
  const classes = useStyles();

  const [refresh, setRefresh] = useState(1);
  const [noData, setNoData] = useState(false);

  const [deleteIcon, setDeleteIcon] = useState(true);
  const [selectedBookings, setSelectedBookings] = useState([]);

  // enterpriseId
  const { enterpriseId ,enterpriseStatus} = getUser();

  // Pooling and filter menu state
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [pollingCounter, setPollingCounter] = useState(0);

  // Bookings state
  const [bookings, setBookings] = useState([]);

  //  services, providers, and dates state
  const [services, setServices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [dates, setDates] = useState([]);

  // ****
  const getSelected = (arr) => arr && arr.length>0 && arr.find(s => s.checked === true);
  const unCheck = (arr) => arr.map(s => s.checked = false);
  const check = (arr, name) => arr.find(s => s.name === name).checked = true;

  // ****
  const setState = async (state, setState) => {
    if(enterpriseId && enterpriseStatus != ENTERPRISE_STATUSES.NEW) {
      const mapping = {
        services: async () => await getEnterpriseServices(enterpriseId),
        providers: async () => await getEnterpriseServiceProviders(enterpriseId, getSelected(services).id),
        dates: async () => await getAvailableDates(enterpriseId, getSelected(services).id, getSelected(providers).id),
      };

      // Apply async call
      const res = await mapping[state]();
      if (res) {
        if (state === 'dates') {
          const dateVersionPairs = res.map(dateVersionPair => ({name: dateVersionPair.date, version: dateVersionPair.version, checked: false }));
          if (dateVersionPairs && dateVersionPairs[0] )
            dateVersionPairs[0].checked = true;
          setState(dateVersionPairs);
        } else {
          const current = res;
          if (current && current[0])
            current[0].checked = true;
          setState(current);
        }
      }
    }
  };

  useEffect(()=>{
    if (!enterpriseId || enterpriseStatus === ENTERPRISE_STATUSES.NEW)
    {
      goToEnterpriseProfilePage()
    }
  },[])

  // Effect runs once - as constructor
  useEffect(() => {

    setState('services', setServices);
  }, []);

  useEffect(() => {
    if (getSelected(services)) setState('providers', setProviders);
  }, [services]);

  useEffect(() => {
    if (getSelected(providers)) setState('dates', setDates);
  }, [providers]);

  // Effect runs when pooling
  useEffect(() => {
    (async () => {
      // Just if the filters is set, get new data
      let selectedDate = getSelected(dates);
      if ( enterpriseId && enterpriseStatus != ENTERPRISE_STATUSES.NEW && getSelected(services) && getSelected(providers) && selectedDate) {
        const bookings = await getEnterpriseBooks(enterpriseId, getSelected(services).id, getSelected(providers).id, selectedDate.name,selectedDate.version);
        if (bookings) {
          let entries = bookings.slots && Object.entries(bookings.slots);
          if(entries) {
              if (entries.length == 0) {
                  setNoData(true);
                  setBookings([]);
              } else {
                  setNoData(false);
                  setBookings(entries.map(([hour,bookingInfo]) => ({
                      id: bookingInfo && bookingInfo.bookingId,
                      title: hour,
                      icon: bookingInfo && bookingInfo.bookingId ? unCheckedIcon : 'calendar_today',
                      //icon: 'calendar_today',
                      iconText: bookingInfo && bookingInfo.bookingId ? null : new Date(selectedDate.name).getDate(),
                      selected: bookingInfo && bookingInfo.bookingId,
                      subTitle: bookingInfo && bookingInfo.bookingId ? bookingInfo.customerName : 'غير محجوز',
                  })));
              }
          }
        }
      }
    })();

    // pool data every 1 min
    const oneMin = 60000;
    const pollingTimerId = setTimeout(() => setPollingCounter(pollingCounter + 1), oneMin * 5);


    // clean-up
    return function cleanup() {
      clearTimeout(pollingTimerId);
    };
  }, [dates, pollingCounter,refresh]);


  const refreshPage = () => {
    setRefresh((prevState => prevState * (-1)))
  }

  useEffect(() => {
    if (selectedBookings.length > 0) {
      setDeleteIcon(true);
    } else {
      setDeleteIcon(false);
      refreshPage();
    }
    ;
  }, [selectedBookings]);

  let consumerName = '';
  const NewBooking = () => {
    const [name, setName] = useState('');
    return (
        <DoreInputField
            bottomSpace={false}
            loading={{}}
            label="الاسم"
            icon="person"
            value={name}
            onChange={e => {
              const newName = e.target.value;
              setName(newName);
              consumerName = newName;
            }}
        />
    );
  };

  const addBooking = (booking) => {
    let titleInfo = 'حجز دور:'
    let subTitleInfo =  getSelected(services).name + " - " + getSelected(providers).name + " - " + getSelected(dates).name + " - " + booking.title
    openConfirmDialog(faBook, titleInfo,subTitleInfo,
        <NewBooking />,
        () => {
            bookTurn(booking)
        });
  };

  const bookTurn = async (booking) => {
      let selectedDate = getSelected(dates);
    const slots = [{
      hour: booking.title,
      date : selectedDate.name,
      version: selectedDate.version,
      serviceTypeId: getSelected(services).id,
      serviceProviderId: getSelected(providers).id,
      customerName : consumerName ? consumerName : null
    }]
    managerBookTurn(enterpriseId, slots).then(() => {
          alertSuccess('تم الحجز بنجاح');
          refreshPage();
        }
    );
  }

  const onBookingClick = async (booking, index) => {

    if(booking && booking.id) {
      switchIcon(bookings, setBookings, selectedBookings, setSelectedBookings, booking.icon, booking.id, index);
    }
    else {
      addBooking(booking);
    }
  };

  const updateService = async (index) => {
    checkByIndex(index, services, setServices);
  };

  const updateProvider = async (index) => {
    checkByIndex(index, providers, setProviders);
  };

  const updateDate = async (index) => {
    checkByIndex(index, dates, setDates);
  };


  const onDelete = async () => {
    const bookingsToCancel = selectedBookings.map(item => item.data);
      managerCancelBooking(enterpriseId, bookingsToCancel).then(() => {
          setSelectedBookings([]);
        }
    );
  };

  return (
    <div>
      <DoreTopBar
          loading={loading}
          onFilter={() => setOpenFilterMenu(true)}
          counter={selectedBookings.length > 0 && selectedBookings.length}
          counterIcon="delete"
      />
      <FilterMenu
        open={openFilterMenu}
        onClose={() => setOpenFilterMenu(false)}
      >
        <DoreExpansionPanel title="الخدمة" icon="business_center" initialExpanded={true} forceCancelLoading={true}>
          <div style={{
            paddingBottom: '1rem',
          }}>
            {services && services.length>0 && services.map((service, index) => (
              <DoreChip
                key={service.name}
                label={service.name}
                checked={service.checked}
                onClick={() => updateService(index)}
              />
            ))}
          </div>
        </DoreExpansionPanel>
        <DoreExpansionPanel title="مقدم الخدمة" icon="account_circle" initialExpanded={true} forceCancelLoading={true}>
          <div style={{
            paddingBottom: '1rem',
          }}>
            {providers && providers.length>0 && providers.map((provider, index) => (
              <DoreChip
                key={provider.name}
                label={provider.name}
                checked={provider.checked}
                onClick={() => updateProvider(index)}
              />
            ))}
          </div>
        </DoreExpansionPanel>
        <DoreExpansionPanel title="التاريخ" icon="today" initialExpanded={true} forceCancelLoading={true}>
          <div style={{
            paddingBottom: '1rem',
          }}>
            {dates && dates.map((date, index) => (
              <DoreChip
                key={date.name}
                label={changeDateFormat(date.name)}
                checked={date.checked}
                onClick={() => updateDate(index)}
              />
            ))}
          </div>
        </DoreExpansionPanel>
      </FilterMenu>

      <DoreFab
          color="secondary"
          onClick={onDelete}
          inout={deleteIcon}
          icon="delete"
          loading={loading}
          variant="extended"
          label="حذف"
      />

      <DoreList list={bookings} loading={loading} onClick={onBookingClick}  />
      {noData && <DoreEmptyPage/>}

    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
}))(React.memo(EnterpriseBooks));
