import history from '../history';
import {
    ENTERPRISES_PREFIX_KEY,
    PROVIDERS_PREFIX_KEY,
    SERVICES_PREFIX_KEY,
    SUBSCRIBERS_PREFIX_KEY,
    USER_TYPE
} from '../utils/consts';
import {clearStorage, getUser, isAuth} from './storageService';

// Dynamic paths
export const ENTERPRISES = '/enterprises';
export const PROFILE = '/profile';
export const BOOKINGS = '/bookings';

export const ENTERPRISE_HOME = `${ENTERPRISES}`;
export const SERVICES = (enterpriseId) => `${ENTERPRISE_HOME}/${enterpriseId}/services`;
export const PROVIDERS = (enterpriseId, serviceId) => `${SERVICES(enterpriseId)}/${serviceId}`;
export const AVAILABLE_DATES = (enterpriseId, serviceId, providerId) => `${PROVIDERS(enterpriseId, serviceId)}/${providerId}`;
export const TIME_SLOTS = (enterpriseId, serviceId, providerId, date) => `${AVAILABLE_DATES(enterpriseId, serviceId, providerId)}/${date}`;

export const ENTERPRISE_BOOKS = '/enterprise-books';
export const SUBSCRIBERS = '/subscribers';
export const SUBSCRIBER_PROFILE = (subscriberId) => `${SUBSCRIBERS}/${subscriberId}`;
export const ENTERPRISE_PROFILE = '/enterprise-profile';
export const ENTERPRISE_VACATIONS = '/enterprise-vacations';
export const ENTERPRISE_WORKING_HOURS = '/enterprise-wh';
export const ENTERPRISE_SERVICES = '/enterprise-profile/services';
export const ENTERPRISE_EMPLOYEES = '/enterprise-profile/employees';
export const ENTERPRISE_SERVICE_TYPE_PROFILE = (serviceTypeId) => `${ENTERPRISE_SERVICES}/${serviceTypeId}`;
export const ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE = (serviceTypeId,serviceTypeVersionId) => `${ENTERPRISE_SERVICE_TYPE_PROFILE(serviceTypeId)}/${serviceTypeVersionId}`;
export const ENTERPRISE_SERVICE_PROVIDER_PROFILE = (serviceTypeId,serviceTypeVersionId,serviceProviderId) => `${ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE(serviceTypeId,serviceTypeVersionId)}/${serviceProviderId}`;
export const ENTERPRISE_EMPLOYEE_PROFILE = (employeeId) => `${ENTERPRISE_EMPLOYEES}/${employeeId}`;


// Static paths
export const SIGN_UP_PATH = '/';

export const ENTERPRISES_PATH = ENTERPRISES;
export const PROFILE_PATH = PROFILE;
export const BOOKINGS_PATH = BOOKINGS;


export const ENTERPRISE_HOME_PATH = `${ENTERPRISE_HOME}/:enterpriseId`;
export const SERVICES_PATH = `${ENTERPRISE_HOME_PATH}/services`;
export const PROVIDERS_PATH = `${SERVICES_PATH}/:serviceId`;
export const AVAILABLE_DATES_PATH = `${PROVIDERS_PATH}/:providerId`;
export const TIME_SLOTS_PATH = `${AVAILABLE_DATES_PATH}/:date`;

export const ENTERPRISE_BOOKS_PATH = ENTERPRISE_BOOKS;
export const ENTERPRISE_VACATIONS_PATH = ENTERPRISE_VACATIONS;
export const ENTERPRISE_WH_PATH = ENTERPRISE_WORKING_HOURS;
export const ENTERPRISE_SUBSCRIBERS_PATH = SUBSCRIBERS;
export const ENTERPRISE_SUBSCRIBER_PROFILE_PATH = `${SUBSCRIBERS}/:subscriberId`;
export const ENTERPRISE_PROFILE_PATH = ENTERPRISE_PROFILE;
export const ENTERPRISE_SERVICES_PATH = ENTERPRISE_SERVICES;
export const ENTERPRISE_EMPLOYEES_PATH = ENTERPRISE_EMPLOYEES;
export const ENTERPRISE_SERVICE_TYPE_PROFILE_PATH = `${ENTERPRISE_SERVICES_PATH}/:serviceTypeId`;
export const ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE_PATH = `${ENTERPRISE_SERVICE_TYPE_PROFILE_PATH}/:serviceTypeVersionId`;
export const ENTERPRISE_SERVICE_PROVIDER_PROFILE_PATH =  `${ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE_PATH}/:serviceProviderId`;
export const ENTERPRISE_EMPLOYEE_PROFILE_PATH = `${ENTERPRISE_EMPLOYEES_PATH}/:employeeId`;

const isPathAllowedWithoutAuth = (path) => path === SIGN_UP_PATH;

const goToPath = (path) => {
  if (!path || (history.location.pathname === path)) return;
  if (isAuth() || isPathAllowedWithoutAuth(path)) {
    console.log("path22:",path);
    console.log("history:",history && history.push);
    history.push(path);
  }
};

export const goToSignUpPage = () => {
  clearStorage(); // TODO: Maybe to put this code just in the click handler of logout button
  goToPath(SIGN_UP_PATH);
};

export const goToProfilePage = () => {
  goToPath(PROFILE);
};

export const goToEnterprisesPage = () => {
  goToPath(ENTERPRISES);
};

export const goToEnterpriseHomePage = (enterpriseId) => {
  goToPath(`${ENTERPRISE_HOME}/${enterpriseId}_${ENTERPRISES_PREFIX_KEY}`);
};

export const goToServicesPage = (enterpriseId) => {
  goToPath(SERVICES(`${enterpriseId}_${ENTERPRISES_PREFIX_KEY}`));
};

export const goToProvidersPage = (enterpriseId, serviceId) => {
  goToPath(PROVIDERS(`${enterpriseId}_${ENTERPRISES_PREFIX_KEY}`, `${serviceId}_${SERVICES_PREFIX_KEY}`));
};

export const goToAvailableDatesPage = (enterpriseId, serviceId, providerId) => {
  goToPath(AVAILABLE_DATES(`${enterpriseId}_${ENTERPRISES_PREFIX_KEY}`, `${serviceId}_${SERVICES_PREFIX_KEY}`, `${providerId}_${PROVIDERS_PREFIX_KEY}`));
};

export const goToTimeSlotsPage = (enterpriseId, serviceId, providerId, date , version) => {
  goToPath(TIME_SLOTS(`${enterpriseId}_${ENTERPRISES_PREFIX_KEY}`, `${serviceId}_${SERVICES_PREFIX_KEY}`, `${providerId}_${PROVIDERS_PREFIX_KEY}`, date));
};

export const goToBookingsPage = () => {
  goToPath(BOOKINGS);
};

export const goToEnterpriseBooksPage = () => {
  goToPath(ENTERPRISE_BOOKS);
};

export const goToEnterpriseVacationsPage = () => {
  goToPath(ENTERPRISE_VACATIONS);
};

export const goToEnterpriseWorkingHoursPage = () => {
  goToPath(ENTERPRISE_WORKING_HOURS);
};

export const goToEnterpriseSubscribersPage = () => {
  goToPath(SUBSCRIBERS);
};

export const goToEnterpriseSubscriberProfilePage = (subscriberId) => {
  goToPath(SUBSCRIBER_PROFILE(`${subscriberId}_${SUBSCRIBERS_PREFIX_KEY}`));
};

export const goToEnterpriseServiceTypePage = (serviceTypeId) => {
  goToPath(ENTERPRISE_SERVICE_TYPE_PROFILE(`${serviceTypeId}_${SERVICES_PREFIX_KEY}`));
};

export const goToEnterpriseServiceTypeVersionPage = (serviceTypeId,serviceTypeVersionId) => {
  goToPath(ENTERPRISE_SERVICE_TYPE_VERSION_PROFILE(`${serviceTypeId}_${SERVICES_PREFIX_KEY}`,serviceTypeVersionId));
};
export const goToEnterpriseServiceProviderPage = (serviceTypeId,serviceTypeVersionId,serviceProviderId) => {
  goToPath(ENTERPRISE_SERVICE_PROVIDER_PROFILE(`${serviceTypeId}_${SERVICES_PREFIX_KEY}`,serviceTypeVersionId,`${serviceProviderId}_${PROVIDERS_PREFIX_KEY}`));
};

export const goToEnterpriseEmployeeProfilePage = (employeeId) => {
  goToPath(ENTERPRISE_EMPLOYEE_PROFILE(employeeId));
};

export const goToEnterpriseProfilePage = () => {
  goToPath(ENTERPRISE_PROFILE);
};

export const goToEnterpriseEmployeesPage = () => {
  goToPath(ENTERPRISE_EMPLOYEES);
};

export const goToEnterpriseServicesPage = () => {
  goToPath(ENTERPRISE_SERVICES_PATH);
};

export const goToByBreadCrumb = (path, node) => {
  goToPath(path.substring(0, path.indexOf(node) + node.length));
};

// Helpers
export const goToHomePage = () => {
  let userInfo = getUser();
  switch (userInfo && userInfo.type) {
    case USER_TYPE.CONSUMER: {
      goToEnterprisesPage();
      break;
    }
    case USER_TYPE.MANAGER: {
      goToEnterpriseBooksPage();
      break;
    }
    default: {
      break;
    }
  }
};
