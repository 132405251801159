import store from '../store/store';
import {setSnackbar} from '../store/actions';

const $alert = (msg, severity) => {
  store.dispatch(setSnackbar(createSnackbar(true, msg, severity)));
};

export const createSnackbar = (open = false, msg = '', severity = '') => ({ open, msg, severity });

export const alertSuccess = (msg) => {
  $alert(msg, 'success');
};

export const alertError = (msg) => {
  $alert(msg, 'error');
};

export const alertWarn = (msg) => {
  $alert(msg, 'warning');
};

export const alertInfo = (msg) => {
  $alert(msg, 'info');
};