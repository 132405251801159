import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {translate, translate2} from "../../../services/translateService";
import store from "../../../store/store";
import {isMobile} from "react-device-detect";
import {getService} from "../../../services/storageService";
import {createEventId} from "../../pages/manager/profile/serviceProvider/event-utils";
import {changeTimeFormat, checkByIndex, resetHHMMToDate} from "../../../utils/utils";
import {closeConfirmDialog, openConfirmDialog} from "../../../services/confirmDialogService";
import {faCog, faEdit} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";
import DoreChip from "../DoreChip";
import EditCalendarSettings from "./EditCalendarSettings";
import EditCalendarSlot from "./EditCalendarSlot";
import LabelledOutline from "../utils/LabelledOutline";


const useStyles = makeStyles((theme) => ({
    "body": {
        marginTop: "40px",
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "\"Lucida Grande\",Helvetica,Arial,Verdana,sans-serif"
    },
    "h1": {
        fontSize: "35px",
        marginBottom: "30px"
    },
    "app": {
        width: "1100px",
        margin: "0 auto"
    },
    "external_events": {
        float: "left",
        width: "150px",
        // padding: "0 10px",
        // border: "1px solid #ccc",
        // background: "#eee",
        textAlign: "left"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    "external_events_h4": {
        fontSize: "16px",
        marginTop: "0",
        paddingTop: "1em"
    },
    "external_events__fc_event": {
        margin: "10px 0",
        cursor: "pointer",
        textAlign: "center",
        color: "#FFFFFF",
        background: "#3A87AD",
    },
    "external_events_p": {
        margin: "1.5em 0",
        fontSize: "11px",
        color: "#666"
    },
    "external_events_p_input": {
        margin: "0",
        verticalAlign: "middle"
    },
    "calendar_container": {
        "position": "relative",
        "zIndex": "1",
        "marginLeft": "200px"
    },
    "calendar": {
        float: "left",
        width: "900px",
        height: "10000px"
    }
    , timePicker_container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    timePicker_textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export const getDayHeaderContent = (arg, isOneWeekCalendar) => {
    let day = arg.date.getDay()//NUMBERS_TO_DAYS[arg.date.getDay()];
    const {pageTranslation} = translate('numbersDays', store.getState().currentLanguage);
    let newVar = isMobile && (arg.view.type === 'timeGridWeek' || arg.view.type === 'listWeek') ? arg.dow + 1 : pageTranslation[day];
    if (isMobile && !isOneWeekCalendar) {
        return <div style={{textAlign: "center"}}> {arg.date.getDate()} </div>
    } else if (isOneWeekCalendar) {
        return <p> {newVar} </p>;
    } else {
        return <div>
            <div> {newVar} </div>
            <div> {arg.date.getDate()} </div>
        </div>
    }
}

export const ServicesPickUpListComponent = ({services, setServices, setServiceTypeId}) => {

    const classes = useStyles();
    return <div>
        {
            !isMobile && services && services.length > 0 &&
            <div className={classes.external_events}>
                <LabelledOutline className={classes.external_events} id="services"
                                 label={`${translate2("services")}`}>
                    {
                        services.map(service => (
                            <div
                                id={service.id}
                                key={service.id}
                                onClick={(se) => setServiceTypeId(service.id)}
                                className={classes.external_events__fc_event}
                                style={{background: service.backgroundColor}}
                            >
                                <div className="fc-event-main">
                                    <p>{service.name}</p>
                                </div>


                            </div>
                        ))
                    }
                </LabelledOutline>
            </div>
        }
        {
            isMobile && services && services.length > 0 &&
            <div style={{marginRight: '12px'}}>
                <header style={{color: 'primary'}}>
                    {`${translate2("services")}:`}
                </header>
                {services.map((service, index) => (
                    <DoreChip
                        id={service.id}
                        key={service.name}
                        label={service.name}
                        checked={service.checked}
                        onClick={() => {
                            setServiceTypeId(service.id)
                            checkByIndex(index, services, setServices)
                        }}
                    />
                ))}
                <br/>
                <br/>
            </div>
        }
    </div>
};

const DoreOneWeekCalendar = ({
                                 loading,
                                 isOneWeekCalendar = true,
                                 providerInfo,
                                 onUpdateEvent,
                                 onChangeCalendar,
                                 onChangeWeekRange,
                                 inputDuration,
                                 inputServices,
                                 inputServiceTypeId
                             }) => {

    const [fcView, setFCView] = useState("timeGridWeek")
    const [isToShowRightArrow, setIsToShowRightArrow] = useState(false)
    const [isToShowLeftArrow, setIsToShowLeftArrow] = useState(false)
    const [weekRange, setWeekRange] = useState({})
    const [calendar, setCalendar] = useState(null);
    const [services, setServices] = useState([]);
    const [serviceTypeId, setServiceTypeId] = useState(inputServiceTypeId);
    const [allowSlotsOverlap, setAllowSlotsOverlap] = useState(false);
    const [slotDuration, setSlotDuration] = useState(10);

    function getDuration(serviceTypeInfo) {
        let isProviderServiceDefined = providerInfo
            && providerInfo.services
            && providerInfo.services[serviceTypeInfo.id]
            && providerInfo.services[serviceTypeInfo.id].versions
            && providerInfo.services[serviceTypeInfo.id].versions[0];
        let durationValue = isProviderServiceDefined
            ? providerInfo.services[serviceTypeInfo.id].versions[0].duration
            : inputDuration || (serviceTypeInfo && serviceTypeInfo.versions && serviceTypeInfo.versions[0] && serviceTypeInfo.versions[0].duration) || 20;
        return durationValue;
    }


    function toHHmm(duration) {
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        let hh = hours < 10 ? '0' + hours : '' + hours;
        let mm = minutes < 10 ? '0' + minutes : '' + minutes;
        return hh + ':' + mm;
    }

    function getDurationFormatHHmm(serviceTypeInfo) {
        const duration = getDuration(serviceTypeInfo);
        return toHHmm(duration);
    }

    const handleDateSelect = (selectInfo) => {
        console.log("handleDateSelect-serviceTypeId", serviceTypeId);
        let serviceTypeInfo = getService(serviceTypeId);
        let title = serviceTypeInfo && serviceTypeInfo.name;
        let duration = getDuration(serviceTypeInfo);
        let eventId = createEventId();
        let calendarApi = selectInfo.view.calendar

        calendarApi.addEvent({
            serviceTypeId: serviceTypeId,
            serviceDuration: duration,
            id: eventId,
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            backgroundColor: serviceTypeInfo.backgroundColor
        })
    }

    // const [stTime, setStTime] = useState();
    // const [endTime, setEndTime] = useState();

    const handleEventClick = (clickInfo) => {
        let stTime = changeTimeFormat(clickInfo.event.start)
        let endTime = changeTimeFormat(clickInfo.event.end)
        let time = `${stTime}-${endTime}`
        let titleInfo = translate2("editSlot") + ":"
        openConfirmDialog(faEdit, titleInfo, null,
            <EditCalendarSlot
                inputEvent={clickInfo.event}
                onChangeEndTime={(newTime) => {
                    endTime = newTime
                }}
                onChangeStartTime={(newTime) => {
                    stTime = newTime
                }}
                onDeleteEvent={() => {
                    clickInfo.event.remove();
                    closeConfirmDialog()
                }}
            />,
            () => {
                let time = `${stTime}-${endTime}`
                clickInfo.event.setStart(resetHHMMToDate(clickInfo.event.start, stTime))
                clickInfo.event.setEnd(resetHHMMToDate(clickInfo.event.end, endTime))
            });
    }


    useEffect(() => {
        if (inputServices && inputServices.length > 0) {
            setServices(inputServices)
            setServiceTypeId(inputServices[0] && inputServices[0].id);
        }

    }, [inputServices])

    useEffect(() => {
        if (calendar && services && isOneWeekCalendar) {
            services.forEach((service, idx) => {
                let draggableEl = document.getElementById(service.id);
                calendar.draggable = true
                let duration = getDuration(service)
                new Draggable(draggableEl, {
                    eventData: {
                        title: service.name,
                        duration: toHHmm(duration),
                        serviceTypeId: service.id,
                        serviceDuration: duration,
                        backgroundColor: service.backgroundColor
                    }
                });
                //TODO assign onDragStartFunction for the dragged items
            })
        }
    }, [calendar, services])


    function getServiceName(serviceTypeInfo) {
        return serviceTypeInfo && serviceTypeInfo.name;
    }

    const renderEventContent = (eventInfo) => {

        if (serviceTypeId) {
            let serviceTypeInfo = getService(serviceTypeId);
            if (eventInfo && eventInfo.event && eventInfo.event.end) {
                let Difference_In_Time = eventInfo.event.end.getTime() - eventInfo.event.start.getTime();
                let Difference_In_MINS = Difference_In_Time / (60000);
                let duration1 = eventInfo.event.extendedProps.serviceDuration;
                let duration = duration1 || getDuration(serviceTypeInfo);
                let numOfSlots = Difference_In_MINS / (duration);
                let title = eventInfo.event.title || getServiceName(serviceTypeInfo);
                return (
                    <div id={"ev." + eventInfo.event.id}
                         draggable={true}
                    >
                        <b>{title}</b>
                        <br/>
                        <b>{eventInfo.timeText}</b>
                        <br/>
                        <b>{"عدد الأدوار: " + Math.trunc(numOfSlots)}</b>

                    </div>
                )
            } else {
                let stId = eventInfo.event.draggedEl && eventInfo.event.draggedEl.id;
                let title = getServiceName(serviceTypeInfo);
                return (
                    <div>
                        <b>{title}</b>
                    </div>
                )
            }
        }
    }

    const onSettingsButtonClick = () => {
        let titleInfo = translate2("editCalendarSettings") + ":"
        openConfirmDialog(faCog, titleInfo, null,
            <EditCalendarSettings
                inputSlotDuration={slotDuration}
                onChangeSlotDuration={setSlotDuration}
                inputAllowSlotsOverlap={allowSlotsOverlap}
                onChangeAllowSlotsOverlap={setAllowSlotsOverlap}
            />,
            null, null, false, true);
    }


    const endTopBar = () => {
        if (!isOneWeekCalendar) {
            return 'timeGridWeek,timeGridThreeDays,timeGridDay';
        } else {
            return 'timeGridWeek,listWeek,timeGridDay'
        }
    };


    const startTopBar = () => {
        if (!isOneWeekCalendar) {
            return 'settingsButton prev,next'
        } else {
            const res = 'settingsButton'
                .concat(isToShowLeftArrow || isToShowRightArrow ? ' ' : '')
                .concat(isToShowLeftArrow ? 'prev' : '')
                .concat(isToShowLeftArrow && isToShowRightArrow ? ',' : '')
                .concat(isToShowRightArrow ? 'next' : '')
            return res;
        }
    };


    return (
        <div>
            {isMobile && calendar && !isOneWeekCalendar && <div>
                {/*<br/>*/}
                <h1 style={{textAlign: 'center'}}>{calendar.currentDataManager.data.viewTitle}</h1>
            </div>}
            {!isMobile && <br/>}
            {isOneWeekCalendar && <ServicesPickUpListComponent
                services={services}
                setServices={setServices}
                setServiceTypeId={setServiceTypeId}
            />}
            <div>
                <FullCalendar
                    slotDuration={toHHmm(slotDuration)}
                    slotEventOverlap={allowSlotsOverlap}
                    selectOverlap={allowSlotsOverlap}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    customButtons={{
                        settingsButton: {
                            text: translate2("settings"),
                            click: onSettingsButtonClick
                        }
                    }}
                    scrollTime={'08:00'}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    datesSet={
                        (arg) => {
                            setCalendar(arg.view.calendar)
                            onChangeCalendar && onChangeCalendar(arg.view.calendar)
                            let isDayView = arg.view.type === 'timeGridDay' || arg.view.type === 'listDay';
                            if (!isDayView) {
                                let endDate = new Date(arg.view.activeEnd.getTime());
                                endDate.setDate(arg.view.activeEnd.getDate() - 1);
                                let weekRangeValue = {
                                    startDate: arg.view.activeStart,
                                    endDate: endDate
                                };
                                setWeekRange(weekRangeValue)
                                onChangeWeekRange && onChangeWeekRange(weekRangeValue)
                            }
                            setFCView(arg.view.type)

                            if (isOneWeekCalendar) {
                                setIsToShowRightArrow(isDayView &&
                                    arg.view.currentStart.isBefore(weekRange.endDate))
                                setIsToShowLeftArrow(isDayView &&
                                    arg.view.currentStart.isAfter(weekRange.startDate))
                            }
                        }
                    }
                    plugins={
                        isOneWeekCalendar
                            ? [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]
                            : [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]
                    }
                    headerToolbar={{
                        // start: isToShowRightArrow ? 'timeGridDay,listDay,settingsButton next' : 'timeGridDay,listDay,settingsButton',
                        // end: isToShowLeftArrow ? 'prev timeGridWeek,listWeek' : 'timeGridWeek,listWeek'
                        center: isMobile || isOneWeekCalendar ? '' : 'title',
                        start: startTopBar(),
                        end: endTopBar()
                    }}

                    // locale={isOneWeekCalendar ? 'en-GB' : translate2("languageCode")}
                    locale={'en-GB'}
                    direction={'rtl'}
                    allDaySlot={false}
                    dayHeaderContent={(arg) => getDayHeaderContent(arg, isOneWeekCalendar)}
                    titleFormat={fcView === 'timeGridDay'
                        ? {year: 'numeric', month: 'numeric', day: 'numeric'}
                        : {year: 'numeric', month: 'numeric'}}
                    dayHeaderFormat={
                        isOneWeekCalendar
                            ? isMobile && fcView === 'timeGridWeek' ? {day: 'numeric'} : {weekday: 'short'}
                            : isMobile && fcView === 'timeGridWeek' ? {day: 'numeric'} : {
                                weekday: 'short',
                                month: 'numeric',
                                day: 'numeric'
                            }
                    }
                    views={{
                        // listDay: {buttonText: 'قائمة اليوم'},
                        listWeek: {buttonText: translate2("list")},
                        // listMonth: {buttonText: 'list month'},
                        timeGridWeek: {buttonText: translate2("week")},
                        dayGridMonth: {buttonText: translate2("month")},
                        timeGridDay: {buttonText: translate2("day")},
                        timeGridThreeDays: {
                            type: 'timeGrid',
                            duration: {days: 3},
                            buttonText: translate2("3Days")
                        }
                    }}
                    initialView='timeGridWeek'
                    editable={isOneWeekCalendar}
                    selectable={isOneWeekCalendar}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    select={handleDateSelect}
                    eventLongPressDelay={200}
                    selectLongPressDelay={200}
                    eventOverlap={allowSlotsOverlap}
                    eventContent={renderEventContent} // custom render function
                    eventClick={isOneWeekCalendar && handleEventClick}
                    eventAdd={(ev) => {

                        onUpdateEvent && onUpdateEvent(calendar)
                    }}
                    eventChange={(ev) => {
                        onUpdateEvent && onUpdateEvent(calendar)
                    }}
                    eventRemove={(ev) => {
                        onUpdateEvent && onUpdateEvent(calendar)
                    }}
                    droppable="true"
                />

            </div>


        </div>
    )
        ;

};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(DoreOneWeekCalendar);
