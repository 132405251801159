import React, {useState} from 'react';
import {connect} from 'react-redux';
import TopBar from '../shared/DoreTopBar';
import DoreTabs from "../shared/DoreTabs";
import Enterprises from "./Enterprises";
import {translate2} from "../../services/translateService";
import DoreTopBar from "../shared/DoreTopBar";




/**
 * Consumers Component
 *
 * @author Mohammad
 */
const ConsumerMainPage = ({loading}) => {
    const [searchText, setSearchText] = useState('');

    const handleSearch = (searchText) =>{
        console.log("handleSearch  " + searchText)
        setSearchText(searchText)
    }

    return <div>
        <TopBar loading={loading}
                // onSearch={(searchText)=>{handleSearch(searchText)}} //TBD enable search
        />
        <DoreTabs
            loading={loading}
            tabs={[
                {
                    label: translate2("venues"),
                    icon: 'dns',
                    children: <Enterprises searchText={searchText} showTopBar={false} loading={loading}/>
                },
                {
                    label: translate2("mySubscriptions"),
                    icon: 'touch_app',
                    children: <Enterprises filterType={"SUBSCRIPTION"} showTopBar={false} loading={loading}/>
                }
            ]}
        />
    </div>;
};

export default connect(state => ({
    loading: state.loading,
}))(ConsumerMainPage);
