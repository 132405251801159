import React, {useEffect, useState} from 'react';
import {changeLanguage, getMenuBarItems, JSS_RTL, THEME} from './utils/utils';
import {StylesProvider, ThemeProvider} from '@material-ui/styles';
import {default as Router} from './router';
import Snackbar from './components/shared/DoreSnackbar';
import {goToHomePage, goToSignUpPage, SIGN_UP_PATH} from './services/pageService';
import history from './history';
import DoreDialog from './components/shared/DoreDialog';
import {isAuth} from './services/storageService';
import DoreConfirmDialog from './components/shared/DoreConfirmDialog';
import {connect} from 'react-redux';
import DoreMenuBar from './components/shared/DoreMenuBar';
import {setIsMenuBarOpen} from './store/actions';

const App = ({ dispatch, currentLanguage, isMenuBarOpen, menuBarItems }) => {

  const [showMenuBar, setShowMenuBar] = useState(true);

  // TODO: check why runs more than one time!!!!?
  history.listen((location, action) => {
    if (location.pathname === SIGN_UP_PATH) {
      console.log('check why runs more than one time!!!!?');
      document.body.style.marginRight = '0';
      setShowMenuBar(false);
    } else {
      setShowMenuBar(true);
    }
  });

  useEffect(() => {

    // TODO: Temporary and problematic code - Needed another stable solution
    changeLanguage(currentLanguage, dispatch);

    // TODO: Need to be checked - Maybe remove it!!!
    if (history.location.pathname === SIGN_UP_PATH && isAuth()) {
      goToHomePage();
    } else {
      if (!isAuth()) {
        document.body.style.marginRight = '0';
        setShowMenuBar(false);
        goToSignUpPage();
      }
    }

    // *** cleanUp function ***
    return function cleanup() {
    };
  }, []);

  // *** The Application main UI ***
  return (
    <StylesProvider jss={JSS_RTL}>
      <ThemeProvider theme={THEME}>
        {showMenuBar &&
        <DoreMenuBar
          menu={menuBarItems || getMenuBarItems()}
          open={isMenuBarOpen}
          onClose={() => dispatch(setIsMenuBarOpen(false))}
        />}
        <DoreConfirmDialog />
        <DoreDialog />
        <Snackbar />
        <Router />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default connect(state => ({
  currentLanguage: state.currentLanguage,
  isMenuBarOpen: state.isMenuBarOpen,
  menuBarItems: state.menuBarItems,
}))(App);
