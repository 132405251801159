import store from '../store/store';
import {setDialog} from '../store/actions';
import {MAIN_COLOR_B} from '../styles';

export const createDialog = (
  open = false,
  children = null,
  headerColor = MAIN_COLOR_B,
  onCloseExternal = null,
  onClose = () => closeDialog(onCloseExternal),
  closeBtn = true,
  title = '',
  footer = false) => ({
  open,
  children,
  closeBtn,
  onClose,
  headerColor,
  title,
  footer,
});

export const openDialog = (children, headerColor,onCloseExternal) => {
  store.dispatch(setDialog(createDialog(true, children, headerColor,onCloseExternal)));
};


// export const closeDialog = () => {
//   store.dispatch(setDialog(createDialog()));
// };

export const closeDialog = (onCloseExternalFunc) => {
  onCloseExternalFunc && onCloseExternalFunc();
  store.dispatch(setDialog(createDialog()));
};