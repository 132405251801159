import React, {useEffect, useState} from 'react';
import {goToEnterpriseHomePage} from '../../services/pageService';
import DoreTopBar from '../shared/DoreTopBar';
import {connect} from 'react-redux';
import DoreMediaCard from '../shared/DoreMediaCard';
import logoSrc from "../../imgs/doretou-barber.jpg";
import {SUBSCRIPTION_STATUS} from '../../utils/consts';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {getEnterprises} from '../../services/dataService';
import {ENTERPRISES_KEY, mapAndSave} from '../../services/storageService';
import {translate2} from "../../services/translateService";

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  background: {
    background: `url(${logoSrc}) center no-repeat fixed`,
    backgroundSize: 'cover',
    filter: 'blur(3px)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  area: {
    zIndex: 2,
  },
}));

const DoreEnterpriseStatus = ({ status, loading, enterpriseId  }) => {

  const [innerStatus, setInnerStatus] = useState(status);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.background}></div>
      <div className={classes.area}>
        <Typography
          variant="h4"
          align="center"
          style={{
            color: 'white',
            textShadow: `2px 2px 8px #000000`,
          }}
        >
          {!innerStatus && <span>{translate2("subscribeNow")}</span>}
          {innerStatus === SUBSCRIPTION_STATUS.PENDING.key && <span>{translate2("subscriptionRequestSent")}</span>}
        </Typography>

      </div>
    </div>
  )
};



/**
 * Enterprises Component
 *
 * @author Mohammad
 */
const Enterprises = ({ dispatch,
                       searchText,
                       loading ,
                       showTopBar = true ,
                       filterType}) => {

  const [enterprises, setEnterprises] = useState({});

  useEffect(() => {
    (async () => {
      console.log("search with " + searchText)
      const e = await getEnterprises(filterType);
      if (e) {
        setEnterprises(mapAndSave(ENTERPRISES_KEY, e));
      }
    })();
  }, [searchText]);

  return (
    <div>
      {showTopBar &&
      <DoreTopBar
          loading={loading}
      />}
      {!loading.skeleton && enterprises && Object.keys(enterprises).map(k => (
        (
          !enterprises[k].subscriptionStatus ||
          enterprises[k].subscriptionStatus === SUBSCRIPTION_STATUS.PENDING.key ||
          enterprises[k].subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE.key
        ) &&
        <DoreMediaCard
          key={k}
          title={`${enterprises[k].name} - ${translate2(enterprises[k].categoryType)}`}
          subTitle={enterprises[k].description}
          image={enterprises[k].subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE.key ? logoSrc : null}
          children={
            enterprises[k].subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE.key ? null :
              <DoreEnterpriseStatus loading={loading} status={enterprises[k].subscriptionStatus} enterpriseId={k} />
          }
          logo={logoSrc}
          onClick={() => goToEnterpriseHomePage(k)}
        />
      ))}
      {loading.skeleton && <DoreMediaCard loading={loading.skeleton} />}
    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
  breadCrumb: state.breadCrumb,
}))(Enterprises);
