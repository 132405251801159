import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Styles
 */
const useStyles = makeStyles({
  wrapper: {},
  titleLogo: {
    width: '7.8rem',
    height: '7.8rem',
    borderRadius: '50%',
    opacity: '.7',
  },
});

/**
 *
 */
export const DAvatarSkeleton = () => {
  const classes = useStyles();
  return (
    <Skeleton
      animation="wave"
      variant="circle"
      className={classes.titleLogo}
      style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '1rem' }}
    />
  );
};

/**
 *
 */
const DAvatar = ({ src }) => {
  const classes = useStyles();
  return (
    <img src={src} alt="sub-logo" className={classes.titleLogo} />
  );
};

/**
 *
 */
const DoreAvatar = (props) => (
  <div>
    {props.loading.skeleton && <DAvatarSkeleton />}
    {!props.loading.skeleton && <DAvatar {...props} />}
  </div>
);

export default DoreAvatar;
