import React from 'react';
import {connect} from 'react-redux';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import LabelledOutline from "./utils/LabelledOutline";
import {translate2} from "../../services/translateService";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const DoreSingleSelect = ({value, title, onChange, id, items, nullItemValue, nullItemName, open = false, onClose, onOpen}) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl}>
            {/*<InputLabel>{title}</InputLabel>*/}
            <LabelledOutline id="serviceProvider" label={`${translate2("serviceProvider")}`} >
                <Select
                    disableUnderline
                    //style={{ textDecoration: 'none' }}
                    labelId={id + "-label"}
                    id={id}
                    open={open}
                    onClose={onClose}
                    onOpen={onOpen}
                    value={value}
                    onChange={onChange}
                >
                    {nullItemName && nullItemValue && <MenuItem key={"nullObj"} value={nullItemValue}>
                        <em>{nullItemName}</em>
                    </MenuItem>}
                    {items && items.length > 0 && items.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                    ))}
                </Select>
            </LabelledOutline>
        </FormControl>
    );
};

export default connect(state => ({
    loading: state.loading,
}))(DoreSingleSelect);
