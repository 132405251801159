import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import DoreExpansionPanel from '../../../shared/DoreExpansionPanel';
import {calculateAge, changeDateFormat, checkByKey} from '../../../../utils/utils';
import DoreChip from '../../../shared/DoreChip';
import {SUBSCRIPTION_STATUS} from '../../../../utils/consts';
import DoreFab from '../../../shared/DoreFab';
import {getConsumer as gc, updateConsumer} from '../../../../services/dataService';
import DoreTopBar from '../../../shared/DoreTopBar';
import {getUser} from '../../../../services/storageService';
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import DoreButton from "../../../shared/DoreButton";
import {Grid} from "@material-ui/core";
import DoreProfilePanel from "../../../shared/DoreProfilePanel";
import NumberOfAllowedTurns from "../../../shared/subscriber/NumberOfAllowedTurns";
import AdditionalSubscribers from "../../../shared/subscriber/AdditionalSubscribers";
import {translate2} from "../../../../services/translateService";


const useStyles = makeStyles((theme) => ({
    root: {},

}));

const EnterpriseSubscriberProfile = ({match, loading, breadCrumb}) => {

    const {enterpriseId} = getUser();
    let {params: {subscriberId}} = match;
    subscriberId = subscriberId.split('_')[0];
    const [subscriber, setSubscriber] = useState();
    const [userInfoMap, setUserInfoMap] = useState();
    const [numOfAllowedTurns, setNumOfAllowedTurns] = useState();

    const classes = useStyles();
    const [_status, setStatus] = useState({...SUBSCRIPTION_STATUS});
    const [customers, setCustomers] = useState();
    const [refresh, setRefresh] = useState(false);

    const refreshPage = () => {
        setRefresh(prevState => !prevState)
    }
    // const [minBooks, setMinBooks] = useState({
    //     1: {label: 'يوم واحد', checked: false},
    //     3: {label: 'ثلاثة ايام', checked: false},
    //     7: {label: 'سبعة ايام', checked: false},
    // });


    const getCheckedValue = (obj) => (
        Object.entries(obj).find(entry => entry[1].checked === true)[0]
    );

    useEffect(() => {
        (async () => {
            const sub = await gc(enterpriseId, subscriberId);
            if (sub) {
                setSubscriber(sub);
                setNumOfAllowedTurns(sub.numOfAllowedTurns);
                setCustomers(sub.customersNames);
                setUserInfoMap({
                    name: {label: `${translate2("name")}: `, value: sub.userInfo.name},
                    phoneNumber: {label: `${translate2("phoneNumber")}: `, value: sub.userInfo.phoneNumber},
                    city: {label: `${translate2("city")}: `, value: sub.userInfo.address && sub.userInfo.address.city},
                    birthDate: {
                        label: `${translate2("birthDate")}: `,
                        value: sub.userInfo.birthDate && changeDateFormat(sub.userInfo.birthDate)
                    },
                    age: {
                        label: `${translate2("age")}: `,
                        value: sub.userInfo.birthDate && calculateAge(sub.userInfo.birthDate)
                    },
                });

                const {status, minPeriodBetweenBookings} = sub;
                checkByKey(status, _status, setStatus);
                // checkByKey(minPeriodBetweenBookings, minBooks, setMinBooks);
            }
        })();
    }, [refresh]);

    const updateConsumerInfo = async () => {
        const subscriberInfo = {...subscriber};
        subscriberInfo.customersNames = customers;
        // subscriberInfo.minPeriodBetweenBookings = getCheckedValue(minBooks);
        subscriberInfo.status = getCheckedValue(_status);
        subscriberInfo.numOfAllowedTurns = numOfAllowedTurns;
        if (await updateConsumer(enterpriseId, subscriberId, subscriberInfo)) {
            refreshPage()
        }
    };

    const onChangeNumOfTurns = async () => {
        setNumOfAllowedTurns(subscriber.changeRequests["requestedNumOfTurns"])
        const subscriberInfo = {
            "changeRequests": {
                "requestedNumOfTurns": subscriber.changeRequests["requestedNumOfTurns"]
            }
        };
        if (await updateConsumer(enterpriseId, subscriberId, subscriberInfo)) {
            refreshPage()
        }
    }
    const onCancelChangeNumOfTurns = async () => {
        const subscriberInfo = {
            "changeRequests": {
                "requestedNumOfTurns": subscriber.numOfAllowedTurns
            }
        };
        if (await updateConsumer(enterpriseId, subscriberId, subscriberInfo)) {
            refreshPage()
        }
    }

    return (
        <div className={classes.root}>
            <DoreTopBar loading={loading}/>
            {subscriber && subscriber.changeRequests &&
            <Collapse in={true}>
                {subscriber.changeRequests["requestedNumOfTurns"] &&
                <div>
                    <Alert
                        severity="info"
                    >
                        {translate2("customerChangeRequestPreMsg")} {subscriber.changeRequests["requestedNumOfTurns"]}
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item className={classes.sameWidth}>
                                    <DoreButton
                                        loading={loading}
                                        label={translate2("cancel")}
                                        icon={faTimesCircle}
                                        variant="outlined"
                                        color="default"
                                        onClick={onCancelChangeNumOfTurns}
                                    />
                                </Grid>
                                <Grid item className={classes.sameWidth}>
                                    <DoreButton
                                        loading={loading}
                                        label={translate2("ok")}
                                        icon={faCheckCircle}
                                        onClick={onChangeNumOfTurns}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Alert>
                </div>}
            </Collapse>
            }

            <DoreProfilePanel
                title={translate2("customerDetails")}
                icon='perm_contact_calendar'
                infoObject={userInfoMap}
                panelInitialExpanded={false}
            />
            <DoreExpansionPanel title={translate2("status")} icon="rowing" initialExpanded={true}>
                <div style={{
                    paddingBottom: '1rem',
                }}>
                    {Object.keys(_status).map(k => (
                        <DoreChip
                            key={k}
                            label={_status[k].label}
                            icon={_status[k].icon}
                            checked={_status[k].checked}
                            onClick={() => checkByKey(k, _status, setStatus)}
                        />
                    ))}
                </div>
            </DoreExpansionPanel>
            <AdditionalSubscribers
                initSubscribers={subscriber && subscriber.customersNames}
                loading={loading}
                onChange={(v) => setCustomers([...v])}
            />
            <NumberOfAllowedTurns
                requestedNumOfTurns={subscriber && subscriber.changeRequests && subscriber.changeRequests["requestedNumOfTurns"]}
                initNumOfAllowedTurns={subscriber && subscriber.numOfAllowedTurns}
                onChange={k => setNumOfAllowedTurns(k)}
            />
            {/*<DoreExpansionPanel title="اقل مدة بين الحجوزات" icon="forward_30">*/}
            {/*    <div style={{*/}
            {/*        paddingBottom: '1rem',*/}
            {/*    }}>*/}
            {/*        {Object.keys(minBooks).map(k => (*/}
            {/*            <DoreChip*/}
            {/*                key={k}*/}
            {/*                label={minBooks[k].label}*/}
            {/*                checked={minBooks[k].checked}*/}
            {/*                onClick={() => checkByKey(k, minBooks, setMinBooks)}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</DoreExpansionPanel>*/}
            <DoreFab onClick={updateConsumerInfo} inout={true} icon="save" loading={loading}/>
        </div>
    );
};

export default connect(state => ({
    loading: state.loading,
    breadCrumb: state.breadCrumb,
    currentLanguage: state.currentLanguage,
}))(EnterpriseSubscriberProfile);
