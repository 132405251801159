import React from 'react';
import {Icon, TextField} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {INPUT_HEIGHT, INPUT_PADDING_TOP, SECOND_COLOR_A} from '../../styles';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Styles
 */
const useStyles = makeStyles({
  root: ({ isBtn, bottomSpace }) => ({
    marginBottom: bottomSpace && '1rem',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      color: SECOND_COLOR_A,
      height: INPUT_HEIGHT,
      boxSizing: 'border-box',
      paddingTop: INPUT_PADDING_TOP,
      cursor: isBtn && 'pointer',
    },
  }),
  inputFieldSkeleton: {
    paddingTop: INPUT_PADDING_TOP,
    height: INPUT_HEIGHT,
    boxSizing: 'border-box',
    borderRadius: '0.35rem',
  }
});

export const DInputFieldSkeleton = () => {
  const classes = useStyles();
  return (
    <div>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.inputFieldSkeleton}
      />
    </div>
  );
};

//TODO: Should use/pass <DoreIcon size={30} loading={loading}>{icon}</DoreIcon>
const DInputField = (
  {
    loading,
    bottomSpace = true,
    type = 'text',
    variant = 'outlined',
    label,
    disabled = false,
    value,
    onChange,
    onClick,
    icon,
    iconColor = SECOND_COLOR_A
  }
) => {
  const classes = useStyles({ isBtn: onClick && true, bottomSpace });
  return (
    <TextField
      fullWidth
      className={classes.root}
      type={type}
      variant={variant}
      label={label}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onClick={onClick}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon style={{ color: iconColor }}>{icon}</Icon>
          </InputAdornment>
        )
      }}
    />
  )
};

/**
 * The Doretou Input Field base component
 * When any component need a input field should use this component
 *
 * @returns React component
 * @constructor
 */
const DoreInputField = (props) => (
  <div>
    {props.loading.skeleton && <DInputFieldSkeleton />}
    {!props.loading.skeleton && <DInputField {...props} />}
  </div>
);

export default DoreInputField;
