import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    createNewManagerEnterpriseServiceType,
    deleteManagerEnterpriseServiceTypeById,
    getManagerEnterpriseServicesTypes,
    updateManagerEnterpriseServiceTypeVersion
} from "../../../../../services/dataService";
import DoreList from "../../../../shared/DoreList";
import {goToEnterpriseServiceTypePage, goToEnterpriseServiceTypeVersionPage} from "../../../../../services/pageService";
import {getUser, mapAndSave, SERVICES_KEY} from "../../../../../services/storageService";
import {Portal} from "@material-ui/core";
import DoreFab from "../../../../shared/DoreFab";
import {openConfirmDialog} from "../../../../../services/confirmDialogService";
import {faBriefcase, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import NewServiceType from "./AddNewServiceTypeForm";
import DoreTopBar from "../../../../shared/DoreTopBar";
import {ENTERPRISE_STATUSES} from "../../../../../utils/consts";
import {translate2} from "../../../../../services/translateService";
import NewServiceProviderForNewEnterprise from "../serviceProvider/AddNewServiceProviderForNewEnterprise";

const EnterpriseServicesProfile = ({
                                       isFirstCreate = false,
                                       loading,
                                       isToShowTopBar = true,
                                       onChangeServices
                                   }) => {


    const {enterpriseId, enterpriseStatus} = getUser();
    const [services, setServices] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [addIcon, setAddICon] = useState(true);
    let deletePressed = false;
    let editSPsPressed = false;

    const refreshPage = () => {
        setRefresh(prevState => !prevState)
        setAddICon(true)
    }

    useEffect(() => {
        onChangeServices && onChangeServices(services)
    }, [services])

    useEffect(() => {
        (async () => {
            if (enterpriseId) {
                const servicesArr = await getManagerEnterpriseServicesTypes(enterpriseId);
                if (servicesArr) {
                    setServices(servicesArr.map((service) => ({
                        id: service.id,
                        title: service.name,
                        avatar: true,
                        subTitle: service.description,
                        effectiveDate: service.versions && service.versions[0] && service.versions[0].effectiveDate,
                        expirationDate: service.versions && service.versions[0] && service.versions[0].expirationDate,
                        duration: service.versions && service.versions[0] && service.versions[0].duration,
                        daysToShow: service.daysToShow
                    })));
                    mapAndSave(SERVICES_KEY, servicesArr);
                }
            }
        })();
    }, [refresh]);

    const onEditServiceProvidersClick = (serviceType) => {
        setAddICon(false)
        let titleInfo = translate2("setTurnInfoPerServiceProvider")
        let subTitleInfo = translate2("setTurnInfoPerServiceProviderExample")
        openConfirmDialog(faEdit, titleInfo, subTitleInfo,
            <NewServiceProviderForNewEnterprise serviceType={serviceType}/>,
            () => {
                editSPsPressed = false;
                refreshPage()
            }, () => {
                editSPsPressed = false
                setAddICon(true)
            },
            true,
            true);
    }

    const onServiceClick = (serviceType) => {
        if (deletePressed) {
            let titleInfo = translate2("areYouSureYouWantToDeleteService") + ' "' + serviceType.title + '" ?'
            let subTitleInfo = ''
            openConfirmDialog(faTrash, titleInfo, subTitleInfo,
                <div></div>,
                async () => {
                    deleteManagerEnterpriseServiceTypeById(enterpriseId, serviceType.id).then(r => {
                        deletePressed = false
                        refreshPage()
                    })
                }, () => {
                    deletePressed = false
                });
        } else if (editSPsPressed) {
            onEditServiceProvidersClick(serviceType);
        } else if (enterpriseStatus === ENTERPRISE_STATUSES.NEW) {
            createOrUpdateServiceType(serviceType)
        } else {
            goToEnterpriseServiceTypePage(serviceType.id);
        }
    }

    const createOrUpdateServiceType = (serviceType) => {
        setAddICon(false)
        let newServiceTypeName = serviceType && serviceType.title || "";
        let newServiceTypeDesc = serviceType && serviceType.subTitle || "";
        let newServiceTypeEffectiveDate = serviceType && serviceType.effectiveDate || "";
        let newServiceTypeExpirationDate = serviceType && serviceType.expirationDate || "";
        let newServiceTypeDuration = (serviceType && serviceType.duration) || 20;
        let newServiceTypeDaysToShow = (serviceType && serviceType.daysToShow) || 7;
        let titleInfo = `${translate2("newService")}:`
        let subTitleInfo = ''
        let serviceTypeId = serviceType && serviceType.id
        openConfirmDialog(faBriefcase, titleInfo, subTitleInfo,
            <NewServiceType loading={loading}
                            inputName={newServiceTypeName} onChangeName={(name) => newServiceTypeName = name}
                            inputDesc={newServiceTypeDesc} onChangeDescription={(desc) => newServiceTypeDesc = desc}
                            inputEffDate={newServiceTypeEffectiveDate}
                            onChangeEffectiveDate={(date) => newServiceTypeEffectiveDate = date}
                            inputExpDate={newServiceTypeExpirationDate}
                            onChangeExpirationDate={(date) => newServiceTypeExpirationDate = date}
                            inputDuration={newServiceTypeDuration}
                            onChangeDuration={(num) => newServiceTypeDuration = num}
                            inputDaysToShow={newServiceTypeDaysToShow}
                            onChangeDaysToShow={(num) => newServiceTypeDaysToShow = num}
            />,
            async () => {
                let body = {
                    "name": newServiceTypeName,
                    "description": newServiceTypeDesc,
                    "effectiveDate": newServiceTypeEffectiveDate,
                    "expirationDate": newServiceTypeExpirationDate,
                    "duration": newServiceTypeDuration,
                    "daysToShow": newServiceTypeDaysToShow
                }
                serviceTypeId
                    ? updateManagerEnterpriseServiceTypeVersion(enterpriseId, serviceTypeId, 0, body).then(refreshPage)
                    : createNewManagerEnterpriseServiceType(enterpriseId, body).then((r) => {
                        if(enterpriseStatus === ENTERPRISE_STATUSES.NEW) {
                            refreshPage();
                        }else{
                            goToEnterpriseServiceTypeVersionPage(r.serviceTypeId,0);
                        }
                    });
            },
            () => {
                setAddICon(true)
            });
    };

    return (
        <div>
            {isToShowTopBar && <DoreTopBar loading={loading}/>}
            <DoreList
                list={services}
                loading={loading}
                onClick={onServiceClick}
                onDelete={() => {
                    deletePressed = true;
                }}
                additionAction={isFirstCreate &&
                {
                    onClick: () => {
                        editSPsPressed = true
                    },
                    icon: "assignment_ind"
                }}
            />
            <Portal container={document.body}>
                {
                    <DoreFab
                        color="primary"
                        onClick={createOrUpdateServiceType}
                        inout={addIcon}
                        icon="add"
                        loading={true}
                        variant="extended"
                        label={translate2("addNewService")}
                    />
                }
            </Portal>
        </div>
    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseServicesProfile);
