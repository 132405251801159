import React from 'react';
import {connect} from 'react-redux';
import DoreIcon from '../shared/DoreIcon';
import DoreForm from '../shared/DoreForm';
import {ERROR_COLOR_A} from '../../styles';

export const ERROR_HAPPENED = 'ERROR_HAPPENED';

// TODO: under implementation
const SomethingHappened = ({ title, subTitle, type, currentLanguage, loading }) => {

  const types = {
    ERROR_HAPPENED: { icon: 'error', color: 'secondary' },
  };

  return (
    <DoreForm
      loading={loading}
      title={title}
      subTitle={subTitle}
      logo={<DoreIcon size="5rem" color={ERROR_COLOR_A} loading={loading}>{types[type].icon}</DoreIcon>}
    >
    </DoreForm>
  );
};

export default connect(state => ({
  loading: state.loading,
  currentLanguage: state.currentLanguage
}))(SomethingHappened);
