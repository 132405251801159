import React, {useEffect, useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Icon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {MAIN_COLOR_C} from '../../styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import wavesImg from "../../imgs/waves-upper-left-small.svg";
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';
import DoreIcon from './DoreIcon';
import Typography from '@material-ui/core/Typography';
import {grey} from '@material-ui/core/colors';
import useWindowSize from '../../hooks/useWindowSize';
import {selectByKey} from '../../utils/utils';
import history from '../../history';
import {translate2} from "../../services/translateService";
import {getUser} from "../../services/storageService";

const MENU_BAR_FIXED_WIDTH = '18rem';

/**
 * Component styles
 */
const useStyles = makeStyles({
  root: {
    '& .MuiList-padding': {
      padding: '0 !important',
      margin: '0 !important',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'white',
      width: MENU_BAR_FIXED_WIDTH,
      color: grey[700],
    },
    '& .MuiListItem-root': {
      paddingTop: '1.1rem',
      paddingBottom: '1rem',
    }
  },
  avatar: {
    position: 'absolute',
    top: '30px',
    right: '100px',
    fontSize: '6rem',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '0.1rem',
    color: MAIN_COLOR_C,
  },
});

/**
 * Doretou menu-bar component
 * TODO: need to do the color more generic, also maybe do some styles.
 */
const DoreMenuBar = ({ anchor = 'left', open, onClose, menu }) => {
  const user = getUser();
  const classes = useStyles();
  const size = useWindowSize();
  const [items, setItems] = useState({ ...menu });
  const [drawerVariant, setDrawerVariant] = useState('permanent'); // || temporary

  useEffect(() => {
    const p = history.location.pathname.split('/');
    const mi = { ...items };
    if (mi[p[p.length - 1]]) {
      mi[p[p.length - 1]].selected = true;
      setItems(mi);
    }
  }, []);

  // history.listen((location, action) => { // TODO: Need to handle when change without re-render, also in inner route
  //   const p = location.pathname.split('/');
  //   const mi = { ...items };
  //   if (mi[p[p.length - 1]]) {
  //     mi[p[p.length - 1]].selected = true;
  //     setItems(mi);
  //   }
  // });

  useEffect(() => {
    if (size.width <= 800) {
      setDrawerVariant('temporary');
      document.body.style.marginRight = '0';
    } else {
      setDrawerVariant('permanent');
      document.body.style.marginRight = MENU_BAR_FIXED_WIDTH;
    }
  }, [size]);

  const onClick = (item, key) => {
    if (!item || !item.onClick) return;
    selectByKey(items, setItems, key);
    item.onClick();
    onClose();
  };

  return (
    <Drawer className={classes.root} anchor={anchor} open={open} onClose={onClose} variant={drawerVariant}>
      <div role="presentation">
        <div style={{ position: 'relative', }}>
          <img src={wavesImg} alt="waves svg" />
          <DoreIcon
            loading={{}}
            clazz={classes.avatar}
          >
            {faUserCircle}
          </DoreIcon>
          <Typography variant="body1" align="center"
                      style={{ transform: 'translate(8px, -35px)', color: MAIN_COLOR_C }}>
            {`${translate2("HelloThere")} ${user && user.userLocalName}`}
          </Typography>
        </div>
        <List>
          {Object.keys(items).map(k => (
            <div key={k}>
              <ListItem button onClick={() => onClick(items[k], k)} selected={items[k].selected}>
                { //TODO: TEMP CODE: Need to be handled from DoreIcon.js component, need to use it as generic icon!!
                  typeof items[k].icon === 'string' ?
                    <Icon style={{ marginLeft: '10px', color: MAIN_COLOR_C }}>{items[k].icon}</Icon> :
                    <FontAwesomeIcon style={{ marginLeft: '10px', color: MAIN_COLOR_C }} icon={items[k].icon} />
                }
                <ListItemText primary={items[k].text} />
              </ListItem>
            </div>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default DoreMenuBar;
