//DoreExpansionPanel

// {,…}
// subscriberInfoList: [{userInfo: {name: "mohammad", id: "47", phoneNumber: "0538322884", email: "", gender: "MALE",…},…},…]
// 0: {userInfo: {name: "mohammad", id: "47", phoneNumber: "0538322884", email: "", gender: "MALE",…},…}
// userInfo: {name: "mohammad", id: "47", phoneNumber: "0538322884", email: "", gender: "MALE",…}
// name: "mohammad"
// id: "47"
// phoneNumber: "0538322884"
// email: ""
// gender: "MALE"
// address: {addressDM: null, city: "musherfa", country: null, postCode: null,…}
// addressDM: null
// city: "musherfa"
// country: null
// postCode: null
// gbsLocation: {latitude: 0, longitude: 0}
// customersNames: ["عماد ابو رازي", "محمد", "رازي", "رازي", "رازي", "رازي"]
// 0: "عماد ابو رازي"
// 1: "محمد"
// 2: "رازي"
// 3: "رازي"
// 4: "رازي"
// 5: "رازي"
// periodUnit: "DAY"
// minPeriodBetweenBookings: 7
// numOfAllowedTurns: 10000
// status: "ACTIVE"
// subscriberAdditionalInfo: {TOWN: "مشيرفة"}
// TOWN: "مشيرفة"
// 1: {,…}
// 2: {userInfo: {name: "محمود", id: "578", phoneNumber: "0525059754", email: null, gender: "",…},…}
// 3: {userInfo: {name: "ddddd", id: "581", phoneNumber: "0525059733", email: null, gender: "",…},…}

import React, {useState} from 'react';
import {connect} from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {Icon} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DoreSkeleton, {DORE_SKELETON_VARIANT} from './DoreSkeleton';
import {SECOND_COLOR_A} from '../../styles';

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0.5rem 0 0.5rem 0',
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '66%',
    flexShrink: 0,
    color: grey[600],
    marginLeft: '0.5rem',
    marginTop: '0.21rem',
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

/**
 * Dore Expansion Panel
 *
 * @author Mohammad
 */
const DoreExpansionPanel = ({ title, subTitle, icon, children, initialExpanded = false, loading, iconColor = SECOND_COLOR_A, forceCancelLoading , disableExpansion=false}) => {

  // State & style
  const classes = useStyles();
  const [expanded, setExpanded] = useState(initialExpanded);
  if (forceCancelLoading) loading.skeleton = false; //TODO: Temp solution, need to update the whole mechanism!!!

  return (
    <>
      {
        !loading.skeleton && <Accordion
          className={classes.root}
          expanded={expanded}
          onChange={() => !disableExpansion ? setExpanded(!expanded) : null}
        >
          <AccordionSummary
            expandIcon={!disableExpansion && <Icon>expand_more_icon</Icon>}
          >
            { // TODO: TEMP CODE: Need to be handled from DoreIcon.js component, need to use it as generic icon!!
              // Also need to create core shred Typography generic component for text used
              typeof icon === 'string' ?
                <Icon style={{ color: iconColor }}>{icon}</Icon> :
                <FontAwesomeIcon style={{ color: iconColor }} icon={icon} />
            }
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subTitle}>{subTitle}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{
            display: 'block',
            padding: '0 1rem 0 1rem',
          }}>
            {children}
          </AccordionDetails>
        </Accordion>
      }
      { //TODO: temp code, need to be deleted and make every core component have its own skeleton
        loading.skeleton && <Accordion
          className={classes.root}
        >
          <AccordionSummary
            expandIcon={<Icon><DoreSkeleton variant={DORE_SKELETON_VARIANT.CIRCLE} component="div" width={25}
                                            height={25} /></Icon>}
          >
            <DoreSkeleton center={false} variant={DORE_SKELETON_VARIANT.CIRCLE} component="div" width={25}
                          height={25} />
            <div style={{ width: '1rem' }}></div>
            <DoreSkeleton center={false} component="div" width={150} height={30} />
          </AccordionSummary>
          <AccordionDetails style={{
            display: 'block',
            padding: '0 1rem 0 1rem',
          }}>
            <span></span>
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
};

export default connect(state => ({
  loading: state.loading,
}))(DoreExpansionPanel);
