import React, {useEffect, useState} from "react";
import NewServiceProvider from "./AddNewServiceProviderForm";
import {getUser} from "../../../../../services/storageService";
import {
    addManagerEnterpriseServiceProvider,
    deleteManagerEnterpriseServiceProviderById,
    getEnterpriseServiceProviders,
    updateManagerEnterpriseServiceProvider
} from "../../../../../services/dataService";
import DoreChip from "../../../../shared/DoreChip";
import {translate2} from "../../../../../services/translateService";
import DoreConfirmButtons from "../../../../shared/utils/DoreConfirmButtons";
import {connect} from "react-redux";


const NewServiceProviderForNewEnterprise = ({
                                                loading, serviceType
                                            }) => {
    const {enterpriseId} = getUser();
    const [newSPDaysToShow, setNewSPDaysToShow] = useState()
    const [newSPId, setNewSPId] = useState()
    const [newSPDuration, setNewSPDuration] = useState()
    const [providers, setProviders] = useState([]);
    const [addNewSPInd, setAddNewSPInd] = useState(false);
    const [editSP, setEditSP] = useState(null);
    const [allEmployeesAdded, setAllEmployeesAdded] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const refreshPage = () => {
        setRefresh(prevState => !prevState)
    }

    useEffect(() => {
        if (serviceType) {
            serviceType.daysToShow &&
            setNewSPDaysToShow(serviceType.daysToShow)
            serviceType.duration &&
            setNewSPDuration(serviceType.duration);

            (async () => {
                if (enterpriseId) {
                    const serviceProviders = await getEnterpriseServiceProviders(enterpriseId, serviceType.id);
                    if (serviceProviders) {
                        setProviders(serviceProviders);
                    }
                }
            })();

        }
    }, [serviceType, addNewSPInd, editSP, refresh])

    useEffect(() => {
        if (editSP) {
            setNewSPDuration(editSP.duration)
        }
    }, [editSP])


    const add = () => {
        setAddNewSPInd(true)
        setEditSP(null)
    };

    const onChangeSP = (id) => {
        setNewSPId(id)
    };


    const removeSP = async (spId) => {
        await deleteManagerEnterpriseServiceProviderById(enterpriseId, serviceType.id, spId, 0).then(r => {
            refreshPage();
            setAddNewSPInd(false);
            setEditSP(null)
        });

    };

    return (
        <div>
            {serviceType && <div>

                <div style={{
                    paddingBottom: '1rem',
                }}>
                    {providers && providers.length > 0 && providers.map((provider, index) => (
                        <DoreChip
                            key={provider.id}
                            label={provider.name}
                            checked={provider.checked}
                            onClick={() => {
                                setEditSP(provider)
                            }}
                        />
                    ))}
                    <DoreChip
                        label={translate2("newServiceProvider")}
                        icon="add_circle"
                        checked={true}
                        onClick={add}
                    />

                </div>
                <br/>
                {(addNewSPInd || editSP) &&
                <div>
                    <NewServiceProvider editSP={editSP}
                                        enterpriseId={enterpriseId}
                                        onChangeEmployees={(employees) => setAllEmployeesAdded(!employees || employees.length === 0)}
                                        refresh={addNewSPInd}
                                        inputDuration={newSPDuration}
                                        onChangeDuration={(duration) => setNewSPDuration(duration)}
                                        inputDaysToShow={newSPDaysToShow}
                                        onChangeDaysToShow={(days) => setNewSPDaysToShow(days)}
                                        onChangeSP={onChangeSP}
                                        serviceTypeId={serviceType.id}
                                        versionId={0}
                    />
                    {(editSP || !allEmployeesAdded) &&
                    <DoreConfirmButtons
                        showCancel={true}
                        showConfirm={true}
                        showDelete={editSP}
                        confirmButtonText={translate2("save")}
                        onCancel={() => {
                            setAddNewSPInd(false)
                            setEditSP(null)
                        }}
                        onConfirm={
                            () => {
                                editSP ? updateManagerEnterpriseServiceProvider(enterpriseId, serviceType.id, 0, editSP.id, newSPDuration).then(
                                    r => {
                                        setAddNewSPInd(false)
                                        setEditSP(null)
                                    })
                                    :
                                    addManagerEnterpriseServiceProvider(enterpriseId, serviceType.id, 0, newSPId, newSPDuration)
                                        .then(r => {
                                            setAddNewSPInd(false)
                                            setEditSP(null)
                                        });
                            }
                        }
                        onDelete={() => {
                            if (editSP)
                                removeSP(editSP.id).then(r => {
                                    console.log("deleted ", editSP.id)
                                    setAddNewSPInd(false)
                                    setEditSP(null)
                                })
                        }}
                    />}

                </div>
                }


            </div>
            }
        </div>
    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewServiceProviderForNewEnterprise);
