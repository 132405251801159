import React, {useEffect, useState} from "react";
import DoreSingleSelect from "../../../../shared/DoreSingleSelect";
import {connect} from "react-redux";
import {getManagerEnterpriseEmployees} from "../../../../../services/dataService";
import DoreInputField from "../../../../shared/DoreInputField";
import {translate2} from "../../../../../services/translateService";

const NewServiceProvider = ({
                                loading,
                                editSP,
                                onChangeEmployees,
                                enterpriseId,
                                inputDuration, onChangeDuration,
                                inputDaysToShow, onChangeDaysToShow,
                                onChangeSP,
                                serviceTypeId,
                                versionId
                            }) => {
    const [open, setOpen] = React.useState(false);
    const [employees, setEmployees] = useState();
    const [employeeId, setEmployeeId] = useState(null);
    const [duration, setDuration] = useState('');
    const [daysToShow, setDaysToShow] = useState();
    const [noEmployeesInd, setNoEmployeesInd] = useState(false);

    useEffect(() => {
        inputDuration && setDuration(inputDuration)
    }, [inputDuration])

    useEffect(() => {
        editSP && editSP.duration && setDuration(editSP.duration)
    }, [editSP])

    useEffect(() => {
        inputDaysToShow && setDaysToShow(inputDaysToShow)
    }, [inputDaysToShow])

    useEffect(() => {
        (async () => {
            const employeesArr = await getManagerEnterpriseEmployees(enterpriseId, serviceTypeId, versionId);
            if (employeesArr) {
                setEmployees(employeesArr.map((emp) => ({
                    id: emp.id,
                    name: emp.displayName,
                    value: emp.id,
                    role: emp.role,
                })));
                employeesArr.length == 0 && setNoEmployeesInd(true)
                onChangeEmployees && onChangeEmployees(employeesArr);
            } else {
                setNoEmployeesInd(true);
            }
        })();
    }, []);

    useEffect(() => {
        employees && employees.length > 0 && setEmployeeId(employees[0].id)
    }, [employees]);

    useEffect(() => {
        onChangeSP && onChangeSP(employeeId)
    }, [employeeId]);

    const handleChangeSP = (event) => {
        let spId = event.target.value;
        setEmployeeId(spId);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    // const [selectedOldVersion, setSelectedOldVersion] = useState(0);


    const noMoreEmployees = <>{noEmployeesInd && !editSP &&
    <div style={
        {color: 'red'}
    }>
        {translate2("noEmployeesAvailable")}
    </div>}</>;


    const employeesList = <div> {!editSP && employeeId && <DoreSingleSelect
        value={employeeId}
        title={translate2("serviceProvider")}
        onChange={handleChangeSP}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        id="new-service-provider-id"
        items=
            {
                employees.map((employee) => (
                    {
                        value: employee.id,
                        name: employee.name,
                    }
                ))

            }

    />}</div>;

    const editSPInfo =
        <div>{editSP &&
        <div>
            <label>{translate2("serviceProvider") + ': '}</label>
            <strong>{editSP.name}</strong>
        </div>}
        </div>;

    return (
        <div>
            {noMoreEmployees}

            {(editSP || (employees && employees.length > 0)) &&
            <div>
                {employeesList}
                {editSPInfo}
                <br/>
                <br/>
                <DoreInputField
                    bottomSpace={false}
                    loading={{}}
                    label={translate2("durationInMin")}
                    icon="time"
                    type="number"
                    min="0"
                    value={duration}
                    onChange={e => {
                        const newDuration = e.target.value;
                        let r = parseInt(newDuration);
                        if (r > 0) {
                            setDuration("" + r)
                            onChangeDuration && onChangeDuration("" + r)
                        }
                    }}
                />
                <br/>
                <br/>
            </div>}
        </div>
    );
};


export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewServiceProvider);
