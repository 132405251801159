import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DoreTopBar from '../../../shared/DoreTopBar';
import {makeStyles} from '@material-ui/core/styles';
import FilterMenu from '../../../shared/FilterMenu';
import DoreList from '../../../shared/DoreList';
import DoreExpansionPanel from '../../../shared/DoreExpansionPanel';
import DoreChip from '../../../shared/DoreChip';
import {
    addEnterpriseVacations,
    deleteEnterpriseVacations,
    getManagerEnterpriseEmployees,
    getEnterpriseServiceProviders,
    getEnterpriseServices,
    getPagedEnterpriseVacations,
} from '../../../../services/dataService';
import {getUser} from '../../../../services/storageService';
import DoreFab from "../../../shared/DoreFab";
import AddNewVacation, {SELECT_ALL_ID} from "./AddNewVacationForm";
import {ERROR_COLOR_C} from "../../../../styles";
import DoreEmptyPage from "../../../shared/DoreEmptyPage";
import {switchIcon} from "../../../../utils/utils";
import {DEFAULT_PAGE_SIZE, ENTERPRISE_STATUSES, unCheckedIcon} from "../../../../utils/consts";
import DorePagination from "../../../shared/paging/DorePagination";
import {goToEnterpriseProfilePage} from "../../../../services/pageService";
import {openConfirmDialog} from "../../../../services/confirmDialogService";
import {faUmbrellaBeach} from "@fortawesome/free-solid-svg-icons";
import {translate2} from "../../../../services/translateService";

const useStyles = makeStyles(theme => ({
    list: {
        "& .MuiListItem-root": {
            display: 'block',
            paddingBottom: 0,
        }
    },
}));

// TODO: instead of showing the filters with the same page, create a filter menu like the menubar

// Check By Index
const checkByIndex = (index, state, setter) => { // TODO: remove and use utils one selectByIndex - should change checked to selected
    const copy = [...state];
    copy.map(c => c.checked = false);
    copy[index].checked = true;
    setter(copy);
};


/**
 * Manager Vacations Component
 *
 * @author Ahmad
 */
const EnterpriseVacations = ({loading}) => {

    // CSS classes
    const classes = useStyles();

    const [numOfPages, setNumOfPages] = useState(0)
    const [numOfElementInPage, setNumOfElementInPage] = useState(0)
    const [selectedPage, setSelectedPage] = useState(0);
    const {enterpriseId,enterpriseStatus} = getUser();

    // Pooling and filter menu state
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [pollingCounter, setPollingCounter] = useState(0);
    const [refresh, setRefresh] = useState(false);

    const [noData, setNoData] = useState(false);
    const [employeeNames, setEmployeeNames] = useState([]);
    const [servicesNames, setServicesNames] = useState([]);

    // Vacation state
    const [vacations, setVacations] = useState([]);
    const [selectedVacations, setSelectedVacations] = useState([]);

    //  services, providers, and dates state
    const [services, setServices] = useState([]);
    const [providers, setProviders] = useState([]);
    const [employees, setEmployees] = useState([]);

    // ****
    const getSelected = (arr) => arr && arr.length > 0 && arr.find(s => s.checked === true);

    // ****
    const setState = async (state, setState) => {
        // Mapping for async calls
        if (enterpriseId) {
            const mapping = {
                services: async () => await getEnterpriseServices(enterpriseId),
                providers: async () => await getEnterpriseServiceProviders(enterpriseId, getSelected(services).id),
                employees: async () => await getManagerEnterpriseEmployees(enterpriseId),
            };

            // Apply async call
            const res = await mapping[state]();
            if (res) {
                const current = res;
                if (current && current[0])
                    current[0].checked = true;
                setState(current);
            }
        }
    };

    useEffect(() => {
        if (!enterpriseId || enterpriseStatus === ENTERPRISE_STATUSES.NEW) {
            goToEnterpriseProfilePage()
        }
    }, [])

    const refreshPage = () => {
        setRefresh((prevState => !prevState))
    }

    useEffect(() => {
        if (selectedVacations.length > 0) {
            setAddIcon(false);
            setDeleteIcon(true);
        } else {
            setDeleteIcon(false);
            setAddIcon(true);
            refreshPage();
        }
        ;
    }, [selectedVacations]);


    // Effect runs once - as constructor
    useEffect(() => {
        setState('services', setServices);
        setState('employees', setEmployees);
    }, []);

    useEffect(() => {
        if (getSelected(services)) setState('providers', setProviders);
    }, [services]);


    useEffect(() => {
        let namesMaps = [];
        for (const service of services) {
            namesMaps[service.id] = service.name;
        }
        setServicesNames(namesMaps);

    }, [services]);


    useEffect(() => {

        let namesMaps = [];
        for (const employee of employees) {
            namesMaps[employee.id] = employee.displayName || employee.name;
        }
        setEmployeeNames(namesMaps);

    }, [employees]);


    const dateTimeFormat = new Intl.DateTimeFormat('ar-TN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });


    // Effect runs when pooling
    useEffect(() => {
        (async () => {
            // Just if the filters is set, get new data
            if (enterpriseId && getSelected(services) && getSelected(providers)) {
                const res = await getPagedEnterpriseVacations(enterpriseId, selectedPage, DEFAULT_PAGE_SIZE);
                let vacations = res.pageBody ? res.pageBody : res;
                setNumOfPages(res.totalPageNumber ? res.totalPageNumber : 0);
                setNumOfElementInPage(res.numberOfResults ? res.numberOfResults : 0);
                //, getSelected(services).id, getSelected(providers).id, getSelected(dates).name
                if (vacations) {
                    if (vacations.length == 0) {
                        setNoData(true);
                        setVacations([]);
                    } else {
                        setNoData(false);
                        setVacations(vacations && vacations.map(vacation => {
                            let startDate = new Date(vacation.startDateTime);
                            let endDate = new Date(vacation.endDateTime);
                            return ({
                                id: vacation.vacationId,
                                title: dateTimeFormat.format(startDate) + " - " + dateTimeFormat.format(endDate),
                                // icon: 'calendar_today',
                                icon: unCheckedIcon,
                                //iconText: startDate.getDate(),
                                selected: vacations[vacation],
                                subTitle: getVacationSubTitle(vacation),
                                description: vacation.description,
                            });
                        }));
                    }
                }
            }
        })();


        const getVacationSubTitle = (vacation) => {
            let employeeName = employeeNames[vacation.serviceProviderId] || translate2("allServicesProviders");
            let serviceName = servicesNames[vacation.serviceTypeId] || translate2("allServices");
            let subTitle = employeeName + " - " + serviceName;
            return subTitle;
        }

        // pool data every 1 min
        const oneMin = 60000;
        const pollingTimerId = setTimeout(() => setPollingCounter(pollingCounter + 1), oneMin * 5);

        // clean-up
        return function cleanup() {
            clearTimeout(pollingTimerId);
        };
    }, [services, providers, pollingCounter, refresh, selectedPage]);

    const updateService = async (index) => {
        checkByIndex(index, services, setServices);
    };

    const updateProvider = async (index) => {
        checkByIndex(index, providers, setProviders);
    };

    const [deleteIcon, setDeleteIcon] = useState(false);

    const [addIcon, setAddIcon] = useState(true);


    const selectAllProviders = {
        name: translate2("allServicesProviders"),
        id: SELECT_ALL_ID,
        checked: true
    };

    const selectAllServices = {
        name: translate2("allServices"),
        id: SELECT_ALL_ID,
        checked: true
    };


    const onAddIconClick = () => {
        let newVacationFromDateTime = "";
        let newVacationToDateTime = "";
        let newVacationDescription = "";
        let newVacationServices = new Set([selectAllServices]);
        let newVacationProviders = new Set([selectAllProviders]);
        setAddIcon(false)
        let titleInfo = `${translate2("addNewVacation")}: `
        let subTitleInfo = ''
        openConfirmDialog(faUmbrellaBeach, titleInfo, subTitleInfo,
            <AddNewVacation
                defaultSelectAllProviders={selectAllProviders}
                defaultSelectAllServices={selectAllServices}
                onChangeProviders={(providers) => {
                    newVacationProviders = providers
                }}
                onChangeServices={(services) => newVacationServices = services}
                onChangeDescription={(desc) => newVacationDescription = desc}
                onChangeFromDate={(dateTime) => {
                    newVacationFromDateTime = dateTime
                }}
                onChangeToDate={(dateTime) => newVacationToDateTime = dateTime}/>,
            () => {
                addVacationPost(newVacationFromDateTime,
                    newVacationToDateTime,
                    newVacationDescription,
                    newVacationProviders,
                    newVacationServices).then(() => {
                    setAddIcon(true);
                    refreshPage()
                });
            },
            () => {
                setAddIcon(true)
            }
        );
    }


    const addVacationPost = async (newVacationFromDateTime,
                                   newVacationToDateTime,
                                   newVacationDescription,
                                   newVacationProviders,
                                   newVacationServices) => {
        let vacationInfoArr = [];
        let providers = (newVacationProviders.size == 0 || newVacationProviders.has(SELECT_ALL_ID)) ? new Set([SELECT_ALL_ID]) : newVacationProviders;
        let services = (newVacationServices.size == 0 || newVacationServices.has(SELECT_ALL_ID)) ? new Set([SELECT_ALL_ID]) : newVacationServices;

        for (const serviceId of services) {
            for (const providerId of providers) {
                let vacationInfo = {};
                vacationInfo.serviceTypeId = serviceId;
                vacationInfo.serviceProviderId = providerId;
                vacationInfo.startDateTime = newVacationFromDateTime
                vacationInfo.endDateTime = newVacationToDateTime
                vacationInfo.description = newVacationDescription
                vacationInfoArr[vacationInfoArr.length] = vacationInfo;
            }
        }
        if (await addEnterpriseVacations(enterpriseId, vacationInfoArr)) {
        }


    };


    const onVacationClick = async (vacation, index) => {
        switchIcon(vacations, setVacations, selectedVacations, setSelectedVacations, vacation.icon, vacation.id, index);
    };


    const onDelete = async () => {
        const vacationToDelete = selectedVacations.map(item => item.data);
        deleteEnterpriseVacations(enterpriseId, vacationToDelete).then(() => {
                let numOfRemainElementInPage = numOfElementInPage - selectedVacations.length
                setSelectedVacations([]);
                if (numOfRemainElementInPage === 0) {
                    setSelectedPage(prevState => {
                        return prevState > 0 ? prevState - 1 : prevState
                    })
                }
            }
        );
    };

    const handlePageChange = (event, value) => {
        setSelectedPage(value > 0 ? value - 1 : value);
    }

    return (
        <div>
            <DoreTopBar loading={loading}
                        onFilter={() => setOpenFilterMenu(true)}
                        counter={selectedVacations.length > 0 && selectedVacations.length}
                        counterIcon="delete"
            />
            {numOfPages > 1 && <DorePagination numOfPages={numOfPages} handlePageChange={handlePageChange}/>}

            {enterpriseId && <DoreFab
                color="primary"
                onClick={onAddIconClick}
                inout={addIcon}
                icon="add"
                loading={loading}
                variant="extended"
                label={translate2("add")}
            />}
            {enterpriseId && <DoreFab
                color="secondary"
                onClick={onDelete}
                inout={deleteIcon}
                icon="delete"
                loading={loading}
                variant="extended"
                label={translate2("delete")}
            />}


            <FilterMenu
                open={openFilterMenu}
                onClose={() => setOpenFilterMenu(false)}
            >
                <DoreExpansionPanel title={translate2("services")} icon="business_center" initialExpanded={true}
                                    forceCancelLoading={true}>
                    <div style={{
                        paddingBottom: '1rem',
                    }}>
                        {services && services.length > 0 && services.map((service, index) => (
                            <DoreChip
                                key={service.name}
                                label={service.name}
                                checked={service.checked}
                                onClick={() => updateService(index)}
                            />
                        ))}
                    </div>
                </DoreExpansionPanel>
                <DoreExpansionPanel title={translate2("serviceProviders")} icon="account_circle" initialExpanded={true}
                                    forceCancelLoading={true}>
                    <div style={{
                        paddingBottom: '1rem',
                    }}>
                        {providers && providers.length > 0 && providers.map((provider, index) => (
                            <DoreChip
                                key={provider.name}
                                label={provider.name}
                                checked={provider.checked}
                                onClick={() => updateProvider(index)}
                            />
                        ))}
                    </div>
                </DoreExpansionPanel>
            </FilterMenu>
            <DoreList list={vacations} loading={loading} onClick={onVacationClick} selectedColor={ERROR_COLOR_C}/>
            {noData && <DoreEmptyPage/>}
        </div>
    );
};


export default connect(state => ({
    loading: state.loading,
}))(React.memo(EnterpriseVacations));
