import React, {useEffect, useState} from 'react';
import DoreList from '../shared/DoreList';
import {getEnterpriseServices} from '../../services/dataService';
import DoreTopBar from '../shared/DoreTopBar';
import {goToProvidersPage} from '../../services/pageService';
import {$bc_store} from '../../services/breadcrumbService';
import {connect} from 'react-redux';
import {mapAndSave, SERVICES_KEY} from '../../services/storageService';
import {translate2} from "../../services/translateService";

/**
 * Services Component
 *
 * @author Mohammad
 */
const Services = ({ match, breadCrumb, loading, asDialog }) => {

  //params
  let { params: { enterpriseId } } = match;
  enterpriseId = enterpriseId.split('_')[0];
  // States
  const [enterpriseServices, setEnterpriseServices] = useState([]);

  // Helpers
  const onClick = (service) => {
    goToProvidersPage(enterpriseId, service.id);
  };

  useEffect(() => {
    (async () => {
      const services = await getEnterpriseServices(enterpriseId);
      if (services) {
        setEnterpriseServices(services.map(service => {
          $bc_store[service.id] = service.name; // TODO: remove $bc_store and use the storage instead
          return {
            id: service.id,
            title: service.name,
            subTitle: service.minDuration !== service.maxDuration ? ` ${translate2("duration")}: ${service.minDuration} - ${service.maxDuration} ${translate2("min")}` : ` ${translate2("duration")}: ${service.maxDuration} ${translate2("min")}`,
            avatar: true,
          }
        }));
        // Save to storage
        mapAndSave(SERVICES_KEY, services);
      }
    })();
  }, []);

  return (
    <div>
      {!asDialog && <DoreTopBar backBarBtn={true} loading={loading} />}
      <DoreList
        list={enterpriseServices}
        onClick={(service) => asDialog ? asDialog.onClick(service) : onClick(service)}
        skeletonLength={3}
        loading={loading}
      />
    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
  breadCrumb: state.breadCrumb,
}))(Services);
