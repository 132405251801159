import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DoreExpansionPanel from '../DoreExpansionPanel';
import DoreChip from '../DoreChip';
import {openConfirmDialog} from '../../../services/confirmDialogService';
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import NewSubscriber from "../../pages/manager/subscriber/AddAdditionalSubscriberForm";
import {translate2} from "../../../services/translateService";


const AdditionalSubscribers = ({initSubscribers = [], onChange, initialExpanded}) => {

    const [subscribers, setSubscribers] = useState(new Set(initSubscribers));

    useEffect(() => {
        onChange(subscribers);
    }, [subscribers]);

    useEffect(() => {
        setSubscribers(new Set(initSubscribers)); // TODO: TEMP_CODE, this code can create an performance problem!!!!
    }, [initSubscribers]);

    const remove = (name) => {
        const ns = new Set(subscribers);
        ns.delete(name);
        setSubscribers(ns);
    };

    const add = () => {
        let newSubscriberName = '';
        openConfirmDialog(faUserPlus, translate2("addNewCustomer"), '',
            <NewSubscriber onChangeName={(name) => newSubscriberName = name}/>,
            () => {
                if (newSubscriberName) {
                    const ns = new Set(subscribers);
                    ns.add(newSubscriberName);
                    setSubscribers(ns);
                }
            });
    };

    return (
        <DoreExpansionPanel title={translate2("additionalSubscribers")} icon="people_alt" initialExpanded={initialExpanded}>
            <div style={{
                paddingBottom: '1rem',
            }}>
                {subscribers && [...subscribers].map((name, index) => (
                    <DoreChip
                        key={index}
                        label={name}
                        icon="face"
                        onDelete={() => remove(name)}
                    />
                ))}
                <DoreChip
                    label={translate2("newCustomer")}
                    icon="add_circle"
                    checked={true}
                    onClick={add}
                />
            </div>
        </DoreExpansionPanel>
    )
}

export default connect(state => ({
    loading: state.loading,
    breadCrumb: state.breadCrumb,
    currentLanguage: state.currentLanguage,
}))(AdditionalSubscribers);
