import React from 'react';
import {Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import DoreIcon from './DoreIcon';
import {MAIN_COLOR_A, SECOND_COLOR_A} from '../../styles';

/**
 * Styles
 */
const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  btn: ({ icon, color, marginBottom }) => ({
    color: color === 'default' && SECOND_COLOR_A,
    fontSize: '1rem',
    boxSizing: 'border-box',
    paddingBottom: icon && '0.15rem',
    marginBottom,
  }),
  btnSkeleton: {
    boxSizing: 'border-box',
    paddingBottom: '0.15rem',
    borderRadius: '0.35rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonProgress: {
    color: MAIN_COLOR_A,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    margin: '0.39rem 0 0 0',
    fontSize: '1.5rem',
  },
  label: {
    marginLeft: '0.5rem'
  },
});

/**
 *
 */
export const DButtonSkeleton = ({ loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.btnSkeleton}
      />
    </div>
  );
};

/**
 *
 */
const DButton = ({ onClick, label, icon, color, loading, disabled, fullWidth = true, variant = 'contained', marginBottom }) => {
  const classes = useStyles({ icon, color, marginBottom });
  return (
    <div className={classes.wrapper} onClick={() => !loading.spinner && !disabled && onClick && onClick()}>
      <Button
        fullWidth={fullWidth}
        className={classes.btn}
        variant={variant}
        color={color || 'primary'}
        disabled={disabled || loading.spinner}
      >
        {icon && <DoreIcon clazz={classes.icon} loading={loading}>{icon}</DoreIcon>}
        {label && <span className={icon && classes.label}>{label}</span>}
      </Button>
      {loading.spinner && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

/**
 * The Doretou Button base component
 * When any component need a button should use this component
 *
 * @returns React component
 * @constructor
 */
const DoreButton = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.loading.skeleton && <DButtonSkeleton loading={props.loading} />}
      {!props.loading.skeleton && <DButton {...props} />}
    </div>
  )
};

export default DoreButton;
