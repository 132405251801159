import React, {useEffect, useState} from 'react';
import DoreTopBar from '../shared/DoreTopBar';
import DoreTabs from '../shared/DoreTabs';
import {connect} from 'react-redux';
import {getEnterpriseById, getSubscriberInfo, subscribe, unsubscribe} from '../../services/dataService';
import {ENTERPRISE_CATEGORY, SUBSCRIPTION_STATUS} from '../../utils/consts';
import DoreFab from '../shared/DoreFab';
import {goToHomePage, goToServicesPage} from '../../services/pageService';
import {Icon, Portal} from '@material-ui/core';
import DoreSpeedDial from '../shared/DoreSpeedDial';
import DoreExpansionPanel from '../shared/DoreExpansionPanel';
import {getEnterprises} from "../../services/storageService";
import Alert from "@material-ui/lab/Alert";
import DoreProfilePanel from "../shared/DoreProfilePanel";
import NumberOfAllowedTurns from "../shared/subscriber/NumberOfAllowedTurns";
import {translate2} from "../../services/translateService";
import TurnsCalendar from "./consumer/TurnsCalendar";

const HomeTab = ({enterprise, loading}) => {

    const [info, setInfo] = useState({});

    useEffect(() => {
        if (enterprise.name) {
            setInfo({
                name: {label: `${translate2("name")}: `, value: enterprise.name},
                description: {label: `${translate2("description")}: `, value: enterprise.description},
                categoryType: {
                    label: `${translate2("categoryType")}: `,
                    value: ENTERPRISE_CATEGORY[enterprise.categoryType]
                },
                phoneNumber: {label: `${translate2("phoneNumber")}: `, value: enterprise.phoneNumber},
                email: {label: `${translate2("email")}: `, value: enterprise.email},
                subscriptionStatus: {
                    label: `${translate2("subscriptionStatus")}: `,
                    value: enterprise.subscriptionStatus && SUBSCRIPTION_STATUS[enterprise.subscriptionStatus].label
                },
                city: {
                    label: `${translate2("city")}: `,
                    value: enterprise && enterprise.address && enterprise.address.city
                },
            });
        }
    }, [enterprise]);

    return (
        <div>
            <DoreProfilePanel
                title={translate2("enterpriseInfo")}
                icon="store"
                infoObject={info}
            />
            <DoreExpansionPanel title={translate2("gpsLocation")} icon="room">
                <h2 style={{textAlign: 'center'}}> {translate2("underConstruction")} </h2>
            </DoreExpansionPanel>
            <Portal container={document.body}>
                <DoreFab
                    inout={true}
                    icon="book"
                    loading={loading}
                    variant="extended"
                    label={translate2("bookNewTurn")}
                    onClick={() => goToServicesPage(enterprise.id)}
                />
            </Portal>
        </div>
    )
};


const SubscriptionTab = ({loading, enterpriseId, subscriptionStatus, subscriberInfo , refreshInfo}) => { // TODO: Not completed yet!

    const [refresh, setRefresh] = useState(false);

    let subscribersNames = subscriberInfo ? subscriberInfo.customerNames : [];
    let numOfAllowedTurns = subscriberInfo ? subscriberInfo.allowedNumOfTurns : 1;

    useEffect(() => {
        console.log("refresh subscription")
    }, [refresh]);

    const refreshPage = () => {
        refreshInfo && refreshInfo()
        setRefresh(prevState => !prevState)
    }

    const onSave = () => {
        onSubscribe(true).then(() => refreshPage() );
    }

    const onUnsubscribe = async () => {
         await unsubscribe(enterpriseId).then(()=>{
            goToHomePage();
        });
    }

    const onSubscribe = async (isUpdate=false) => {
       await subscribe(enterpriseId, numOfAllowedTurns, subscribersNames).then(()=>{
           console.log("subscribed")
           if(!isUpdate) goToHomePage();
       });

    }


    const actions = [
        {icon: <Icon>save</Icon>, name: translate2("save"), onClick: onSave},
        {icon: <Icon>unsubscribe</Icon>, name: translate2("cancelSubscription"), onClick: onUnsubscribe},
    ];
    ;

    return (
        <div>

            {
                subscriberInfo &&
                subscriberInfo.changeRequests &&
                subscriberInfo.changeRequests["requestedNumOfTurns"] &&
                <Alert
                    severity="info"> {`${translate2("numOfTurnChangeRequest")} ${subscriberInfo.changeRequests["requestedNumOfTurns"]} ${translate2("stillPending")}`}</Alert>
            }
            <NumberOfAllowedTurns
                initialExpanded={false}
                initNumOfAllowedTurns={numOfAllowedTurns}
                onChange={(num) => numOfAllowedTurns = num}
            />
            {/*<AdditionalSubscribers*/}
            {/*    initialExpanded={false}*/}
            {/*    initSubscribers={subscribersNames}*/}
            {/*    onChange={(subscribers) => {*/}
            {/*        subscribersNames = [...subscribers]*/}
            {/*    }*/}
            {/*    }*/}
            {/*/>*/}

            <Portal container={document.body}>
                {
                    !subscriptionStatus ?
                        <DoreFab
                            inout={true}
                            icon="subscriptions"
                            loading={loading}
                            variant="extended"
                            color="secondary"
                            label={translate2("subscribeNow")}
                            onClick={() => onSubscribe()}
                        /> :
                        <DoreSpeedDial actions={actions} openAction={() => console.log('openAction')}
                                       closeAction={() => console.log('closeAction')}/>
                }
            </Portal>
        </div>
    )
};

/**
 * Enterprise Home Component
 */
const EnterpriseHome = ({match, breadCrumb, loading, dispatch, currentLanguage, breadCrumbLeaf}) => {

    // Params & state
    // Params & state
    const [refresh, setRefresh] = useState(false);


    const refreshPage = () => {
        setRefresh(prevState => !prevState)
    }

    let {params: {enterpriseId}} = match;
    enterpriseId = enterpriseId.split('_')[0];
    const [enterprise, setEnterprise] = useState(getEnterprises()[enterpriseId]);
    let subscriptionStatus = enterprise ? enterprise.subscriptionStatus : null;
    const [isSubscribed, setIsSubscribed] = useState(subscriptionStatus != null && subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE.key);
    const [initSelectedTab, setInitSelectedTab] = useState(isSubscribed ? 0 : 1);
    const [subscriberInfo, setSubscriberInfo] = useState(null);
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [showFilter, setShowFilter] = useState(false);


    useEffect(() => {
        (async () => {
            if (!enterprise) {
                const e = await getEnterpriseById(enterpriseId);
                if (e) {
                    setIsSubscribed(e.subscriptionStatus != null && e.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE.key)
                    setInitSelectedTab(isSubscribed ? 0 : 1);
                    setEnterprise(e);
                }
            }
        })();
    }, [refresh]);

    useEffect(() => {
        (async () => {
            if (enterprise) {
                const res = await getSubscriberInfo(enterpriseId)
                if (res) {
                    setSubscriberInfo(res);
                }
            }
        })();
    }, [enterprise, refresh]);


    let mainPage = {
        label: translate2("homePage"),
        icon: 'home',
        disabled: !isSubscribed,
        children: <HomeTab enterprise={enterprise} loading={loading}/>
    };
    let calendarPage = {
        label: translate2("calendar"),
        icon: 'date_range',
        disabled: !isSubscribed,
        children: <TurnsCalendar
            enterpriseId={enterpriseId}
            loading={loading}
            inputOpenFilterMenu={openFilterMenu}
            onFilterMenuChange={(flag) => setOpenFilterMenu(flag)}
        />
    };

    let subscriptionPage = {
        label: translate2("subscription"),
        icon: 'touch_app',
        children: <SubscriptionTab refreshInfo={() => refreshPage()}
                                   subscriberInfo={subscriberInfo}
                                   enterpriseId={enterpriseId}
                                   subscriptionStatus={enterprise && enterprise.subscriptionStatus}
                                   loading={loading} />
    };
    let tabs = [mainPage, subscriptionPage];
    const onFilter = () => setOpenFilterMenu(true);
    return (
        <div>
            <DoreTopBar backBarBtn={true}
                loading={loading}
                        onFilter={showFilter && onFilter}
            />
            {<DoreTabs
                initSelectedTab={initSelectedTab}
                onTabChange={(idx) => {
                    if(idx === 1){
                        setShowFilter(true)
                    }else{
                        setShowFilter(false)
                    }
                }}
                loading={loading}
                tabs={tabs}
            />}
        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseHome);
