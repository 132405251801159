import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import DoreButton from './DoreButton';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import DoreIcon from './DoreIcon';
import {MAIN_COLOR_B, MAIN_COLOR_C} from '../../styles';
import {translate2} from "../../services/translateService";

/**
 * Component styles
 */
const useStyles = makeStyles({
  root: {
    padding: '0 1rem',
  },
  title: {
    padding: '1.5rem 1 0rem 0',
    color: MAIN_COLOR_B,
  },
  subtitle: {
    padding: '0.1rem 0 1rem 0',
    color: MAIN_COLOR_C,
  },
  icon: {
    padding: '1.7rem 0.4rem 1rem 0.7rem',
    color: MAIN_COLOR_B,
    fontSize: '1.2rem',
  },
  sameWidth: {
    width: '8rem'
  },
});

/**
 * DoreDialog
 */
const DoreConfirmDialog = ({ confirmDialog, loading }) => {

  const classes = useStyles();
  return (
      confirmDialog && confirmDialog.title && <Dialog
      fullWidth={true}
        //maxWidth={'xl'}
      fullScreen={confirmDialog.fullScreen}
      className={classes.wrapper}
      onClose={confirmDialog.onClose}
      open={confirmDialog.open}
    >
      <Grid
        container
        className={classes.root}
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
        >
          {confirmDialog.icon && <DoreIcon clazz={classes.icon} loading={loading}>{confirmDialog.icon}</DoreIcon>}
          {confirmDialog.title && <DialogTitle className={classes.title}>{confirmDialog.title}</DialogTitle>}
          {confirmDialog.subtitle && <DialogTitle className={classes.subtitle}>{confirmDialog.subtitle}</DialogTitle>}
        </Grid>
        <Grid item>
          {confirmDialog.children}
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            {!confirmDialog.hideCancelButton && <Grid item className={classes.sameWidth}>
              <DoreButton
                loading={loading}
                label={translate2("cancel")}
                icon={faTimesCircle}
                variant="outlined"
                color="default"
                onClick={confirmDialog.onCancel}
              />
            </Grid>}
            {!confirmDialog.hideConfirmButton && <Grid item className={classes.sameWidth}>
              <DoreButton
                loading={loading}
                label={translate2("ok")}
                icon={faCheckCircle}
                onClick={confirmDialog.onConfirm}
              />
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
};

export default connect(state => ({
  loading: state.loading,
  confirmDialog: state.confirmDialog,
}))(DoreConfirmDialog);
