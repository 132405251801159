import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import DoreExpansionPanel from '../DoreExpansionPanel';
import {checkByKey} from '../../../utils/utils';
import DoreChip from '../DoreChip';
import {translate2} from "../../../services/translateService";




const NumberOfAllowedTurns = ({requestedNumOfTurns, initNumOfAllowedTurns, initialExpanded, onChange}) => {

    useEffect(() => {
        checkByKey(initNumOfAllowedTurns, allowedTurns, setAllowedTurns);
    }, [initNumOfAllowedTurns]);

    const [allowedTurns, setAllowedTurns] = useState({
        1: {label: '1', checked: false},
        2: {label: '2', checked: false},
        3: {label: '3', checked: false},
        4: {label: '4', checked: false},
        5: {label: '5', checked: false},
        10: {label: '10', checked: false},
        1000: {label: '1000', checked: false}, // TODO: Remove
        10000: {label: '10000', checked: false}, // TODO: Remove
    });

    const onClick = (k) => {
        const selected = checkByKey(k, allowedTurns, setAllowedTurns);
        onChange(selected);
    };

    return (
        <DoreExpansionPanel title={translate2("numOfAllowedBookings")} icon="looks" initialExpanded={initialExpanded}>
            <div style={{
                paddingBottom: '1rem',
            }}>

                {Object.keys(allowedTurns).map(k => (
                    <DoreChip
                        key={k}
                        label={allowedTurns[k].label}
                        checked={allowedTurns[k].checked}
                        fantasyFont={true}
                        marginBottom="-5px"
                        onClick={() => onClick(k)}
                    />
                ))}
            </div>
        </DoreExpansionPanel>
    )
};

export default connect(state => ({
    loading: state.loading,
    breadCrumb: state.breadCrumb,
    currentLanguage: state.currentLanguage,
}))(NumberOfAllowedTurns);
