// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCPNnmDwDn2NPivCG8LMKw_ZvN2AvnGWwI",
    authDomain: "doretou-5b342.firebaseapp.com",
    projectId: "doretou-5b342",
    storageBucket: "doretou-5b342.appspot.com",
    messagingSenderId: "637032112922",
    appId: "1:637032112922:web:4680ce94c274ea4f5882a3"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
// export firebase