import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {fade, Icon} from '@material-ui/core';
import Breadcrumb from './DoreBreadcrumb';
import {connect} from 'react-redux';
import {translate, translate2} from './../../services/translateService';
import {makeStyles} from '@material-ui/core/styles';
import {MAIN_COLOR_A, THIRD_COLOR} from '../../styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {useLocation} from 'react-router-dom'
import {$updateBreadcrumb} from '../../services/breadcrumbService';
import Button from '@material-ui/core/Button';
import useWindowSize from '../../hooks/useWindowSize';
import Badge from '@material-ui/core/Badge';
import {setIsMenuBarOpen} from '../../store/actions';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase";
import {isMobile} from "react-device-detect";
import history from "../../history";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



/**
 * Component styles
 */
const useStyles = makeStyles((theme) => ({
    root: () => ({
        flexGrow: 1,
        //backgroundImage: 'linear-gradient(141deg, #303f9f 0%, #3949ab 30%,  #3f51b5 60%, #5c6bc0 90%, #7986cb 100%)',
        backgroundImage: 'linear-gradient(141deg, #7986cb 0%, #7986cb 10%,  #7986cb 20%, #5c6bc0 35%, #7986cb 55%, #7986cb 70%, #7986cb 90%, #9fa8da 100%)',
    }),
    appBar: ({color, backgroundColor}) => ({
        boxShadow: 'none',
        color,
        backgroundColor: backgroundColor === '#ffffff' ? 'white' : 'transparent'
    }),
    title: ({color}) => ({
        flexGrow: 1,
        color,
    }),
    appIcon: ({color}) => ({
        color,
        margin: '0 0.2rem 0.2rem 0',
    }),
    actionsBox: {
        position: 'absolute',
        right: 0,
        direction: 'ltr',
        '& .MuiBadge-badge': {
            fontFamily: 'fantasy !important',
        },
    },
    actionsIcon: ({color}) => ({
        color,
    }),
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

/**
 * Doretou Top-Bar Component
 *
 * @returns {ReactComponent} React component with redux connection
 */
const DoreTopBar =
    ({
         loading,
         currentLanguage,
         color = THIRD_COLOR,
         backgroundColor = MAIN_COLOR_A,
         menuBarBtn = true,
         backBarBtn = false,
         breadcrumb = true,
         breadcrumbBg = MAIN_COLOR_A,
         counter,
         counterIcon = 'mail',
         onFilter,
         onSearch,
         isMenuBarOpen,
         dispatch,
     }) => {
        const classes = useStyles({color, backgroundColor});
        const {sharedTranslation: {appName}, routerTranslation} = translate(null, currentLanguage);

        const size = useWindowSize();
        const [showBySize, setShowBySize] = useState(false); // || temporary

        useEffect(() => {
            if (size.width <= 800) {
                setShowBySize(true);
            } else {
                setShowBySize(false);
            }
        }, [size]);

        // Update breadcrumb
        const location = useLocation();
        useEffect(() => {
            $updateBreadcrumb(location, routerTranslation);
        }, []);

        return (
            <div className={classes.root}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <Button onClick={() => window.open(window.location.origin)} style={{padding: 0}}>
                            <Icon className={classes.appIcon}>query_builder</Icon>
                            <Typography variant="h6" className={classes.title}>{appName}</Typography>
                        </Button>
                        {onSearch && <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={`${translate2('search')}...`}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={ (event)=>{
                                  onSearch && onSearch(event.target.value)
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>}
                        <div className={classes.actionsBox}>
                            {/*{onSearch  &&*/}
                            {/*<IconButton  color="inherit">*/}
                            {/*    <Icon className={classes.actionsIcon}>search</Icon>*/}
                            {/*</IconButton>*/}
                            {/*}*/}
                            {counter && // TODO: Badge should be a to generic custom dore component

                            <Badge badgeContent={counter} color="secondary" style={{
                                marginLeft: !showBySize && '1rem',
                            }}>
                                <Icon>{counterIcon}</Icon>
                            </Badge>}

                            {onFilter &&
                            <IconButton onClick={onFilter} color="inherit">
                                <FontAwesomeIcon className={classes.actionsIcon} style={{fontSize: '0.8rem'}}
                                                 icon={faFilter}/>
                            </IconButton>}

                            {(menuBarBtn && showBySize && !backBarBtn) &&
                            <IconButton onClick={() => dispatch(setIsMenuBarOpen(!isMenuBarOpen))} color="inherit">
                                <Icon className={classes.actionsIcon}>list</Icon>
                            </IconButton>}

                            {(isMobile && backBarBtn  ) &&
                                <IconButton onClick={() => history.goBack()} color="inherit">
                                   <ArrowBackIcon/>
                                </IconButton>}



                        </div>
                    </Toolbar>
                </AppBar>
                {breadcrumb && <Breadcrumb/>}
                {(loading && (loading.spinner || loading.skeleton)) &&
                <LinearProgress variant="query" color="primary"/>}
            </div>
        );
    };

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    isMenuBarOpen: state.isMenuBarOpen,
}))(DoreTopBar);
