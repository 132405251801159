// General Consts

import {
    faAddressBook,
    faDoorOpen,
    faHistory,
    faStoreAlt,
    faUmbrellaBeach,
    faUser,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
    goToBookingsPage,
    goToEnterpriseEmployeesPage,
    goToEnterpriseProfilePage,
    goToEnterpriseServicesPage,
    goToEnterpriseSubscribersPage,
    goToEnterpriseVacationsPage, goToEnterpriseWorkingHoursPage,
    goToHomePage,
    goToProfilePage,
    goToSignUpPage
} from '../services/pageService';
import {getUser} from '../services/storageService';
import {translate2} from "../services/translateService";
import {faBusinessTime} from "@fortawesome/free-solid-svg-icons/faBusinessTime";
import {faCalendarWeek} from "@fortawesome/free-solid-svg-icons/faCalendarWeek";

export const ARABIC = 'ARABIC';
export const HEBREW = 'HEBREW';
// export const checkedIcon = 'check_circle';
// export const unCheckedIcon = 'radio_button_unchecked';
export const checkedIcon = 'check_box';
export const unCheckedIcon = 'check_box_outline_blank';

export const THEME_BASE_OPTIONS = {
    direction: 'rtl',
    typography: {
        fontFamily: `'Tajawal', sans-serif`,
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    }
};

export const USER_TYPE = Object.freeze({
    CONSUMER: 'CONSUMER',
    MANAGER: 'MANAGER',
});

export const NUMBERS_TO_DAYS = {
    0: "SUNDAY",
    1: "MONDAY",
    2: "TUESDAY",
    3: "WEDNESDAY",
    4: "THURSDAY",
    5: "FRIDAY",
    6: "SATURDAY"
}

export const DAYS_TO_NUMBERS = {
    "SUNDAY": 0,
    "MONDAY": 1,
    "TUESDAY": 2,
    "WEDNESDAY": 3,
    "THURSDAY": 4,
    "FRIDAY": 5,
    "SATURDAY": 6
}

export const PAGE_STYLE = Object.freeze({
    PAGE: 'PAGE',
    DIALOG: 'DIALOG',
});

// TODO: need to get the value from translator
export const SUBSCRIPTION_STATUS = Object.freeze({
    // INACTIVE: { key: 'INACTIVE', label: 'غير نشط', icon: 'not_interested' },
    PENDING: {key: 'PENDING', label: translate2("pending"), icon: 'pause_circle_outline'},
    ACTIVE: {key: 'ACTIVE', label: translate2("active"), icon: 'check_circle_outline'},
    // SUSPENDED: { key: 'SUSPENDED', label: 'معلق', icon: 'lock_outlined' },
    BLOCKED: {key: 'BLOCKED', label: translate2("blocked"), icon: 'block'},
});

export const USER_MENU = {
    enterprises: {
        text: translate2("enterprises"),
        icon: faStoreAlt,
        onClick: goToHomePage,
    },
    bookings: {
        text: translate2("bookings"),
        icon: faHistory,
        onClick: goToBookingsPage,
    },
    profile: {
        text: translate2("profile"),
        icon: faAddressBook,
        onClick: goToProfilePage,
    },
    exit: {
        text: translate2("exit"),
        icon: faDoorOpen,
        onClick: goToSignUpPage,
    },
};

export const MANAGER_MENU = {
    "enterprise-books": {
        text: translate2("enterpriseBooks"),
        icon: faHistory,
        onClick: goToHomePage,
    },
    "enterprise-profile": {
        text: translate2("enterpriseProfile"),
        icon: faStoreAlt,
        onClick: goToEnterpriseProfilePage,
    },
    "enterprise-employees": {
        text: translate2("employees"),
        icon: faUser,
        onClick: goToEnterpriseEmployeesPage,
    },
    "enterprise-services": {
        text: translate2("services"),
        icon: faBusinessTime,
        onClick: goToEnterpriseServicesPage,
    },
    "enterprise-wh": {
        text: translate2("workingHours"),
        icon: faCalendarWeek,
        onClick: goToEnterpriseWorkingHoursPage,
    },
    "enterprise-vacation": {
        text: translate2("enterpriseVacations"),
        icon: faUmbrellaBeach,
        onClick: goToEnterpriseVacationsPage,
    },
    subscribers: {
        text: translate2("subscribers"),
        icon: faUsers,
        onClick: () => goToEnterpriseSubscribersPage(getUser().enterpriseId),
    },
    exit: {
        text: translate2("exit"),
        icon: faDoorOpen,
        onClick: goToSignUpPage,
    },
};

// TODO: need to moved-to/used-by translator!
export const ENTERPRISE_CATEGORY = Object.freeze({
    BARBER: translate2("barber"),
});

export const ENTERPRISE_CATEGORIES = {
    "BARBER": "BARBER"
}

export const FIXED_TZ = {
    JRS: "Asia/Jerusalem"
}

export const DEFAULT_PAGE_SIZE = 10;

export const employeeRoleTypes = {
    "OWNER": {name: "employeeType.OWNER", checked: false},
    "ADMIN": {name: "employeeType.ADMIN", checked: false},
    "SERVICE_PROVIDER": {name: "employeeType.SERVICE_PROVIDER", checked: false},
    "SECRETARY": {name: "employeeType.SECRETARY", checked: false}
}

export const ENTERPRISE_STATUS_TYPES = {
    ACTIVE: {name: "enterpriseStatus.OWNER", id: "ACTIVE"},
    NEW: {name: "enterpriseStatus.NEW", id: "NEW"},
    INACTIVE: {name: "enterpriseStatus.INACTIVE", id: "INACTIVE"},
}

export const ENTERPRISE_PRIVACY_TYPES = {
    PUBLIC: {name: "enterprisePrivacy.PUBLIC", id: "PUBLIC"},
    SUBSCRIPTION: {name: "enterprisePrivacy.SUBSCRIPTION", id: "SUBSCRIPTION"},
    PRIVATE: {name: "enterprisePrivacy.PRIVATE", id: "PRIVATE"},
}

export const SUBSCRIBERS_PREFIX_KEY = 'C';
export const PROVIDERS_PREFIX_KEY = 'P';
export const SERVICES_PREFIX_KEY = 'S';
export const ENTERPRISES_PREFIX_KEY = 'E';

export const ENTERPRISE_STATUSES = {
    NEW: 'NEW',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const SOME_COLORS = ['#0088FE',
    '#00C49F',
    '#FFBB28',
    '#ff38b6',
    '#ff8838',
    '#9538ff',
    '#ff3866',
    '#38ffe8',
    '#74523c'];
