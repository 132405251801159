import store from '../store/store';
import {setConfirmDialog} from '../store/actions';
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";

export const createConfirmDialog = (
    icon = faUserPlus,
    title = '',
    subtitle = '',
    open = false,
    children = null,
    onConfirm,
    onClose = closeConfirmDialog,
    onCancel = closeConfirmDialog,
    hideConfirmButton = false,
    hideCancelButton = false,
    fullScreen = false) => ({
    icon,
    title,
    subtitle,
    open,
    children,
    onClose,
    onConfirm,
    onCancel,
    hideConfirmButton,
    hideCancelButton,
    fullScreen
});

export const openConfirmDialog = (icon,
                                  title,
                                  subtitle,
                                  children,
                                  onConfirm,
                                  onCancel,
                                  hideConfirmButton,
                                  hideCancelButton,
                                  fullScreen) => {
    store.dispatch(setConfirmDialog(
        createConfirmDialog(icon,
            title,
            subtitle,
            true,
            children,
            () => {
                onConfirm && onConfirm();
                closeConfirmDialog();
            }, () => {
                onCancel && onCancel()
                closeConfirmDialog()
            },
            () => {
                onCancel && onCancel()
                closeConfirmDialog()
            },
            hideConfirmButton,
            hideCancelButton,
            fullScreen
        )));
};

export const closeConfirmDialog = () => {
    store.dispatch(setConfirmDialog(createConfirmDialog()));
};
