import {connect} from "react-redux";
import React from "react";
import FullWorkingHours from "./FullWorkingHours";

const EnterpriseWorkingHours = ({loading}) => {

    return <div>
        <FullWorkingHours
            loading={loading}
            isToShowTopBar={true}
        />
    </div>
}

export default connect(state => ({
    loading: state.loading,
}))(React.memo(EnterpriseWorkingHours));
