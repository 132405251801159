import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    editServiceProviderWorkingHours,
    getManagerEnterpriseServiceProviderById,
    getManagerServiceProviderWorkingHours
} from "../../../../../services/dataService";
import {getUser} from "../../../../../services/storageService";
import DoreTopBar from "../../../../shared/DoreTopBar";
import DoreExpansionPanel from "../../../../shared/DoreExpansionPanel";
import {Icon, Portal} from "@material-ui/core";
import {NUMBERS_TO_DAYS} from "../../../../../utils/consts";
import {changeTimeFormat} from "../../../../../utils/utils";
import DoreOneWeekCalendar from "../../../../shared/calendar/DoreOneWeekCalendar";
import {mapServiceProviderWHToCalendarEvents} from "../utils/EnterpriseProfileUtils";
import {translate2} from "../../../../../services/translateService";
import DoreInputField from "../../../../shared/DoreInputField";
import DoreFab from "../../../../shared/DoreFab";


const EnterpriseServiceProviderProfile = ({match, loading, currentLanguage, isEditMode = true}) => {

    const {enterpriseId} = getUser();
    let {params: {serviceTypeId, serviceTypeVersionId, serviceProviderId}} = match;
    serviceTypeId = serviceTypeId.split('_')[0];
    serviceProviderId = serviceProviderId.split('_')[0];

    const [serviceProvider, setServiceProvider] = useState({});
    const [serviceProviderInfo, setServiceProviderInfo] = useState({});
    const [initialWorkingHours, setInitialWorkingHours] = useState({});

    useEffect(() => {
        (async () => {
            const serviceProviderRes = await getManagerEnterpriseServiceProviderById(enterpriseId, serviceTypeId, serviceProviderId, serviceTypeVersionId);

            if (serviceProviderRes) {
                setServiceProvider({
                    id: serviceProviderRes.id,
                    name: serviceProviderRes.name,
                    duration: serviceProviderRes.duration,
                    phoneNumber: serviceProviderRes.phoneNumber,
                });
            }
        })();
    }, []);


    useEffect(() => {
        (async () => {
            const workingHours = await getManagerServiceProviderWorkingHours(enterpriseId, serviceTypeId, serviceTypeVersionId, serviceProviderId);
            if (workingHours) {
                setInitialWorkingHours(workingHours)
            }
        })();
    }, []);

    // function mapServiceProviderWHToCalendarEvents() {
    //     if (initialWorkingHours && calendar) {
    //         Object.keys(initialWorkingHours).map((day) => {
    //                 let title = getService(serviceTypeId) ? getService(serviceTypeId).name : 'WH';
    //                 let mappedDaySlots = null;
    //                 let slots = initialWorkingHours[day]
    //                 let dayNumber = DAYS_TO_NUMBERS[day];
    //                 let date = addDays(weekRange.startDate, dayNumber);
    //                 if (slots) {
    //                     slots.map((slot) => {
    //                         let startDateTime = toYYYYMMDDTHHmmssFormat(addHHMMToDate(date, slot.startTime));
    //                         let endDateTime = toYYYYMMDDTHHmmssFormat(addHHMMToDate(date, slot.endTime));
    //                         let mappedSlot = {
    //                             id: createEventId(),
    //                             title: title,
    //                             start: startDateTime,
    //                             end: endDateTime,
    //                             allDay: false
    //                         };
    //                         calendar.addEvent(mappedSlot);
    //                     })
    //                 }
    //                 return mappedDaySlots;
    //             }
    //         )
    //     }
    // }

    useEffect(() => {
        mapServiceProviderWHToCalendarEvents(initialWorkingHours, calendar, weekRange.startDate, serviceTypeId);
    }, [initialWorkingHours]);

    // useEffect(() => {
    //     setServiceProviderInfo({
    //         name: {label: `${translate2("name")}: `, value: serviceProvider.name},
    //         phoneNumber: {label: `${translate2("phoneNumber")}: `, value: serviceProvider.phoneNumber},
    //         duration: {label: 'مدة الدور: ', value: `${serviceProvider.duration} د`},
    //     });
    // }, [serviceProvider]);
    useEffect(() => {
        setServiceProviderInfo({
            name: serviceProvider.name,
            phoneNumber: serviceProvider.phoneNumber,
            duration: serviceProvider.duration,
        });
    }, [serviceProvider]);

    function onSave() {
        let fcEvents = calendar.getEvents();
        let newWorkingHours = {};
        fcEvents.map((event) => {
                let day = NUMBERS_TO_DAYS[event.start.getDay()];
                let slot = {
                    startTime: changeTimeFormat(event.start),
                    endTime: changeTimeFormat(event.end)
                }
                if (newWorkingHours[day]) {
                    newWorkingHours[day].push(slot);
                } else {
                    newWorkingHours[day] = [slot]
                }
            }
        );
        isEditMode &&
        editServiceProviderWorkingHours(enterpriseId, serviceTypeId, serviceTypeVersionId, serviceProviderId, newWorkingHours)
            .then(r => console.log("done adding"));
    }


    const actions = [
        {icon: <Icon>save</Icon>, name: 'أحفظ التعديلات', onClick: onSave},
    ];


    const [calendar, setCalendar] = useState(null);
    const [weekRange, setWeekRange] = useState({})

    return (
        <div>
            <DoreTopBar loading={loading}/>
            {/*<DoreProfilePanel*/}
            {/*    title="تفاصيل مقدم الخدمه"*/}
            {/*    icon="account_circle"*/}
            {/*    infoObject={serviceProviderInfo}*/}
            {/*/>*/}
            <br/>
            <div style={{marginRight: "10px", marginLeft: "10px"}}>

                <DoreExpansionPanel title={translate2("serviceProviderDetails")} icon="account_circle"
                                    initialExpanded={true}>
                    <DoreInputField
                        loading={loading}
                        disabled
                        label={translate2("name")}
                        icon="person"
                        value={serviceProviderInfo.name}
                    />

                    <DoreInputField
                        loading={loading}
                        disabled
                        type="number"
                        label={translate2("phoneNumber")}
                        icon="local_phone"
                        value={serviceProviderInfo.phoneNumber}
                    />
                {/*</DoreExpansionPanel>*/}
                {/*<DoreExpansionPanel title={translate2("customizedInfo")} icon="account_circle"*/}
                {/*                    initialExpanded={false}>*/}
                    <DoreInputField
                        bottomSpace={false}
                        loading={{}}
                        label={translate2("durationInMin")}
                        icon="time"
                        type="number"
                        min="0"
                        value={serviceProviderInfo.duration}
                        onChange={e => {
                            const newDuration = e.target.value;
                            let r = parseInt(newDuration);
                            if (r > 0) {
                                setServiceProviderInfo(prevState => {
                                    let newState = JSON.parse(JSON.stringify(prevState));
                                    newState.duration = "" + r;
                                    return newState;
                                })
                            }
                        }}
                    />
                </DoreExpansionPanel>


                <DoreExpansionPanel title={translate2("workingHours")} icon="schedule" initialExpanded={false}>
                    <DoreOneWeekCalendar
                        inputDuration={serviceProvider.duration}
                        inputServiceTypeId={serviceTypeId}
                        onChangeCalendar={(c) => setCalendar(c)}
                        onChangeWeekRange={(wr) => setWeekRange(wr)}
                    />
                </DoreExpansionPanel>
            </div>
            {/*{isEditMode && <DoreSpeedDial actions={actions} openAction={() => console.log('openAction')}*/}
            {/*                              closeAction={() => console.log('closeAction')}/>}*/}

            <Portal container={document.body}>
                <DoreFab
                    onClick={onSave}
                    inout={true}
                    icon="save"
                    loading={loading}
                    variant="extended"
                    label={translate2("save")}
                />
            </Portal>
        </div>
    )
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(EnterpriseServiceProviderProfile);
