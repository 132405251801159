import React from 'react';
import Icon from '@material-ui/core/Icon';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

/**
 * Component styles
 */
const useStyles = makeStyles({
  root: ({ fantasyFont, marginBottom }) => ({
    margin: '0.2rem',
    fontFamily: fantasyFont && 'fantasy',
    '& .MuiChip-label': {
      marginBottom,
    }
  }),
});

/**
 * DoreChip
 */
const DoreChip = ({ id,
                    label,
                    icon,
                    variant = 'outlined',
                    onClick,
                    onDelete,
                    checked = false,
                    letter,
                    fantasyFont,
                    marginBottom }) => {

  // CSS classes
  const classes = useStyles({ fantasyFont, marginBottom });

  return (
    <Chip
      id={id}
      className={classes.root}
      variant={checked ? 'default' : variant}
      color="primary"
      label={label}
      icon={icon && <Icon>{icon}</Icon>}
      avatar={letter && <Avatar>{letter}</Avatar>}
      onClick={onClick}
      onDelete={onDelete}
    />

  );
};

export default connect(state => ({}))(DoreChip);
