import 'datejs';
import React, {useEffect, useState} from 'react';
import {getAvailableDates} from '../../services/dataService';
import DoreTopBar from '../shared/DoreTopBar';
import DoreList from '../shared/DoreList';
import {goToTimeSlotsPage} from '../../services/pageService';
import {changeDateFormat} from '../../utils/utils';
import {connect} from 'react-redux';

const AvailableDates = ({ match, breadCrumb, loading, asDialog }) => {

  // Params
  let { params: { enterpriseId, serviceId, providerId } } = match;
  enterpriseId = enterpriseId.split('_')[0];
  serviceId = serviceId.split('_')[0];
  providerId = providerId.split('_')[0];

  // States
  const [dates, setDates] = useState([]);

  const onClick = ({ dateVersionPair }) => {
    goToTimeSlotsPage(enterpriseId, serviceId, providerId, dateVersionPair.date,dateVersionPair.version);
  };

  useEffect(() => {
    (async () => {
      const dateVersionPairsInfo = await getAvailableDates(enterpriseId, serviceId, providerId);
      if (dateVersionPairsInfo) {
        setDates(dateVersionPairsInfo.map((dateVersionPair, index) => ({
          dateVersionPair,
          id: index,
          title: Date.getDayName(new Date(dateVersionPair.date).getDay()),
          subTitle: changeDateFormat(dateVersionPair.date),
          icon: 'calendar_today',
          iconText: new Date(dateVersionPair.date).getDate(),
        })));
      }
    })();
  }, []);

  return (
    <div>
      {!asDialog && <DoreTopBar backBarBtn={true} loading={loading} />}
      <DoreList
        list={dates}
        onClick={(dateObj) => asDialog ? asDialog.onClick(dateObj) : onClick(dateObj)}
        skeletonLength={6}
        loading={loading}
      />
    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
  breadCrumb: state.breadCrumb,
}))(AvailableDates);
