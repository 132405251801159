import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {Icon} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * Styles
 */
const useStyles = makeStyles({
  wrapper: {},
  icon: ({ size, color }) => ({
    fontSize: size,
    color,
  })
});

/**
 *
 */
export const DIconSkeleton = ({ size }) => {
  const classes = useStyles();
  return (
    <Skeleton
      animation="wave"
      variant="circle"
      width={size}
      height={size}
      style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '1rem' }}
    />
  );
};

/**
 *
 */
const DIcon = ({ size, color, children, clazz }) => {
  const classes = useStyles({ size, color });
  return (
    <div>
      {
        typeof children === 'string' ?
          <Icon className={clazz || classes.icon}>{children}</Icon> :
          <FontAwesomeIcon className={clazz || classes.icon} icon={children} />
      }
    </div>
  );
};

/**
 *
 */
const DoreIcon = ({ size = '4.1rem', ...props }) => (
  <div>
    {props.loading.skeleton && <DIconSkeleton size={size} />}
    {!props.loading.skeleton && <DIcon size={size} {...props} />}
  </div>
);

export default DoreIcon;
