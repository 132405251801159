import {startSkeletonLoading, startSpinnerLoading, stopLoading} from '../utils/utils';
import {getAuth, isAuth} from './storageService';
import SomethingHappened, {ERROR_HAPPENED} from '../components/pages/SomethingHappened';
import {openDialog} from './dialogService';
import React from 'react';
import {ERROR_COLOR_A} from '../styles';
import {translate} from './translateService';
import store from '../store/store';

// const BASE_HTTPS_SERVER_URL = 'http://localhost:8080';
// const BASE_HTTPS_SERVER_URL = 'https://localhost:8443';
const BASE_HTTPS_SERVER_URL = 'https://backend.doretou.com:8443';
const BASE_SERVER_URL = BASE_HTTPS_SERVER_URL;
const SIGN_UP_URL = `${BASE_SERVER_URL}/user/signUp`;
const SIGN_IN_URL = `${BASE_SERVER_URL}/user/signIn`;
const SIGN_OUT_URL = `${BASE_SERVER_URL}/user/signOut`;
const GET_ENTERPRISES_URL = `${BASE_SERVER_URL}/user/enterprise`;
const GET_ENTERPRISE_BY_ID_URL = (enterpriseId) => `${BASE_SERVER_URL}/user/enterprise?enterpriseId=${enterpriseId}`;
const GET_ENTERPRISE_SERVICES_URL = (enterpriseId) => `${BASE_SERVER_URL}/user/enterprise/service?enterpriseId=${enterpriseId}`;
const GET_ENTERPRISE_EMPLOYYES_URL = (enterpriseId) => `${BASE_SERVER_URL}/user/enterprise/${enterpriseId}/employees`;
const GET_ENTERPRISE_SERVICE_PROVIDERS_URL = (enterpriseId, serviceTypeId) => `${BASE_SERVER_URL}/user/enterprise/service/provider?enterpriseId=${enterpriseId}&serviceTypeId=${serviceTypeId}`;
const GET_AVAILABLE_DATES_URL = (enterpriseId, serviceTypeId, serviceProviderId) => `${BASE_SERVER_URL}/user/enterprise/service/provider/date?enterpriseId=${enterpriseId}&serviceTypeId=${serviceTypeId}&serviceProviderId=${serviceProviderId}`;
const GET_AVAILABLE_SLOTS = (enterpriseId, serviceTypeId, serviceProviderId, date,version) => `${BASE_SERVER_URL}/user/enterprise/service/provider/date/slot?enterpriseId=${enterpriseId}&serviceTypeId=${serviceTypeId}&serviceProviderId=${serviceProviderId}&date=${date}&versionId=${version}`;
const BOOK_TURN_URL = (enterpriseId) => `${BASE_SERVER_URL}/user/turn/book?enterpriseId=${enterpriseId}`;
const GET_BOOKINGS_URL = `${BASE_SERVER_URL}/user/turn/booking`;
const GET_BOOKINGS_BY_ENTERPRISE_URL =  (enterpriseId) =>  `${GET_BOOKINGS_URL}?enterpriseId=${enterpriseId}`;
const CANCEL_BOOKING_URL = (bookingIds) => `${GET_BOOKINGS_URL}/${bookingIds}/cancel`;
const GET_USER_INFO_URL = `${BASE_SERVER_URL}/user/info`;
const UPDATE_USER_INFO_URL = `${BASE_SERVER_URL}/user/info`;
const SUBSCRIBE_BASE_URL = `${BASE_SERVER_URL}/user/enterprise/subscribe`
const SUBSCRIBE_URL = (enterpriseId) => `${SUBSCRIBE_BASE_URL}?enterpriseId=${enterpriseId}`;
const UNSUBSCRIBE_URL = (enterpriseId) => `${BASE_SERVER_URL}/user/enterprise/unsubscribe?enterpriseId=${enterpriseId}`;

// Manager API
const MANAGER_ENTERPRISE_BOOKS_URL = (enterpriseId, serviceTypeId, serviceProviderId, date) => `${BASE_SERVER_URL}/enterprise/${enterpriseId}/service/${serviceTypeId}/provider/${serviceProviderId}/date/${date}/slot`;
const MANAGER_CONSUMERS_URL = (enterpriseId) => `${BASE_SERVER_URL}/enterprise/${enterpriseId}/subscriber`;
const MANAGER_CONSUMER_URL = (enterpriseId, consumerId) => `${MANAGER_CONSUMERS_URL(enterpriseId)}/${consumerId}`;
const MANAGER_VACATION_URL = (enterpriseId) => `${BASE_SERVER_URL}/enterprise/${enterpriseId}/vacation`;
const MANAGER_EMPLOYEE_URL = (enterpriseId) => `${BASE_SERVER_URL}/enterprise/${enterpriseId}/employee`;
const MANAGER_SERVICES_URL = (enterpriseId) => `${BASE_SERVER_URL}/enterprise/${enterpriseId}/service`;
const MANAGER_SERVICE_PROVIDERS_URL = (enterpriseId,serviceTypeId) => `${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}/provider`;
const MANAGER_SERVICE_PROVIDERS_WH_URL = (enterpriseId,serviceTypeId,serviceProviderId) => `${MANAGER_SERVICE_PROVIDERS_URL(enterpriseId,serviceTypeId)}/${serviceProviderId}/wh`;
const MANAGER_SERICES_MULTI_WH_URL = (enterpriseId) => `${MANAGER_SERVICES_URL(enterpriseId)}/wh`;

const MANAGER_BOOKINGS_URL = (enterpriseId) => `${BASE_SERVER_URL}/enterprise/${enterpriseId}/booking`;

const MANAGER_ENTERPRISE_URL = () => `${BASE_SERVER_URL}/enterprise`

const getHeaders = (pageNumber,pageSize) => {
  if (!isAuth()) return;
  const { auth } = getAuth();//TODO CHECK AHMAD
  return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + auth,
      'pageNumber' : pageNumber ? pageNumber : null ,
      'pageSize' : pageSize ? pageSize : null
  };
};

function getSubTitle(errorTranslation, errorResponse) {
    let params = errorResponse.params;
    let msg = errorTranslation.msg;
    if(params){
        for (let i = 0; i < params.length; i++) {
            const { errorConstantTranslation } = translate(params[i], store.getState().currentLanguage);
            msg = msg.replace('{'+i+'}',errorConstantTranslation ? errorConstantTranslation :params[i])
        }
    }
    return msg;
}

const $fetch = (url, startLoading, options) => {
  startLoading();
  return fetch(url, options)
    .then(response => {
      stopLoading();
      if (response.ok) {
          let contentLength= response.headers.get("content-Length");
          let toReturnEmptyArray = contentLength && contentLength === 0;
        return toReturnEmptyArray ? new Map() : response.json();
      } else {
        (async () => {
          const errorResponse = await response.json();
          if(errorResponse.status === 401){
              console.log("401 error")
             // goToSignUpPage()
          }
          else {

              const {errorTranslation} = translate(errorResponse.errorCode, store.getState().currentLanguage);
              openDialog(
                  <SomethingHappened
                      title={errorTranslation.title}
                      subTitle={getSubTitle(errorTranslation, errorResponse)}
                      type={ERROR_HAPPENED}
                  />,
                  ERROR_COLOR_A
              );
          }
        })();
      }
    })
    .catch(e => {
      console.error(e);
    });
};

const $post = (url, body) => (
  $fetch(url, startSpinnerLoading, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(body)
  })
);

const $postWithoutAuth = (url, body) => (
  $fetch(url, startSpinnerLoading, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
);

const $put = (url, body) => (
  $fetch(url, startSkeletonLoading, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(body)
  })
);

const $get = (url,pageNumber,pageSize) => (
  $fetch(url, startSkeletonLoading, {
    method: 'GET',
    headers: getHeaders(pageNumber,pageSize),
    redirect: 'follow'
  })
);

const $delete = (url) => (
    $fetch(url, startSkeletonLoading, {
        method: 'DELETE',
        headers: getHeaders(),
        redirect: 'follow'
    })
);

export const signUp = (phoneNumber) => {
  return $postWithoutAuth(SIGN_UP_URL, { phoneNumber });
};

export const signIn = (phoneNumber, accessCode) => {
  return $postWithoutAuth(SIGN_IN_URL, { phoneNumber, accessCode });
};

export const signInWithFirebase = (firebaseAuthIdToken) => {
    return $postWithoutAuth(SIGN_IN_URL, { firebaseAuthIdToken });
};

export const signOut = () => {
    return $post(SIGN_OUT_URL);
}

export const getEnterprises = (filter) => {
    let params = [filter && `filter=${filter}`]
    let queryParams = getQueryParams(params);
  return $get(`${GET_ENTERPRISES_URL}${queryParams}`);
};

export const getEnterpriseById = (enterpriseId) => {
  return $get(GET_ENTERPRISE_BY_ID_URL(enterpriseId));
};

export const getEnterpriseServices = (enterpriseId) => {
  return $get(GET_ENTERPRISE_SERVICES_URL(enterpriseId));
};

export const getEnterpriseEmployees = (enterpriseId) => {
  return $get(GET_ENTERPRISE_EMPLOYYES_URL(enterpriseId));
};



export const getEnterpriseServiceProviders = (enterpriseId, serviceTypeId) => {
  return $get(GET_ENTERPRISE_SERVICE_PROVIDERS_URL(enterpriseId, serviceTypeId));
};

export const getUserInfo = () => {
  return $get(GET_USER_INFO_URL);
};

export const updateUserInfo = (name, birthDate, city) => {
  return $post(UPDATE_USER_INFO_URL, { name, birthDate, address: { city } });
};

export const getAvailableDates = (enterpriseId, serviceTypeId, serviceProviderId) => {
  return $get(GET_AVAILABLE_DATES_URL(enterpriseId, serviceTypeId, serviceProviderId));
};

export const getAvailableSlots = (enterpriseId, serviceTypeId, serviceProviderId, date,version) => {
  return $get(GET_AVAILABLE_SLOTS(enterpriseId, serviceTypeId, serviceProviderId, date,version));
};

export const bookTurn = (enterpriseId, slots) => {
  return $post(BOOK_TURN_URL(enterpriseId), { requiredSlots: slots }); // TODO: maybe talk to ahmad to remove the key: 'requiredSlots'
};

export const getBookings = (enterpriseId) => {
  let params = [enterpriseId && `enterpriseId=${enterpriseId}`]
  let queryParams = getQueryParams(params);
  return $get(`${GET_BOOKINGS_URL}${queryParams}`);
};

export const getBookingsByEnterpriseId = (enterpriseId) => {
    return $get(GET_BOOKINGS_BY_ENTERPRISE_URL(enterpriseId));
}

export const cancelBookings = (bookingIds) => {
  return $post(CANCEL_BOOKING_URL(bookingIds));
};

export const subscribe = (enterpriseId, maxTurnNumbers = 1, customersNames = []) => {
  return $post(SUBSCRIBE_URL(enterpriseId), { maxTurnNumbers, customersNames });
};

export const unsubscribe = (enterpriseId) => {
    return $post(UNSUBSCRIBE_URL(enterpriseId),  null);
};

export const getSubscriberInfo = (enterpriseId,fieldsNames) => {
    let params = [fieldsNames && `fieldsNames=${fieldsNames}`]
    let queryParams = getQueryParams(params);
    return $get(`${SUBSCRIBE_URL(enterpriseId)}`);
};

//
// const SUBSCRIBER_REMAIN_TURNS_NUMBER_URL =  (enterpriseId) =>  `${SUBSCRIBE_BASE_URL}?enterpriseId=${enterpriseId}&fieldsNames=remainNumOfTurns`
// const SUBSCRIBER_ALLOWED_TURNS_NUMBER_URL =  (enterpriseId) =>  `${SUBSCRIBE_BASE_URL}?enterpriseId=${enterpriseId}&fieldsNames=allowedNumOfTurns`
//
// export const getSubscriberAllowedTurnsNumber = (enterpriseId) => {
//     return $get(SUBSCRIBER_ALLOWED_TURNS_NUMBER_URL(enterpriseId));
// };
//
// export const getSubscriberRemainTurnsNumber = (enterpriseId) => {
//     return $get(SUBSCRIBER_REMAIN_TURNS_NUMBER_URL(enterpriseId));
// };

// *** Manager ***

export const getEnterpriseBooks = (enterpriseId, serviceTypeId, serviceProviderId, date,versionId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
  return $get(`${MANAGER_ENTERPRISE_BOOKS_URL(enterpriseId, serviceTypeId, serviceProviderId, date)}${queryParams}`);
};


export const getEnterpriseConsumers = (enterpriseId) => {
  return $get(MANAGER_CONSUMERS_URL(enterpriseId));
};

export const getPagedEnterpriseSubscribers = (enterpriseId,pageNumber,pageSize) => {
    return $get(MANAGER_CONSUMERS_URL(enterpriseId),pageNumber,pageSize);
};

export const getConsumer = (enterpriseId, consumerId) => {
  return $get(MANAGER_CONSUMER_URL(enterpriseId, consumerId));
};

export const updateConsumer = (enterpriseId, consumerId, body) => {
  return $post(`${MANAGER_CONSUMERS_URL(enterpriseId)}/${consumerId}`, body); // TODO: Ask AHMAD why using POST and not PUT?
};

export const addSubscriber = (enterpriseId, body) => {
    return $post(`${MANAGER_CONSUMERS_URL(enterpriseId)}`, body);
};

export const searchSubscribers = (enterpriseId, text) => {
    return $get(MANAGER_CONSUMERS_URL(enterpriseId)+"/search/"+text);
};

export const getEnterpriseVacations = (enterpriseId) => {
    return $get(MANAGER_VACATION_URL(enterpriseId));
};

export const getPagedEnterpriseVacations = (enterpriseId,pageNumber,pageSize) => {
    return $get(MANAGER_VACATION_URL(enterpriseId),pageNumber,pageSize);
};


//selectedPage,DEFAULT_PAGE_SIZE

export const addEnterpriseVacations = (enterpriseId,body) => {
    return $post(MANAGER_VACATION_URL(enterpriseId)+"/s",body);
};

export const deleteEnterpriseVacations = (enterpriseId,vacationIds) => {
    return $delete(MANAGER_VACATION_URL(enterpriseId)+"/"+vacationIds);
};

export const managerCancelBooking = (enterpriseId,bookingIds) => {
    return $post(MANAGER_BOOKINGS_URL(enterpriseId)+"/"+bookingIds+"/cancel");
}

export const managerBookTurn = (enterpriseId,slots) =>
{
    return $put(MANAGER_BOOKINGS_URL(enterpriseId), { requiredSlots: slots })
}

export const getManagerEnterprises =() =>
{
    return $get(`${MANAGER_ENTERPRISE_URL()}`);
}

export const getManagerEnterpriseInfoById =(enterpriseId) =>
{
    return $get(`${MANAGER_ENTERPRISE_URL()}/${enterpriseId}`);
}

export const createEnterprise =(body) =>
{
    return $post(`${MANAGER_ENTERPRISE_URL()}/add`,body);
}

export const updateManagerEnterpriseInfo =(enterpriseId,body) =>
{
    return $post(`${MANAGER_ENTERPRISE_URL()}/${enterpriseId}`,body);
}

export const activateManagerEnterprise =(enterpriseId) =>
{
    return $post(`${MANAGER_ENTERPRISE_URL()}/${enterpriseId}/activate`);
}

export const deactivateManagerEnterprise =(enterpriseId) =>
{
    return $post(`${MANAGER_ENTERPRISE_URL()}/${enterpriseId}/deactivate`);
}

export const getManagerEnterpriseEmployees = (enterpriseId, serviceTypeId, versionId) => {
    let params = [serviceTypeId && `serviceTypeId=${serviceTypeId}`, versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $get(`${MANAGER_EMPLOYEE_URL(enterpriseId)}${queryParams}`);
};

export const addEnterpriseEmployees = (enterpriseId,body) => {
    return $post( `${MANAGER_EMPLOYEE_URL(enterpriseId)}/add`,body);
};

export const getEnterpriseEmployeeById = (enterpriseId,employeeId) => {
    return $get(`${MANAGER_EMPLOYEE_URL(enterpriseId)}/${employeeId}`);
};

export const deleteEnterpriseEmployeeById = (enterpriseId,employeeId) => {
    return $delete(`${MANAGER_EMPLOYEE_URL(enterpriseId)}/${employeeId}`);
};

export const updateEnterpriseEmployeeById = (enterpriseId,employeeId,body) => {
    return $post(`${MANAGER_EMPLOYEE_URL(enterpriseId)}/${employeeId}`,body);
};

export const getManagerEnterpriseServicesTypes = (enterpriseId) => {
    return $get(MANAGER_SERVICES_URL(enterpriseId));
};

export const getManagerEnterpriseServiceTypeById = (enterpriseId,serviceTypeId) => {
    return $get(`${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}`);
};

export const updateManagerEnterpriseServiceTypeById = (enterpriseId,serviceTypeId,body) => {
    return $post(`${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}`,body);
};

export const deleteManagerEnterpriseServiceTypeById = (enterpriseId,serviceTypeId) => {
    return $delete(`${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}`);
};

export const createNewManagerEnterpriseServiceType = (enterpriseId,body) => {
    return $post(`${MANAGER_SERVICES_URL(enterpriseId)}/add`,body);
};

export const activateManagerEnterpriseServiceType = (enterpriseId,serviceTypeId,versionId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $post( `${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}/activate${queryParams}`);
};

export const deactivateManagerEnterpriseServiceType = (enterpriseId,serviceTypeId,versionId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $post( `${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}/deactivate${queryParams}`);
};

export const deleteManagerEnterpriseServiceTypeVersion = (enterpriseId,serviceTypeId,versionId) => {
    return $delete( `${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}/version/${versionId}`);
};

export const updateManagerEnterpriseServiceTypeVersion = (enterpriseId,serviceTypeId,versionId,body) => {
    return $put( `${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}/version/${versionId}`,body);
};

export const createNewServiceTypeVersion = (enterpriseId,serviceTypeId,effectiveDate,expirationDate,versionId,prevVersionExpirationDate) => {
    let baseUrl = `${MANAGER_SERVICES_URL(enterpriseId)}/${serviceTypeId}/version`;

    let params = [effectiveDate && `effectiveDate=${effectiveDate}`,
        expirationDate && `expirationDate=${expirationDate}`,
        versionId && `versionId=${versionId}`,
        prevVersionExpirationDate && `prevVersionExpirationDate=${prevVersionExpirationDate}`]
    let queryParams = getQueryParams(params);
    let url = `${baseUrl}${queryParams}`;
    return $post(url);
};

export const getManagerEnterpriseServiceProviders = (enterpriseId,serviceTypeId,versionId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $get( `${MANAGER_SERVICE_PROVIDERS_URL(enterpriseId,serviceTypeId)}${queryParams}`);
};

export const getManagerEnterpriseServiceProviderById = (enterpriseId,serviceTypeId,serviceProviderId,versionId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $get(`${MANAGER_SERVICE_PROVIDERS_URL(enterpriseId,serviceTypeId)}/${serviceProviderId}${queryParams}`);
};

export const deleteManagerEnterpriseServiceProviderById = (enterpriseId,serviceTypeId,serviceProviderId,versionId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $delete(`${MANAGER_SERVICE_PROVIDERS_URL(enterpriseId,serviceTypeId)}/${serviceProviderId}${queryParams}`);
};

export const addManagerEnterpriseServiceProvider = (enterpriseId,serviceTypeId,versionId,employeeId,duration) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    let body = [
        {
            "id": employeeId,
            "duration": duration
        }
    ]
    return $post(`${MANAGER_SERVICE_PROVIDERS_URL(enterpriseId,serviceTypeId)}/add${queryParams}`, body);
};

export const updateManagerEnterpriseServiceProvider = (enterpriseId,serviceTypeId,versionId,employeeId,duration) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    let body =
        {
            "duration": duration
        }
    return $put(`${MANAGER_SERVICE_PROVIDERS_URL(enterpriseId,serviceTypeId)}/${employeeId}${queryParams}`, body);
};


export const getManagerServiceProviderWorkingHours = (enterpriseId,serviceTypeId,versionId,serviceProviderId) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $get(`${MANAGER_SERVICE_PROVIDERS_WH_URL(enterpriseId,serviceTypeId,serviceProviderId)}${queryParams}`);
};

export const editServiceProviderWorkingHours = (enterpriseId,serviceTypeId,versionId,serviceProviderId,body) => {
    let params = [versionId && `versionId=${versionId}`]
    let queryParams = getQueryParams(params);
    return $post(`${MANAGER_SERVICE_PROVIDERS_WH_URL(enterpriseId,serviceTypeId,serviceProviderId)}${queryParams}`,body);
};

export const editMultiServiceProviderWorkingHours = (enterpriseId,body) => {
    return $post(`${MANAGER_SERICES_MULTI_WH_URL(enterpriseId)}`,body);
};




function getQueryParams(params) {
    let queryParams = ``
    let isFirstParam = true;
    for (let i = 0; i < params.length; i++) {

        if (params[i]) {
            queryParams += isFirstParam ? "?" : "&";
            queryParams += params[i];
            isFirstParam = false;
        }
    }
    return queryParams;
}



