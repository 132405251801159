import React from "react";
import ReactDOM from "react-dom";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import {withStyles} from "@material-ui/core/styles";
import {SECOND_COLOR_A} from "../../../styles";
import Icon from "@material-ui/core/Icon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const styles = {
    root: {
        position: "relative"
    },
    content: {
        padding: "18.5px 14px"
    },
    inputLabel: {
        position: "absolute",
        left: 0,
        top: 0,
        // slight alteration to spec spacing to match visual spec result
        transform: "translate(0, 24px) scale(1)"
    }
};


const LabelledOutline = ({classes, id, label, children, icon , width}) => {
    const iconColor = SECOND_COLOR_A;

    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);
    React.useEffect(() => {
        const labelNode = ReactDOM.findDOMNode(labelRef.current);
        setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
    }, [label]);

    return (
        <div style={{position: "relative", marginTop: "8px" , width: width}}>
            <InputLabel
                ref={labelRef}
                htmlFor={id}
                variant="outlined"
                className={classes.inputLabel}
                shrink
            >
                <div>
                    { // TODO: TEMP CODE: Need to be handled from DoreIcon.js component, need to use it as generic icon!!
                        // Also need to create core shred Typography generic component for text used
                        icon && typeof icon === 'string' ?
                            <Icon style={{color: iconColor}}>{icon}</Icon> :
                            <FontAwesomeIcon style={{color: iconColor}} icon={icon}/>
                    }
                    {label}
                </div>
            </InputLabel>
            <div className={classes.root}>
                <div id={id} className={classes.content}>
                    {children}
                    <NotchedOutline notched labelWidth={labelWidth}/>
                </div>
            </div>
        </div>
    );
};
export default withStyles(styles)(LabelledOutline);
