import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {translate2} from "../../../services/translateService";
import {makeStyles} from "@material-ui/core/styles";
import DoreExpansionPanel from "../../shared/DoreExpansionPanel";
import DoreChip from "../../shared/DoreChip";
import FilterMenu from "../../shared/FilterMenu";
import {getAvailableSlots, getEnterpriseEmployees, getEnterpriseServices} from "../../../services/dataService";
import {changeCheckByIndex, checkByIndex, getUniqueColor, toYYYYMMDDFormat} from "../../../utils/utils";
import {isMobile} from "react-device-detect";
import {getDayHeaderContent} from "../../shared/calendar/DoreOneWeekCalendar";
import {mapAndSave, SERVICES_KEY} from "../../../services/storageService";
import {SOME_COLORS} from "../../../utils/consts";


const useStyles = makeStyles((theme) => ({
    "body": {
        marginTop: "40px",
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "\"Lucida Grande\",Helvetica,Arial,Verdana,sans-serif"
    },
    "h1": {
        fontSize: "35px",
        marginBottom: "30px"
    },
    "app": {
        width: "1100px",
        margin: "0 auto"
    },
    "external_events": {
        float: "left",
        width: "150px",
        // padding: "0 10px",
        // border: "1px solid #ccc",
        // background: "#eee",
        textAlign: "left"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    "external_events_h4": {
        fontSize: "16px",
        marginTop: "0",
        paddingTop: "1em"
    },
    "external_events__fc_event": {
        margin: "10px 0",
        cursor: "pointer",
        textAlign: "center",
        color: "#FFFFFF",
        background: "#3A87AD",
    },
    "external_events_p": {
        margin: "1.5em 0",
        fontSize: "11px",
        color: "#666"
    },
    "external_events_p_input": {
        margin: "0",
        verticalAlign: "middle"
    },
    "calendar_container": {
        "position": "relative",
        "zIndex": "1",
        "marginLeft": "200px"
    },
    "calendar": {
        float: "left",
        width: "900px",
        height: "10000px"
    }
    , timePicker_container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    timePicker_textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

let cache = {};

const TurnsCalendar = ({
                           onFilterMenuChange,
                           inputOpenFilterMenu,
                           loading,
                           enterpriseId,
                       }) => {
    const [openFilterMenu, setOpenFilterMenu] = useState(inputOpenFilterMenu);
    const [fcView, setFCView] = useState("timeGridWeek")
    const [isToShowRightArrow, setIsToShowRightArrow] = useState(false)
    const [isToShowLeftArrow, setIsToShowLeftArrow] = useState(false)
    const [weekRange, setWeekRange] = useState({})
    const [calendar, setCalendar] = useState(null);
    const [calendarTitle, setCalendarTitle] = useState(null);

    const [services, setServices] = useState(null);
    const [serviceIdIndex, setServiceIdIndex] = useState(0);
    // const [serviceTypeId, setServiceTypeId] = useState(inputServiceTypeId);
    const [allowSlotsOverlap, setAllowSlotsOverlap] = useState(false);
    const [slotDuration, setSlotDuration] = useState(10);
    // const [providers, setProviders] = useState(null);
    const [employees, setEmployees] = useState(null);
    // const [providerIdIndex, setProviderIdIndex] = useState(null);
    const [employeeIndex, setEmployeeIndex] = useState(null);


    useEffect(() => {
        setOpenFilterMenu(inputOpenFilterMenu)
    }, [inputOpenFilterMenu]);


    // useEffect(() => {
    //     providers && console.log("provider ID changed to", providers[providerIdIndex].id)
    // }, [providerIdIndex]);
    //

    useEffect(() => {
        (async () => {
            const servicesArr = await getEnterpriseServices(enterpriseId);
            if (servicesArr) {
                setServices(servicesArr);
                mapAndSave(SERVICES_KEY, servicesArr)
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const employeesArr = await getEnterpriseEmployees(enterpriseId);
            if (employeesArr) {
                // setEmployees(employeesArr);
                setEmployees(employeesArr.map((emp, idx) => {
                    Object.keys(emp.employeeServices).map((key, idx2) => {

                        const uuidIdx = (idx + 1) * idx2;
                        const backgroundColor = uuidIdx < SOME_COLORS.length ? SOME_COLORS[uuidIdx] : getUniqueColor();
                        // console.log("idx,idx2 ["+ idx +","+ idx2 +"]" + " uuidIdx: "+uuidIdx)
                        // console.log("backgroundColor :"+backgroundColor + " , for: empID["+emp.id+"],"+"service["+key+"]");
                        emp.employeeServices[key].backgroundColor = backgroundColor;
                    });

                    return emp
                }));
                console.log("employees", employeesArr)
            }
        })();
    }, []);


    // useEffect(() => {
    //     let serviceId = services && services[serviceIdIndex] && services[serviceIdIndex].id;
    //     if (serviceId) {
    //         (async () => {
    //             if (enterpriseId) {
    //                 const providersArr = await getEnterpriseServiceProviders(enterpriseId, serviceId);
    //                 if (providersArr) {
    //                     setProviders(providersArr);
    //                     mapAndSave(PROVIDERS_KEY, providersArr)
    //                 }
    //             }
    //         })();
    //     }
    // }, [serviceIdIndex]);

    const  resetCache = (spServiceKey)  => {
        if (cache[spServiceKey]) {
            cache[spServiceKey].forEach(slotID => {
                const eventById = slotID && calendar.getEventById(slotID);
                eventById && eventById.remove();
            })
        }
    }

    useEffect( () => {
        (async () => {
        let serviceId = services && services[serviceIdIndex] && services[serviceIdIndex].id;
        let employeeId = employees && employees[employeeIndex] && employees[employeeIndex].id;
        const spServiceKey = "SID_" + serviceId + "_EMP_" + employeeId;

        await resetCache(spServiceKey);

        if (serviceId && employeeId) {
            let dateIterator;
            for (dateIterator = new Date(weekRange.startDate); dateIterator <= weekRange.endDate; dateIterator.addDays(1)) {
                await (async () => {
                    const dateStr = toYYYYMMDDFormat(dateIterator);
                    const slots = await getAvailableSlots(enterpriseId, serviceId, employeeId, dateStr, 0);
                    if (slots) {
                        slots.forEach(stTime => {
                            const startDateTimeRaw = new Date(Date.parse(dateStr + ' ' + stTime));
                            const startDateTime = new Date(startDateTimeRaw)
                            const endDateTime = new Date(startDateTimeRaw.addMinutes(15));


                            const slotID = spServiceKey + "_ST_" + stTime;
                            if (cache[spServiceKey]) {
                                cache[spServiceKey].push(slotID)
                            } else {
                                cache[spServiceKey] = [slotID]
                            }

                            calendar.addEvent({
                                serviceTypeId: serviceId,
                                resourceId: spServiceKey,
                                serviceTypeIndex: serviceIdIndex,
                                employeeIndex,
                                employeeId,
                                id: slotID,
                                title: stTime,
                                start: startDateTime,
                                end: endDateTime,
                                allDay: false,
                                backgroundColor: employees[employeeIndex].employeeServices[serviceId].backgroundColor
                            })
                        })
                    }
                })();
            }

        }

    })()}, [employeeIndex , serviceIdIndex, weekRange]);

    // const updateProvider = async (index) => {
    //     setProviderIdIndex(index);
    //     checkByIndex(index, providers, setProviders);
    // };

    const updateEmployee = async (index) => {
        setEmployeeIndex(index);
        checkByIndex(index, employees, setEmployees);
    };
    const updateService = async (index) => {
        setServiceIdIndex(index)
        changeCheckByIndex(index, services, setServices);
    };


    const endTopBar = () => {
        return isMobile ? 'timeGridThreeDays,timeGridDay'
            : 'timeGridWeek,timeGridThreeDays,timeGridDay'
    };

    const startTopBar = () => {
        return 'prev,next';

    };

    const datesSet = (arg) => {
        setCalendarTitle(arg.view.calendar.currentDataManager.data.viewTitle)
        setCalendar(arg.view.calendar)
        setFCView(arg.view.type)

        let endDate = new Date(arg.view.activeEnd.getTime());
        endDate.setDate(arg.view.activeEnd.getDate() - 1);
        const startDate = arg.view.activeStart;
        let weekRangeValue = {
            startDate: startDate,
            endDate: endDate
        };
        setWeekRange(weekRangeValue)
    }


    const filterMenu =
        <FilterMenu
            open={openFilterMenu}
            onClose={() => {
                setOpenFilterMenu(false)
                onFilterMenuChange && onFilterMenuChange(false)
            }}
        >


            <DoreExpansionPanel title={translate2("serviceProvider")} icon="account_circle"
                                initialExpanded={true}
                                forceCancelLoading={true}>
                <div style={{
                    paddingBottom: '1rem',
                }}>
                    {employees && employees.length > 0 && employees.map((emp, index) => (
                        <DoreChip
                            key={emp.id}
                            label={emp.name}
                            checked={emp.checked}
                            onClick={() => updateEmployee(index)}
                        />
                    ))}
                </div>
            </DoreExpansionPanel>

            <DoreExpansionPanel title={translate2("services")} icon="business_center"
                                initialExpanded={true}
                                forceCancelLoading={true}>
                <div style={{
                    paddingBottom: '1rem',
                }}>
                    {services && services.length > 0 && services.map((service, index) => (
                        <DoreChip
                            key={service.id}
                            label={service.name}
                            checked={service.checked}
                            onClick={() => updateService(index)}
                        />
                    ))}
                </div>
            </DoreExpansionPanel>
        </FilterMenu>;


    const mobileTitle = <div>{isMobile && calendarTitle && <div>
        <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}}>
            {calendarTitle}
        </div>
    </div>}</div>;


    const renderEventContent = (eventInfo) => {
        let serviceTypeInfo = services[eventInfo.event.extendedProps.serviceTypeIndex];
        let employeeInfo = employees[eventInfo.event.extendedProps.employeeIndex];
        return (
            <div style={{textAlign: 'center'}}>
                <b>{eventInfo.event.title}</b>
                <br/>
                <b>{`${serviceTypeInfo.name}`}</b>
                <br/>
                <b>{`${employeeInfo.name}`}</b>
                {/*<b>{`${translate2("service")}: ${serviceTypeInfo.name}`}</b>*/}
                {/*<br/>*/}
                {/*<b>{`${translate2("provider")}: ${serviceTypeInfo.name}`}</b>*/}

            </div>
        )

        //     let serviceTypeInfo = getService(serviceTypeId);
        //     if (eventInfo && eventInfo.event && eventInfo.event.end) {
        //         let Difference_In_Time = eventInfo.event.end.getTime() - eventInfo.event.start.getTime();
        //         let Difference_In_MINS = Difference_In_Time / (60000);
        //         let duration1 = eventInfo.event.extendedProps.serviceDuration;
        //         let duration = duration1 || getDuration(serviceTypeInfo);
        //         let numOfSlots = Difference_In_MINS / (duration);
        //         let title = eventInfo.event.title || getServiceName(serviceTypeInfo);
        //         return (
        //             <div id={"ev." + eventInfo.event.id}
        //                  draggable={true}
        //             >
        //                 <b>{title}</b>
        //                 <br/>
        //                 <b>{eventInfo.timeText}</b>
        //                 <br/>
        //                 <b>{"عدد الأدوار: " + Math.trunc(numOfSlots)}</b>
        //
        //             </div>
        //         )
        //     } else {
        //         let stId = eventInfo.event.draggedEl && eventInfo.event.draggedEl.id;
        //         let title = getServiceName(serviceTypeInfo);

        //     }
        // }
    }

    return (
        <div>
            <div>
                {filterMenu}
                {mobileTitle}
                <FullCalendar
                    datesSet={datesSet}
                    slotDuration={'00:05'}
                    slotEventOverlap={allowSlotsOverlap}
                    selectOverlap={allowSlotsOverlap}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    // customButtons={{
                    //     settingsButton: {
                    //         text: translate2("settings"),
                    //         click: onSettingsButtonClick
                    //     }
                    // }}
                    scrollTime={'08:00'}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    // datesSet={}
                    plugins={
                        [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]
                    }
                    headerToolbar={{
                        center: isMobile ? '' : 'title',
                        start: startTopBar(),
                        end: endTopBar()
                    }}
                    locale={'en-GB'}
                    direction={'rtl'}
                    allDaySlot={false}
                    dayHeaderContent={(arg) => getDayHeaderContent(arg, false)}


                    titleFormat={fcView === 'timeGridDay'
                        ? {year: 'numeric', month: 'numeric', day: 'numeric'}
                        : {year: 'numeric', month: 'numeric'}}
                    // dayHeaderFormat={
                    //     isOneWeekCalendar
                    //         ? isMobile && fcView === 'timeGridWeek' ? {day: 'numeric'} : {weekday: 'short'}
                    //         : isMobile && fcView === 'timeGridWeek' ? {day: 'numeric'} : {
                    //             weekday: 'short',
                    //             month: 'numeric',
                    //             day: 'numeric'
                    //         }
                    // }
                    views={{
                        // listDay: {buttonText: 'قائمة اليوم'},
                        listWeek: {buttonText: translate2("list")},
                        // listMonth: {buttonText: 'list month'},
                        timeGridWeek: {buttonText: translate2("week")},
                        dayGridMonth: {buttonText: translate2("month")},
                        timeGridDay: {buttonText: translate2("day")},
                        timeGridThreeDays: {
                            type: 'timeGrid',
                            duration: {days: 3},
                            buttonText: translate2("3Days")
                        }
                    }}
                    initialView={isMobile ? 'timeGridThreeDays' : 'timeGridWeek'}
                    editable={false}
                    selectable={false}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    // select={handleDateSelect}
                    eventLongPressDelay={200}
                    selectLongPressDelay={200}
                    eventOverlap={allowSlotsOverlap}
                    eventContent={renderEventContent} // custom render function
                    eventClick={(arg) => {
                        console.log("cliecked", arg)
                    }}
                    eventAdd={(ev) => {

                        // onUpdateEvent && onUpdateEvent(calendar)
                    }}
                    eventChange={(ev) => {
                        // onUpdateEvent && onUpdateEvent(calendar)
                    }}
                    eventRemove={(ev) => {
                        // onUpdateEvent && onUpdateEvent(calendar)
                    }}
                    droppable="true"
                />

            </div>


        </div>
    )
        ;

};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(TurnsCalendar);
