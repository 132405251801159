import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {Icon} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import {connect} from 'react-redux';
import {MAIN_COLOR_C} from '../../styles';
import {grey} from '@material-ui/core/colors';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {Delete} from "@material-ui/icons";

const shouldDividerBeWhite = (list, item, index) => {
  if (index < list.length - 1) {
    return item.selected || list[index + 1].selected;
  }
  return item.selected;
};

const DoreList = ({
                    additionAction,
                    onDelete,
                    list,
                    onClick,
                    loading,
                    selectedColor = MAIN_COLOR_C,
                    skeleton = {
                        itemsNumber: 1,
                        titleWidth: '55%',
                        subTitleWidth: '85%',
                        descriptionWidth: '95%'
                    }
                  }) => (
  <>
    {!loading.skeleton && <List>
      {list && list.map((item, index) => (
        <div
          key={item.id || index}
          onClick={() => onClick && onClick(item, index)}
        >
          <ListItem
            button
            alignItems="center"
            style={{
              backgroundColor: item.selected && selectedColor,
              color: item.selected && 'white',
            }}
          >
            {
              item.avatar &&
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
            }
            {
              item.icon &&
              <IconButton>
                <Icon style={{ fontSize: '1.4em', color: item.selected && 'white' }}>{item.icon}</Icon>
                {item.iconText && <span
                  style={{
                    fontSize: '16px',
                    position: 'absolute',
                    bottom: '17px',
                    fontFamily: 'cursive',
                    color: item.selected ? 'white' : grey[700],
                  }}>{parseInt(item.iconText) < 10 ? `0${item.iconText}` : item.iconText}</span>}
              </IconButton>
            }
            <ListItemText>
              <Typography component="p" variant="body1" color="textSecondary"
                          style={{ color: item.selected && 'white', fontWeight: 'bold' }}>
                {item.title}
              </Typography>
              <div style={{ height: '0.25rem' }}></div>
              <Typography component="p" variant="body2" color="textSecondary"
                          style={{ color: item.selected && 'white' }}>
                {item.subTitle}
              </Typography>
              <Typography component="p" variant="body2" color="textSecondary"
                          style={{ color: item.selected && 'white' }}>
                  {item.description}
              </Typography>
            </ListItemText>
               <ListItemSecondaryAction>
                   {additionAction && <IconButton   color="primary" edge="end" aria-label="additionAction" onClick={additionAction.onClick}>
                       <Icon>{additionAction.icon}</Icon>
                   </IconButton>}
                  {onDelete && <IconButton   color="secondary" edge="end" aria-label="delete" onClick={onDelete}>
                      <Delete/>
                  </IconButton>}
              </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="middle" style={{ backgroundColor: shouldDividerBeWhite(list, item, index) && 'white' }} />
        </div>))}
    </List>}
    {loading.skeleton && <List>
      {[...Array(skeleton.itemsNumber).keys()].map(idx => (
        <div key={idx}>
          <ListItem alignItems="center">
            <div>
              <Skeleton animation="wave" variant="circle" width={45} height={45} />
            </div>
            <ListItemText style={{ padding: '0.5rem' }}>
              <div style={{ padding: '0 0 1.1rem 0' }}>
                <Skeleton animation="wave" height={10} width={skeleton.titleWidth} />
              </div>
              <div>
                <Skeleton animation="wave" height={10} width={skeleton.subTitleWidth} />
              </div>
              <div>
                  <Skeleton animation="wave" height={10} width={skeleton.descriptionWidth}/>
              </div>
            </ListItemText>
          </ListItem>
          <div style={{ padding: '0 1.1rem 0 1.1rem' }}>
            <Skeleton animation="wave" height={2} width="100%" />
          </div>
        </div>))}
    </List>}
  </>
);

export default connect(state => ({
  loading: state.loading,
}))(React.memo(DoreList));
