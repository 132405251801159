import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: '999999999999',
    '& .MuiFab-label': {
      margin: '0 0 5px 1px', // TODO: check this in another places, seem that's because of dir=RTL the shortcut not the same, this value equals to *** margin: 0 1px 5px 0 ***
    },
  },
}));

const DoreSpeedDial = ({actions , openAction ,closeAction , editIcon=false}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleVisibility = () => {
    setHidden(prevHidden => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
    openAction && openAction();
  };

  const handleClose = () => {
    setOpen(false);
    closeAction && closeAction();
  };

  return (
    <div className={classes.root}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel=""
        hidden={hidden}
        icon={<SpeedDialIcon openIcon={editIcon && <EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default DoreSpeedDial;
