import React from 'react';
import {withStyles} from '@material-ui/core';
import DoreExpansionPanel from "./DoreExpansionPanel";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";


const styles = {
    root: {},
};


const DoreProfilePanel = ({infoObject, panelInitialExpanded, title, icon}) => {
    return (
        <DoreExpansionPanel title={title} icon={icon} initialExpanded={panelInitialExpanded}>
            {infoObject && Object.keys(infoObject).map(k => (
                <Typography key={k} variant="subtitle1" style={{
                    padding: '0.2rem',
                    lineHeight: '0.1rem',
                    letterSpacing: '0.05rem',
                    marginBottom: '0.2rem',
                    color: grey[800],
                }}>
                    {infoObject[k].label}
                    <Typography component="span" variant="body1" color="textSecondary">
                        {infoObject[k].value}
                    </Typography>
                </Typography>
            ))}
        </DoreExpansionPanel>
    )
};

export default withStyles(styles)(DoreProfilePanel);
