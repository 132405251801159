import React from 'react';
import {connect} from 'react-redux';
import Drawer from '@material-ui/core/Drawer';


/**
 * Filter Menu Component
 *
 * // TODO: change name to DoreMenuBar - make it generic with children, menubar and filterbar should use it
 *
 * @author Mohammad
 */
const FilterMenu = ({ anchor = 'left', open, onClose, children }) => {

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose} style={{ opacity: 1 }}>
      <div role="presentation" style={{ height: '100%', background: 'white', width: '18rem' }}>
        {children}
      </div>
    </Drawer>
  );
};

export default connect(state => ({
  loading: state.loading,
}))(FilterMenu);
