import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import {Icon, useTheme} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {ERROR_COLOR_C, MAIN_COLOR_C, THIRD_COLOR} from '../../styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  wrapper: ({ position }) => ({
    margin: theme.spacing(1),
    position,
    bottom: 15,
    right: 15,
    zIndex: '99999',
  }),
  icon: {
    color: THIRD_COLOR,
  },
  fabProgress: ({ color }) => ({
    color: color === 'primary' ? MAIN_COLOR_C : ERROR_COLOR_C,
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  }),
}));

const DoreFab = ({ onClick, inout, color = 'primary', icon, loading, disabled, position = 'fixed', variant = 'round', label }) => {

  const theme = useTheme();
  const classes = useStyles({ color, position });

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    !loading.skeleton && <Zoom
      className={classes.wrapper}
      onClick={() => !disabled && !loading.spinner && onClick && onClick()}
      unmountOnExit
      in={inout}
      timeout={transitionDuration}
      style={{ transitionDelay: `${inout ? transitionDuration.exit : 0}ms`, }}
    >
      <div>
        <Fab
          color={color}
          disabled={disabled || loading.spinner}
          variant={loading.spinner ? 'round' : variant}
        >
          {icon && <Icon className={classes.icon}>{icon}</Icon>}
          {(label && !loading.spinner) &&
          <Typography style={{ margin: '0 5px -3px 1px' }} variant="body2">{label}</Typography>}
        </Fab>
        {loading.spinner && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
    </Zoom>
  );
};

export default DoreFab;
