import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {connect} from 'react-redux';
import {Icon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {THIRD_COLOR} from '../../styles';

/**
 * ====================================================
 * =================[Component styles]=================
 * ====================================================
 */
const useStyles = makeStyles({
  root: ({ background }) => ({
    padding: '0 0 0 1.15rem',
    background,
    '& .MuiBreadcrumbs-separator': {
      color: 'white',
    },
    '& span': {
      fontSize: '0.85rem',
      color: THIRD_COLOR,
      cursor: 'pointer',
    },
  }),
});

/**
 * ================================================================
 * =================[Doretou breadcrumb component]=================
 * ================================================================
 *
 * @param breadCrumb {array} Passed automatically by Redux store
 * @returns {ReactComponent} React component with redux connection
 */
const DoreBreadcrumb = ({ breadCrumb, background }) => {
  const classes = useStyles({ background });
  return (
    <div className={classes.root}>
      {breadCrumb && <Breadcrumbs separator="›">
        {breadCrumb.map((item, index) => (
          <Link key={index} onClick={() => item.onClick && item.onClick()}>
            {item.icon && <Icon>{item.icon}</Icon>}
            <span>{item.label}</span>
          </Link>
        ))}
      </Breadcrumbs>}
    </div>
  );
};

export default connect(state => ({
  breadCrumb: state.breadCrumb,
}))(DoreBreadcrumb);
