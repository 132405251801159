import React, {useEffect, useState} from 'react';
import {bookTurn, getAvailableSlots} from '../../services/dataService';
import DoreTopBar from '../shared/DoreTopBar';
import DoreList from '../shared/DoreList';
import {switchIcon, tConvert} from '../../utils/utils';
import {goToBookingsPage} from '../../services/pageService';
import DoreFab from '../shared/DoreFab';
import {checkedIcon, unCheckedIcon} from '../../utils/consts';
import {connect} from 'react-redux';
import {alertSuccess} from '../../services/snackbarService';
import {translate2} from "../../services/translateService";

// TODO: save Button, and DeleteButton should be on the dore list component with same logic....
const TimeSlots = ({ match, loading, breadCrumb }) => {

  // Params
  let { params: { enterpriseId, serviceId, providerId, date , version=0 } } = match;
  enterpriseId = enterpriseId.split('_')[0];
  serviceId = serviceId.split('_')[0];
  providerId = providerId.split('_')[0];
  // States
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]); // Todo: Change from array to {} and change the update/delete implementation as needed, its more fast
  const [saveIcon, setSaveIcon] = useState(false);

  useEffect(() => {
    if (selectedTimeSlots.length > 0) setSaveIcon(true);
    else setSaveIcon(false);
  }, [selectedTimeSlots]);

  const onClick = (slot, index) => {
    switchIcon(timeSlots, setTimeSlots, selectedTimeSlots, setSelectedTimeSlots, slot.icon, slot.slot, index);
  };

  useEffect(() => {
    (async () => {
      const slots = await getAvailableSlots(enterpriseId, serviceId, providerId, date,version);
      if (slots) {
        setTimeSlots(slots.map((slot, index) => ({
          slot,
          id: index,
          title: slot,
          subTitle: tConvert(slot),
          icon: unCheckedIcon,
        })));
      }
    })();
  }, []);

  const onSave = async () => {
    // Make slots data ready for server understanding
    const slots = selectedTimeSlots.map(({ data: hour }) => ({
      hour,
      version: version,
      date : date,
      serviceTypeId: serviceId,
      serviceProviderId: providerId
    }));

    // Save - ask the server to book a turn
    if (await bookTurn(enterpriseId, slots)) {
      alertSuccess('تم الحجز بنجاح');
      goToBookingsPage();
    }
  };

  return (
    <div>
      <DoreTopBar
          backBarBtn={true}
        counter={selectedTimeSlots.length > 0 && selectedTimeSlots.length}
        loading={loading}
        counterIcon={checkedIcon}
      />
      <DoreFab onClick={onSave} inout={saveIcon} icon="save" loading={loading} variant="extended" label={translate2("save")} />
      <DoreList list={timeSlots} onClick={onClick} skeletonLength={13} loading={loading} />
    </div>
  );
};

export default connect(state => ({
  loading: state.loading,
  breadCrumb: state.breadCrumb,
}))(TimeSlots);
