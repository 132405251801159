import React, {useState} from "react";
import {connect} from "react-redux";
import DoreDatePicker from "../../../../../shared/DoreDatePicker";
import {dateSimpleFormat} from "../../../../../../utils/utils";
import DoreSingleSelect from "../../../../../shared/DoreSingleSelect";

const NewVersion = ({loading,
                    versionIdR, onChangeSourceVersion,
                    prevVersionExpirationDateR, onChangePrevVersionExpirationDate,
                    effectiveDateR, onChangeEffectiveDate,
                    expirationDateR, onChangeExpirationDate,
                    serviceTypeVersions}
) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const [open, setOpen] = React.useState(false);
    // const [selectedOldVersion, setSelectedOldVersion] = useState(0);
    const [versionId, setVersionId] = useState(versionIdR);
    const [effectiveDate, setEffectiveDate] = useState(effectiveDateR);
    const [expirationDate, setExpirationDate] = useState(expirationDateR);
    const [prevVersionExpirationDate, setPrevVersionExpirationDate] = useState(prevVersionExpirationDateR);

    const handleChangeSourceVersion = (event) => {
        let v = event.target.value;
        setVersionId(v);
        if (v != -1) {
            setPrevVersionExpirationDate(serviceTypeVersions[v].expirationDate);
        }
        onChangeSourceVersion && onChangeSourceVersion(v)
    }

    return (
        <div>
            <DoreSingleSelect
                value={versionId}
                title="الاصدار السابق"
                onChange={handleChangeSourceVersion}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                id="old-version-id"
                nullItemValue={-1}
                nullItemName={"نسخة جديدة"}
                items=
                    {
                        serviceTypeVersions && serviceTypeVersions.map((versionInfo) => (
                            {
                                value: versionInfo.id,
                                name: versionInfo.title,
                            }
                        ))

                    }

            />
            <br/>
            <br/>
            {versionId != -1 && <DoreDatePicker
                disableFutureDates={false}
                loading={loading}
                label="تاريخ نهاية الاصدار السابق"
                okLabel="موافق"
                cancelLabel="الغاء"
                icon="today"
                value={prevVersionExpirationDate}
                onChange={date => {
                    let simpleDate = dateSimpleFormat(date);
                    setPrevVersionExpirationDate(simpleDate);
                    onChangePrevVersionExpirationDate && onChangePrevVersionExpirationDate(simpleDate)
                }}
            />
            }
            <DoreDatePicker
                disableFutureDates={false}
                loading={loading}
                label="تاريخ بداية الاصدار الجديد"
                okLabel="موافق"
                cancelLabel="الغاء"
                icon="today"
                value={effectiveDate}
                onChange={date => {
                    let simpleDate = dateSimpleFormat(date);
                    setEffectiveDate(simpleDate);
                    onChangeEffectiveDate && onChangeEffectiveDate(simpleDate)
                }}
            />
            <DoreDatePicker
                disableFutureDates={false}
                loading={loading}
                label="تاريخ نهاية الاصدار الجديد"
                okLabel="موافق"
                cancelLabel="الغاء"
                icon="today"
                value={expirationDate}
                onChange={date => {
                    let simpleDate = dateSimpleFormat(date);
                    setExpirationDate(simpleDate);
                    onChangeExpirationDate && onChangeExpirationDate(simpleDate);
                }}
            />
        </div>
    );
};

export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(NewVersion);
