import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {translate2} from "../../../services/translateService";
import {Grid} from "@material-ui/core";
import DoreButton from "../DoreButton";
import {faCheckCircle, faTimesCircle, faTrash} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
    sameWidth: {
        width: '8rem'
    },
});
const DoreConfirmButtons = ({
                                loading,
                                showConfirm, confirmButtonText = translate2("ok"), onConfirm, disableConfirm = false,
                                showCancel, cancelButtonText = translate2("cancel"), onCancel, disableCancel = false,
                                showDelete, deleteButtonText = translate2("delete"), onDelete, disableDelete = false
                            }) => {
    const classes = useStyles();

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>

            <Grid item>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    {showCancel && <Grid item className={classes.sameWidth}>
                        <DoreButton
                            disabled={disableCancel}
                            loading={loading}
                            label={cancelButtonText}
                            icon={faTimesCircle}
                            variant="outlined"
                            color="default"
                            onClick={onCancel}
                        />
                    </Grid>}

                    {showConfirm && <Grid item className={classes.sameWidth}>
                        <DoreButton
                            disabled={disableConfirm}
                            loading={loading}
                            label={confirmButtonText}
                            icon={faCheckCircle}
                            onClick={onConfirm}
                        />
                    </Grid>}

                    {showDelete &&
                    <Grid item className={classes.sameWidth}>
                        <DoreButton
                            disabled={disableDelete}
                            color="secondary"
                            loading={loading}
                            label={deleteButtonText}
                            icon={faTrash}
                            onClick={onDelete}
                        />
                    </Grid>
                    }


                </Grid>
            </Grid>
        </div>
    )
}
export default connect(state => ({
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(DoreConfirmButtons);