import {goToByBreadCrumb} from './pageService';
import store from '../store/store';
import {setBreadCrumb} from '../store/actions';
import {getConsumer, getEnterprise, getProvider, getService} from './storageService';
import {
    ENTERPRISES_PREFIX_KEY,
    PROVIDERS_PREFIX_KEY,
    SERVICES_PREFIX_KEY,
    SUBSCRIBERS_PREFIX_KEY
} from "../utils/consts";

/**
 * Breadcrumb In Memory Store
 * TODO: Move this in memory stores to new service (inMemoryStoreService.js)
 */
export const $bc_store = {};

/**
 * Private creator function to generate breadcrumb UI object
 *
 * @param label The label text
 * @param onClick The onClick handler
 * @returns {{onClick: *, label: *}}
 */
const createBc = (label, onClick) => ({label, onClick});

/**
 * Update Breadcrumb
 * This function update the breadcrumb from the router location
 * @param location React router location object
 * @param routerTranslation
 */
export const $updateBreadcrumb = (location, routerTranslation) => {
    const path = location.pathname.split('/').filter(bc => bc);
    const breadcrumb = path.map((nodeStr) => {
        let nodeInfo = nodeStr.split('_');
        let node = nodeInfo[0];
        let prefixKey = nodeInfo.length === 2 ? nodeInfo[1] : null;
        const staticLabel = routerTranslation ? routerTranslation[node] : '';
        const consumerLabel = prefixKey === SUBSCRIBERS_PREFIX_KEY && getConsumer(node) ? getConsumer(node).userInfo.name : '';
        const enterpriseLabel = prefixKey === ENTERPRISES_PREFIX_KEY && getEnterprise(node) ? getEnterprise(node).name : '';
        const serviceLabel = prefixKey === SERVICES_PREFIX_KEY && getService(node) ? getService(node).name : '';
        const providerLabel = prefixKey === PROVIDERS_PREFIX_KEY && getProvider(node) ? getProvider(node).name : '';
        let label = staticLabel || consumerLabel || enterpriseLabel || serviceLabel || providerLabel || node;
        return createBc(label, () => goToByBreadCrumb(location.pathname, `/${node}`));
    });
    store.dispatch(setBreadCrumb(breadcrumb));
};
