import 'date-fns';
import React from 'react';
import {Grid} from '@material-ui/core';
import {SECOND_COLOR_A} from '../../styles';
import DoreSkeleton from './DoreSkeleton';
import {makeStyles} from '@material-ui/core/styles';

// TODO: Make spacing more RWD to handle small screen size
// TODO: e.x Iphone 5/SE will not show the buttons / inputs because spacing is lot on his small screen

/**
 * Styles
 */
const useStyles = makeStyles({
  root: ({ vhToSub }) => ({
    flexGrow: 0,
    boxSizing: 'border-box',
    height: `calc(100vh - ${vhToSub})`,
  }),
  item: {
    textAlign: 'center',
    color: SECOND_COLOR_A,
  },
  title: {
    justifyContent: 'center',
    color: SECOND_COLOR_A,
  },
});

/**
 * Dore Form Component
 */
const DoreForm = (
  {
    loading,
    spacing = 10, //TODO: check screen height - make it more responsive
    logo,
    title,
    subTitle,
    children,
    vhToSub = '6.25rem' //TODO: check screen height - make it more responsive
  }
) => {
  const classes = useStyles({ vhToSub });
  return (
    <Grid
      container
      className={`dore-form ${classes.root}`}
      direction="column"
      justify="center"
      alignItems="center"
      spacing={spacing}
    >
      <Grid item className={classes.item}>
        <div className={classes.title}>
          {logo}
          {loading.skeleton ? <DoreSkeleton width="10rem" /> : <h2>{title}</h2>}
          {loading.skeleton ? <DoreSkeleton width="15rem" /> : <h3>{subTitle}</h3>}
        </div>
      </Grid>
      {children &&
      <Grid item>
        {children}
      </Grid>}
    </Grid>
  )
};

export default DoreForm;
