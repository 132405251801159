import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DoreOneWeekCalendar from "../../../../shared/calendar/DoreOneWeekCalendar";
import {getUser, mapAndSave, PROVIDERS_KEY, SERVICES_KEY} from "../../../../../services/storageService";
import {
    getManagerEnterpriseEmployees,
    getManagerEnterpriseServicesTypes,
    getManagerServiceProviderWorkingHours
} from "../../../../../services/dataService";
import DoreSingleSelect from "../../../../shared/DoreSingleSelect";
import {makeStyles} from "@material-ui/core/styles";
import DoreFab from "../../../../shared/DoreFab";
import {mapServiceProviderWHToCalendarEvents} from "../utils/EnterpriseProfileUtils";
import {SOME_COLORS} from "../../../../../utils/consts";
import {checkByIndex, getUniqueColor, selectByKey} from "../../../../../utils/utils";
import {translate2} from "../../../../../services/translateService";
import {Portal} from "@material-ui/core";
import DoreTopBar from "../../../../shared/DoreTopBar";
import FilterMenu from "../../../../shared/FilterMenu";
import DoreExpansionPanel from "../../../../shared/DoreExpansionPanel";
import DoreChip from "../../../../shared/DoreChip";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }

}));


const WorkingHours = ({
                          inputEmployee = null,
                          loading, isToShowTopBar = true,
                          inputServices = null,
                          onUpdateEvent,
                          onChangeEmployeesEvents,
                          onSave,
                          isOneWeekCalendar = true
                      }) => {
        const classes = useStyles();
        const {enterpriseId, enterpriseStatus} = getUser();
        const [refresh, setRefresh] = useState(false);
        const [employees, setEmployees] = useState([]);
        const [services, setServices] = useState([]);
        const [selectableItems, setSelectableItems] = useState(null);
        const [openEmployeeList, setOpenEmployeeList] = React.useState(false);
        const [employeeId, setEmployeeId] = useState(null);
        const [selectedProviderInfo, setSelectedProviderInfo] = useState();
        const [calendar, setCalendar] = useState(null);
        const [weekRange, setWeekRange] = useState(null);
        const [isLoadedInitialWH, setLoadedInitialWH] = useState({});

        const [openFilterMenu, setOpenFilterMenu] = useState(false);


        const findById = (arr, id) => arr && arr.length > 0 && arr.find(s => s.id === id);

        useEffect(() => {
            if (inputEmployee != null) {
                setEmployees([inputEmployee]);
            }
        }, [inputEmployee]);

        useEffect(() => {
            if (inputServices != null) {
                setServices(inputServices);
            }
        }, [inputServices]);

        useEffect(() => {
            if (inputEmployee == null) {
                (async () => {
                    if (enterpriseId) {
                        const employeesArr = await getManagerEnterpriseEmployees(enterpriseId);
                        if (employeesArr) {
                            setEmployees(employeesArr);
                            mapAndSave(PROVIDERS_KEY, employeesArr)
                        }
                    }
                })();
            }
        }, [refresh]);


        useEffect(() => {
            (async () => {
                if (inputServices == null && enterpriseId) {
                    const servicesArr = await getManagerEnterpriseServicesTypes(enterpriseId);
                    if (servicesArr) {
                        setServices(servicesArr.map((service, idx) => {
                            service['backgroundColor'] = idx < SOME_COLORS.length ? SOME_COLORS[idx] : getUniqueColor();
                            return service
                        }));
                        mapAndSave(SERVICES_KEY, servicesArr)
                    }
                }
            })();
        }, [refresh]);


        const getRelevantVersion = (service) => {
            if (isOneWeekCalendar) {
                return 0;
            }
            let i;
            for (i = 0; i < service.versions.length; i++) {
                let version = service.versions[i];
                let ef = new Date(version.effectiveDate);
                let exp = version.expirationDate ? new Date(version.expirationDate) : null;
                if (ef < weekRange.endDate && (exp == null || exp > weekRange.startDate)) {
                    return version.versionId;
                }
            }
        }

        const isToFetchForFullCalendar = () => {
            return !isOneWeekCalendar;
            // if (isOneWeekCalendar) {
            //     return false;
            // } else {
            //     return true;
            // }
        }

        useEffect(() => {
            const isToFetchWH = weekRange && services && employeeId && (isToFetchForFullCalendar() || !isLoadedInitialWH[employeeId.current]);
            if (isToFetchWH) {
                (async () => {
                    for (const service of services) {
                        const versionId = getRelevantVersion(service);
                        const workingHours = await getManagerServiceProviderWorkingHours(enterpriseId, service.id, versionId, employeeId.current);

                        if (workingHours) {
                            setLoadedInitialWH(prevState => {
                                if (isOneWeekCalendar) {
                                    prevState[employeeId.current] = true;
                                }
                                return prevState;
                            });
                            await mapServiceProviderWHToCalendarEvents(workingHours, calendar, weekRange.startDate, service.id, service);
                        }
                    }
                })();
            }
        }, [services, employeeId, weekRange]);


        useEffect(() => {
            if (employees && employees.length > 0 && calendar) {
                inputEmployee == null && setSelectableItems(
                    employees.map((emp, index) => {
                        if (index == 0) {
                            console.log("index", index)
                            return {
                                id: emp.id,
                                value: emp.id,
                                name: emp.displayName,
                                checked: true
                            }
                        } else {
                            return {
                                id: emp.id,
                                value: emp.id,
                                name: emp.displayName,
                                checked: false,
                            }
                        }
                    }))

                setEmployeeId({
                    old: employees[0].id,
                    current: employees[0].id
                })
                setSelectedProviderInfo({
                    id: employees[0].id,
                    services: employees[0].employeeServices
                });
                onChangeEmployeesEvents && onChangeEmployeesEvents(employees[0].id, employees[0].id, calendar);
            }
        }, [employees, calendar])


        useEffect(() => {
            console.log("employees changd")
        }, [employees])

        useEffect(() => {
            console.log("calendar changd")
        }, [calendar])

        function changeSPSelection(newSelectedEmpId, oldSelectedEmpId) {
            let employeeInfo = findById(employees, newSelectedEmpId)
            setSelectedProviderInfo({
                id: newSelectedEmpId,
                services: employeeInfo.employeeServices
            });
            setEmployeeId({
                old: oldSelectedEmpId,
                current: newSelectedEmpId
            })
        }

        const handleChangeSP = (event) => {
            let newSelectedEmpId = event.target.value;
            let oldSelectedEmpId = employeeId.current;
            changeSPSelection(newSelectedEmpId, oldSelectedEmpId);
        };

        useEffect(() => {
            if (employeeId && calendar) {
                onChangeEmployeesEvents && onChangeEmployeesEvents(employeeId.old, employeeId.current, calendar)
            }
        }, [employeeId, calendar]);

        const onUpdateEmployeeEvent = (calendar) => {
            onUpdateEvent && onUpdateEvent(employeeId.current, calendar)
        }

        const handleCloseEmployeeList = () => {
            setOpenEmployeeList(false);
        };

        const handleOpenEmployeeList = () => {
            setOpenEmployeeList(true);
        };


        const onChangeCalendar = (c) => {
            if (!isOneWeekCalendar) {
                c.removeAllEvents();
            }
            setCalendar(c)
        };

        function updateSelectedEmployeeFilterOption(index) {
            checkByIndex(index, selectableItems, setSelectableItems);
            selectByKey(index, selectableItems, setSelectableItems);
            changeSPSelection(selectableItems[index].id, employeeId.current);
        }

        return (
            <div>
                {isToShowTopBar && <div>
                    <DoreTopBar loading={loading}
                                onFilter={() => setOpenFilterMenu(true)}
                                counterIcon="delete"
                    />

                    <FilterMenu
                        open={openFilterMenu}
                        onClose={() => setOpenFilterMenu(false)}
                    >
                        <DoreExpansionPanel title={translate2("serviceProvider")} icon="account_circle"
                                            initialExpanded={true}
                                            forceCancelLoading={true}>
                            <div style={{
                                paddingBottom: '1rem',
                            }}>
                                {selectableItems && selectableItems.length > 0 && selectableItems.map((emp, index) => (
                                    <DoreChip
                                        key={emp.name}
                                        label={emp.name}
                                        checked={emp.checked}
                                        onClick={() => updateSelectedEmployeeFilterOption(index)}
                                    />
                                ))}
                            </div>
                        </DoreExpansionPanel>
                    </FilterMenu>

                </div>}


                {!isToShowTopBar && selectableItems &&
                <div className={classes.formControl}>

                    <DoreSingleSelect

                        value={employeeId.current}
                        title={translate2("serviceProvider")}
                        onChange={handleChangeSP}
                        onClose={handleCloseEmployeeList}
                        onOpen={handleOpenEmployeeList}
                        open={openEmployeeList}
                        id="new-service-provider-id"
                        items={selectableItems}
                    />
                </div>
                }

                {services && services.length > 0 &&
                <div>
                    <DoreOneWeekCalendar
                        isOneWeekCalendar={isOneWeekCalendar}
                        providerInfo={selectedProviderInfo}
                        inputServices={services}
                        onChangeCalendar={onChangeCalendar}
                        onUpdateEvent={onUpdateEmployeeEvent}
                        onChangeWeekRange={(wr) => setWeekRange(wr)}
                    />
                    <Portal container={document.body}>
                        <DoreFab
                            onClick={() => {
                                onChangeEmployeesEvents && onChangeEmployeesEvents(employeeId.current, employeeId.current, calendar);
                                onSave && onSave()
                            }}
                            inout={isOneWeekCalendar}
                            icon="save"
                            loading={loading}
                            // variant="extended"
                            label={translate2("save")}
                        />
                    </Portal>
                </div>}
            </div>
        )


    }
;


export default connect(state => ({
    currentLanguage: state.currentLanguage,
    loading: state.loading,
    breadCrumb: state.breadCrumb,
}))(WorkingHours);



